import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

export const ServiceList = props => (
    (
    <div>
        <h1>Welcome to Admin</h1>
        <List {...props} bulkActionButtons={false}>
            <Datagrid>
                <TextField source="key" key="key" />
                <TextField source="value" key="value" />
            </Datagrid>
        </List>
    </div>
    )
);