import Api from './api';

export default class UploadStore {

    async uploadAgencyBookingFile(data) {
        return new Promise(async (resolve, reject) => {
            const headers = {
                'Content-Type': 'multipart/form-data'
            }
            let formData = new FormData();
            formData.append('file', data);
            Api.post(`/agency_bookings/upload`, formData, headers).then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
        });
    }

    async deleteAgencyBookingFile(id) {
        return new Promise(async (resolve, reject) => {
            Api.delete(`/agency_bookings/file/${id}`).then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
        });
    }

    async downloadAgencyBookingFile(id, fileName) {
        return new Promise(async (resolve, reject) => {
            const url = `/agency_bookings/file/${id}`;
            Api.download(url, fileName).catch(error => {
                reject(error);
            });
        });
    }
}