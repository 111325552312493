import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    lighten, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel,
    Toolbar, Paper, Button, Chip, Avatar, Link, CircularProgress
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import AssignmentIcon from '@material-ui/icons/Assignment';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import './enhancedTable.css';

var options = { year: 'numeric', month: 'short', day: 'numeric' };
const dateTimeFormat = new Intl.DateTimeFormat('en-NZ', options);

const ColorCircularProgress = withStyles({
    root: {
      color: '#666',
      marginRight: '24px'
    },
  })(CircularProgress);

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array) {
    return array; // server side sorting thanks
    // const stabilizedThis = array.map((el, index) => [el, index]);
    // stabilizedThis.sort((a, b) => {
    //     const order = cmp(a[0], b[0]);
    //     if (order !== 0) return order;
    //     return a[1] - b[1];
    // });
    // return stabilizedThis.map(el => el[0]);
}


function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort, working } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {props.headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        width={headCell.width}
                    >
                        {headCell.disableSort ? headCell.label :
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>}
                    </TableCell>
                ))}
                { props.hideActionCol ? null :
                <TableCell align="right">{working ? <ColorCircularProgress size={18}/> : null}</TableCell>}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    working: PropTypes.bool.isRequired,
    hideActionCol: PropTypes.bool.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <Button aria-label="filter list">
                <FilterListIcon />
            </Button>            
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState(props.defaultSortDir);
    const [orderBy, setOrderBy] = React.useState(props.defaultSort);
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(props.page || 0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage || 25);
    const [working, setWorking] = React.useState(false);
    
    React.useEffect(() => {
        setWorking(false);
      }, [props.rows]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        const sortOrder = isAsc ? 'desc' : 'asc';
        setOrder(sortOrder);
        setOrderBy(property);
        props.onSortChange(property, sortOrder);
        setWorking(true);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = props.rows.map(n => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setWorking(true);
        props.onPageChange(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setWorking(true);
        setPage(0);
        props.onPageChange(0, parseInt(event.target.value, 10));
    };

    const openPersonDialog = (event, obj) => {
        event.stopPropagation(); // Stop open the edit dialog when click the table row
        props.openPersonDialog(obj);
    }

    const getTaskOwner = (people) => {
        if (people.length < 1) {
            return '';
        }
        const owner = people.find(p => p.ownerResponsible === true);
        if (owner.id) {
            return <Chip
                className="userchip"
                clickable={true}
                onClick={(event) => openPersonDialog(event, owner.person)}
                avatar={<Avatar alt={owner.person.displayName} src={owner.person.photo} />}
                label={owner.person.displayName}
                variant="outlined"
            />   
        } else {
            return '';
        }
    } 

    const isSelected = name => false && selected.indexOf(name) !== -1; // ben: turn off selection with false &&

    const emptyRows = false; // rowsPerPage - Math.min(rowsPerPage, props.rows.length - page * rowsPerPage);

    const getPersonName = (person) => {
        if (person.firstName || person.surname) {
            return `${person.firstName ? person.firstName : ''} ${person.surname ? person.surname : ''}`.trim();
        } else if (person.displayName) {
            return `${person.displayName}`.trim();
        } else if (person.identifier) {
            return `${person.identifier}`.trim();
        } else if (person.email) {
            return `${person.email}`.trim();
        } else if (person.upn) {
            return `${person.upn}`.trim();
        } else {
            return '';
        }
    }

    const displayVal = (obj, headerCell, row) => {
        if (headerCell.type) {
            switch (headerCell.type) {
                case "flag": {
                    return <Chip
                        label={obj === true ? "Yes" : "No"}
                        className={`flag-${obj}`}
                    />;
                }
                case "array": {
                    return row[headerCell.array][0][headerCell.arrayField];
                }
                case "dict": return obj != null ? (obj.title || obj.name) : '';
                case "user": return obj ==  null ? null : (
                    <Chip
                    className="userchip"
                    clickable={true}
                    onClick={(event) => openPersonDialog(event, obj)}
                    avatar={<Avatar alt={getPersonName(obj)} src={obj.photo} />}
                    label={getPersonName(obj)}
                    variant="outlined"
                />);
                case "date": return obj != null && obj !== "Invalid Date" ? dateTimeFormat.format(Date.parse(obj)) : "";
                case "key-date": return obj && obj.date && obj.date !== "Invalid Date" ? dateTimeFormat.format(Date.parse(obj.date)) : "";
                case "rating": {
                    let icon;
                    let color = '';
                    switch (obj.value) {
                        case '1': color = 'blue'; break;
                        case '2': color = 'yellow'; break;
                        default: color = 'pink';
                    };
                    return <Chip
                        size="small"
                        key={obj.id}
                        icon={icon}
                        label={obj.name}
                        className={"coloured " + color}
                    />
                };
                case "contract-link": {
                    return <Link color="inherit" href={`/#/contract/${obj.id}`} onClick={(evt) => {console.log("event", evt)}}>
                        {obj.number}
                    </Link>
                };
                case "status": {
                    let color = '';
                    switch (obj.code) {
                        case 'ACT': color = 'active'; break;
                        case 'DFT': color = 'draft'; break;
                        case 'COM': color = 'complete'; break;
                        case 'CLO': color = 'closingout'; break;
                        default: color = 'unknown';
                    };
                    return <Chip
                        size="small"
                        key={obj.id}
                        label={obj.title}
                        className={"coloured " + color}
                    />;
                };
                case "resolved": return obj ? 'Resolved' : 'Unresolved';
                case "contract-count": {
                    return <Chip
                        size="small"
                        icon={<AssignmentIcon/>}
                        // key={data.key}
                        label={`${obj} contract${obj !== 1 ? "s" : ""}`}
                        className={obj === 0 ? `grey` : `blue`}
                    />;
                };
                case "issue-count": {
                    return <Chip
                        size="small"
                        icon={<WarningIcon/>}
                        // key={data.key}
                        label={`${obj} issue${obj !== 1 ? "s" : ""}`}
                        className={obj === 0 ? `grey` : `pink`}
                    />;
                };
                case "role": return obj != null ? obj.title : '';
                case "vendor": {
                    if (obj && obj.length) {
                        return obj[0].vendor.title;
                    } else if (obj) {
                        return obj.title || "";
                    }
                }
                case "currency": return obj != null ? `$${obj.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "";
                case "history-change": return <Link>{obj}</Link>;
                case "contract-number": return obj ? obj.number : null;
                case "task-owner": return getTaskOwner(obj);
                default: return
            }
        }

        switch (typeof obj) {
            case "boolean":
                return obj ? "Yes" : "No";
            case "object":
                // handles null case
                return obj ? (obj.group || `${obj.firstName} ${obj.surname}` || obj.id) : "";
            default:
                return obj;
        }
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} square={true}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="medium"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={props.rows.length}
                            headCells={props.headCells}
                            working={working}
                            hideActionCol={props.hideActionCol || false}
                        />
                        <TableBody>
                            {stableSort(props.rows)
                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    const isItemSelected = isSelected(row.name);
                                    
                                    return (
                                        <TableRow
                                            hover
                                            // onClick={event => handleClick(event, row.name)}
                                            //   role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                            onClick={() => props.onRowClick(row)}
                                        >
                                            {/* <TableCell padding="checkbox">
                                                <Checkbox
                                                checked={isItemSelected}
                                                inputprops={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell> */}
                                            {props.headCells.map((headCell) => {
                                                const display = displayVal(
                                                    headCell.id.indexOf(".") === -1 ? row[headCell.id] 
                                                    : row[headCell.id.split(".")[0]] ? row[headCell.id.split(".")[0]][headCell.id.split(".")[1]] : '', 
                                                    headCell, row);
                                                
                                                return (<TableCell 
                                                    key={headCell.id} 
                                                    data-key={headCell.id} 
                                                    className={headCell.id} 
                                                    title={typeof display === 'string' ? display : ''}
                                                    align={headCell.numeric ? 'right' : 'left'}>
                                                    {display}
                                                </TableCell>
                                            )})}
                                            {props.hideActionCol ? null : 
                                                <TableCell align="right">
                                                    <Button aria-label="edit" className="tableaction">
                                                        {props.actionColType === "goto" ? 
                                                        <ArrowForwardIcon fontSize="small" color="disabled" /> : 
                                                        <OpenInBrowserIcon fontSize="small" color="disabled" />}
                                                    </Button>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (53) * emptyRows }}>
                                    <TableCell colSpan={props.headCells.length + 1} />
                                </TableRow>
                            )}
                            {props.spanningRow && props.spanningRow.colSpan && (
                            <TableRow>
                                <TableCell colSpan={props.spanningRow.colSpan}><b>{props.spanningRow.title}</b></TableCell>
                                <TableCell align="right"><b>${props.spanningRow.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b></TableCell>
                            </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {props.showPaging ?
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]} 
                    component="div"
                    count={props.rowCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                /> : null}
            </Paper>            
        </div>
    );
}