import Api from './api';

export default class UserStore {

    async getDrivers() {
        return new Promise(async (resolve, reject) => {
            Api.get(`/users/drivers`).then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
        });
    }
}