import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Badge, Box, Container, Tab, Tabs, Typography, Grid, Paper, InputBase, IconButton, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import AddIcon from '@material-ui/icons/Add';
import { RequestGrid } from '../../components/requests';
import classes from './request.module.css';
import { RequestStore } from '../../stores';
import authProvider from '../../providers/authProvider';

const DRAFT_STATUS = 'Draft';
const SUBMITTED_STATUS = 'Submitted';
const PROCESSING_STATUS = 'Processing';
const QUEUED_STATUS = 'Queued';
const OTHERS_STATUS = 'Others';
const PUBLISHED_STATUS = 'Published';

const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
};

const statusIndexes = { 
    draft: 0,
    submitted: 1,
    processing: 2,
    queued: 3,
    published: 4,
    others: 5
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `request-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const RequestPage = (props) => {
    const [currentTabIndex, setCurrentTabIndex] = useState(Number(localStorage.getItem('currentTabIndex')) || 0);
    const [requestStats, setRequestStats] = useState([]);
    const [dense, setDense] = useState(false);
    const [interactId, setInteractId] = useState('');
    const [reload, setReload] = useState(false);
    const [responseMessage, setResponseMessage] = useState({
        severity: '',
        message: ''
    });

    const requestStore = new RequestStore();

    useEffect(() => {
        loadRequestStats();
    }, []);

    const checkRequestStats = (statuses) => {
        const availableTabs = [];
        let currentTab = null;
        for (const [key, value] of Object.entries(statusIndexes)) {
            if (value === currentTabIndex) {
                currentTab = key;
                break;
            }
        }

        for (const [key, value] of Object.entries(statuses)) {
            if (value > 0) {
                availableTabs.push(key);
            }
        }

        if (availableTabs.length > 0) {
            if (!availableTabs.includes(currentTab)) {
                updateCurrentTabIndex(statusIndexes[availableTabs[0]]);
            }
        } else {
            if (interactId) {
                setResponseMessage({
                    severity: "error",
                    message: 'No request found for this Interact ID'
                });
            }
        }
    };

    const clearResponseMessage = () => {
        setResponseMessage({
            severity: responseMessage.severity,
            message: ''
        });
    };

    const handleSnackbarClose = () => {
        clearResponseMessage();
    };

    const loadRequestStats = (searchInteractId) => {
        requestStore.getRequestStats(searchInteractId || interactId)
            .then(response => {
                setRequestStats(response.success ? response.result : []);
                if (response.success) {
                    checkRequestStats(response.result);
                } else {
                    setResponseMessage({
                        severity: "error",
                        message: 'Error: unable to search interact ID'
                    });
                }
            }).catch(error => {
                console.log(error);
                setResponseMessage({
                    severity: "error",
                    message: error.message || 'Error: unable to search interact ID'
                });
            });
    };

    const updateCurrentTabIndex = (index) => {
        localStorage.setItem('currentTabIndex', index);
        setCurrentTabIndex(index);
    };

    const handleTabIndexChange = (event, newIndex) => {
        updateCurrentTabIndex(newIndex);
    };

    const getBadge = status => {
        return requestStats[status];
    };

    const onDenseChange = (value) => {
        setDense(value);
    };

    const onResponseMessage = (response) => {
        if(response && response.message !== "Created") {
            setResponseMessage({ duration: 15000, severity: response.severity||'info', message: response.message });
        }
    }

    const onReload = () => {
        setReload(false);
    };

    const searchRequest = () => {
        setReload(true);
        loadRequestStats();
    };

    const searchInteractId = (event) => {
        const id = event.target.value.trim();
        setInteractId(id);
        if (event.keyCode === 13) {
            setReload(true);
            loadRequestStats(id);
        }
    };

    return (
        <div className="wrapper wrapbody">
            <Snackbar open={responseMessage.message != ''} autoHideDuration={responseMessage.duration||5000} onClose={handleSnackbarClose}>
                <Alert severity={responseMessage.severity}>
                    {responseMessage.message}
                </Alert>
            </Snackbar>
            <Grid container item className={classes.page_title_frame}>
                <Typography variant="h5">
                    Requests
                </Typography>

                <div className={classes.page_search_box_frame}>
                    <Button
                        variant="contained"
                        component={Link}
                        to='/requests/create'
                        color="primary"
                        disabled={authProvider.readOnly() && !authProvider.isBranchOps()}
                    >
                        New Request
                    </Button>

                    <Paper className={classes.page_search_box}>
                        <InputBase
                            placeholder="Search Interact ID"
                            inputProps={{ 'aria-label': 'search interact ID' }}
                            onKeyUp={event => searchInteractId(event)}
                        />
                        <IconButton type="submit" aria-label="search" onClick={searchRequest}>
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </div>
            </Grid>

            <Tabs value={currentTabIndex} onChange={handleTabIndexChange} aria-label="request tabs" className={classes.tab_frame}>
                <Tab label="Draft" icon={<Badge badgeContent={getBadge('draft')} color="secondary"></Badge>} {...a11yProps(0)} className={classes.tab} />
                <Tab label="Submitted" icon={<Badge badgeContent={getBadge('submitted')} color="secondary"></Badge>} {...a11yProps(1)} className={classes.tab} />
                <Tab label="Processing" icon={<Badge badgeContent={getBadge('processing')} color="secondary"></Badge>} {...a11yProps(2)} className={classes.tab} />
                <Tab label="Queued" icon={<Badge badgeContent={getBadge('queued')} color="secondary"></Badge>} {...a11yProps(3)} className={classes.tab} />
                <Tab label="Published" icon={<Badge badgeContent={getBadge('published')} color="secondary"></Badge>} {...a11yProps(4)} className={classes.tab} />
                <Tab label="Others" icon={<Badge badgeContent={getBadge('others')} color="secondary"></Badge>} {...a11yProps(5)} className={classes.tab} />
            </Tabs>

            <TabPanel value={currentTabIndex} index={0}>
                <Container maxWidth="lg" className="container">
                    <RequestGrid status={DRAFT_STATUS} reload={reload} onReload={onReload} interactId={interactId} onRequestStatusChange={loadRequestStats} dense={dense} onDenseChange={onDenseChange} />
                </Container>
            </TabPanel>

            <TabPanel value={currentTabIndex} index={1}>
                <Container maxWidth="lg" className="container">
                    <RequestGrid status={SUBMITTED_STATUS} reload={reload} onReload={onReload} interactId={interactId} onRequestStatusChange={loadRequestStats} dense={dense} onDenseChange={onDenseChange} onResponseMessage={onResponseMessage} />
                </Container>
            </TabPanel>

            <TabPanel value={currentTabIndex} index={2}>
                <Container maxWidth="lg" className="container">
                    <RequestGrid status={PROCESSING_STATUS} reload={reload} onReload={onReload} interactId={interactId} onRequestStatusChange={loadRequestStats} dense={dense} onDenseChange={onDenseChange} />
                </Container>
            </TabPanel>

            <TabPanel value={currentTabIndex} index={3}>
                <Container maxWidth="lg" className="container">
                    <RequestGrid status={QUEUED_STATUS} reload={reload} onReload={onReload} interactId={interactId} onRequestStatusChange={loadRequestStats} dense={dense} onDenseChange={onDenseChange} />
                </Container>
            </TabPanel>

            <TabPanel value={currentTabIndex} index={4}>
                <Container maxWidth="lg" className="container">
                    <RequestGrid status={PUBLISHED_STATUS} reload={reload} onReload={onReload} interactId={interactId} onRequestStatusChange={loadRequestStats} dense={dense} onDenseChange={onDenseChange} />
                </Container>
            </TabPanel>

            <TabPanel value={currentTabIndex} index={5}>
                <Container maxWidth="lg" className="container">
                    <RequestGrid status={OTHERS_STATUS} reload={reload} onReload={onReload} interactId={interactId} onRequestStatusChange={loadRequestStats} dense={dense} onDenseChange={onDenseChange} />
                </Container>
            </TabPanel>
        </div>
    );
}

export default RequestPage;


