

import React, { Component } from 'react';

import './notice-bar.css';

class NoticeBar extends Component {

    render() {
        const icon = (this.props.icon != null && this.props.icon !== '' ? `k-icon k-i-${this.props.icon}` : null);
        const classname = `notice-bar-frame${(this.props.className !== null ? ` ${this.props.className}` : '')}`;
        return (
            <div className={classname}>
                <div className="notice-bar-details">
                    <div className="notice-bar-title">
                        <p>
                            { (icon != null) && <span className={icon}></span> }
                            <span className="notice-bar-title-text">{this.props.text}</span>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default NoticeBar;
