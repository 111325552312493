import React, { Fragment, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import { withStyles, Button, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { ConfirmDialog, Spinner, AddContainerControl } from '../../components';
import { ModCodes } from '../../utilities';
import { RequestStore, LoadPlanStore } from '../../stores';
import { useIsMountedRef } from '../../hooks'
import authProvider from '../../providers/authProvider';

import classes from './requestActionsMenu.module.css';

const DEFAULT_ERROR_MESSAGE = 'Update request failed. Please try again';



const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        width: '200px'
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export default function RequestActionsMenu(props) {
    const [selectedStatus, setSelectedStatus] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [updating, setUpdating] = React.useState(false);
    const [addContainerType, setAddContainerType] = React.useState(false);
    const [availableStatuses, setAvailableStatuses] = React.useState([]);
    const [firstRequest, setFirstRequest] = React.useState(null);
    const isMountedRef = useIsMountedRef();
    const requestStore = new RequestStore();
    const loadPlanStore = new LoadPlanStore();
    const history = useHistory();

    useEffect(() => {
        setUpdating(true);
        getAvailableStatusesByRequestIds(props.requestIds);
    }, [props.requestIds]);

    const getAvailableStatusesByRequestIds = (ids) => {
        requestStore.getAvailableStatusesByRequestIds(ids)
            .then(response => {
                if (isMountedRef.current) {
                    const filtered = response.filter(s => !s.current);
                    setAvailableStatuses(filtered);
                    setUpdating(false);
                }
            }).catch(error => {
                console.log(error);
            });
    };

    const showStatusDropdown = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const updateStatus = async (status) => {
        setUpdating(true);
        // send request
        if (props.statusCode) {
            // Update multiple requests status
            if (props.requestIds.length > 0) {
                const promises = props.requestIds.map(async id => {
                    const data = {
                        id,
                        newStatusId: status.id
                    };

                    return requestStore.editRequestStatus(id, data)
                        .then(response => {
                            return {
                                success: true,
                                result: response
                            };
                        }).catch(error => {
                            return {
                                success: false,
                                result: error.message || DEFAULT_ERROR_MESSAGE
                            };
                        });
                });

                const receivedData = await Promise.all(promises);
                const error = receivedData.find(item => item.success === false);
                if (error) {
                    // set error msg
                    props.setErrorMessage(`${error.result || DEFAULT_ERROR_MESSAGE}`);
                    setUpdating(false);
                } else {
                    // update parent requests
                    if (props.onStatusChange) {
                        props.onStatusChange();
                    }
                    setUpdating(false);
                }
            }
        } else {
            // Update single request status
            const data = {
                id: props.requestId,
                newStatusId: status.id
            };
            requestStore.editRequestStatus(props.requestId, data)
                .then(response => {
                    // update current status
                    const filtered = response.result.filter(s => !s.current);
                    setAvailableStatuses(filtered);
                    setUpdating(false);
                    if (props.onStatusChange) {
                        props.onStatusChange();
                    }
                }).catch(error => {
                    // set error msg
                    props.setErrorMessage(`${error.message || DEFAULT_ERROR_MESSAGE}`);
                    setUpdating(false);
                });
        }
    };

    const onChangeStatusClick = (event, item) => {
        if (item.confirm) {
            // show confirm dialog
            setOpenDialog(true);
            // set temporal status first, if confirm on the dialog, then change current status
            setSelectedStatus(item);
        } else if (item.code !== props.statusCode) {
            // send udpate request
            updateStatus(item);
            onMenuClose();
        }
    };

    const onMenuClose = () => {
        setAnchorEl(null);
    };

    const onDialogClose = () => {
        setOpenDialog(false);
        // No change for menu
    };

    const onDialogCancel = () => {
        setOpenDialog(false);
        // No changes for menu
    };

    const onDialogConfirm = () => {
        setOpenDialog(false);
        // send update request
        updateStatus(selectedStatus);
        onMenuClose();
    };

    const processNow = () => {
        // move this single request to processed and then go to its wizard
        if (props.requestIds.length === 1) {
            const requestId = props.requestIds[0];
            const status = availableStatuses.find(s => s.code === 'Processing');
            const data = {
                id: requestId,
                newStatusId: status.id
            };
            requestStore.editRequestStatus(requestId, data)
                .then(response => {
                    if (isMountedRef.current) {
                        if (response.success) {
                            history.push(`/loadplan/${requestId}/0/`);
                        }
                    }
                }).catch(error => {
                    console.log(error);
                });
        }
    };

    const combineAndProcess = () => {
        // validate request load plan
        let result = true;
        const requests = [];
        for (const id of props.requestIds) {
            const request = props.rows.find(request => request.id === id);
            setFirstRequest(props.rows.find(request => request.id === props.requestIds[0]));
            requests.push(request);
            if (request.loadRequests.length > 0) {
                result = false;
                props.setErrorMessage(`Request ${request.interactId} already has a load plan`);
                onMenuClose();
                break;
            }
        }

        // open dialog
        if (result) {
            setAddContainerType(true);
        }
    };

    const addAgencyBooking = () => {
        history.push(`/request/${props.requestIds[0]}/add/agencybooking`);
    };

    const mapStatuses = (statuses) => {
        return statuses.map(status => {
            const title = status.title === 'Cancelled' ? 'Cancel' : status.title === 'Draft' ? 'Revert to Draft' : `Move to ${status.title}`;
            return { 
                id: status.id, 
                title, 
                code: status.code, 
                action: status.action, 
                confirm: status.confirm 
            };
        });
    };

    const getActions = (statusCode) => {
        const actions = [];
        const mapped = mapStatuses(availableStatuses);
        // Only high level user can add agency booking / process now / combine and process

        switch (statusCode) {
            case 'draft': 
                if (mapped.length) {
                    // No more actions if no available statuses been returned from server
                    !authProvider.readOnly() && actions.push(...[
                        { title: 'Add agency booking', action: addAgencyBooking, mode: 'single' }
                    ]);
                }
                break;
            case 'submitted': 
                if (mapped.length) {
                    // No more actions if no available statuses been returned from server
                    !authProvider.readOnly() && actions.push(...[
                        { title: 'Process Now', action: processNow, mode: 'single' },
                        { title: 'Combine and Process', action: combineAndProcess, mode: 'multiple' },
                        { title: 'Add agency booking', action: addAgencyBooking, mode: 'single' }
                    ]);
                }
                break;
            case 'processing': 
                if (mapped.length) {
                    !authProvider.readOnly() && actions.push(...[
                        { title: 'Combine and Process', action: combineAndProcess, mode: 'multiple' },
                        { title: 'Add agency booking', action: addAgencyBooking, mode: 'single' }
                    ]);
                }
                break;
            case 'draft':
            case 'queued': 
                if (mapped.length) {
                    !authProvider.readOnly() && actions.push(...[
                        { title: 'Add agency booking', action: addAgencyBooking, mode: 'single' }
                    ]);
                }
                break;
            case 'published':
            case 'others': break;
        };

        for (const item of mapped) {
            item.action = onChangeStatusClick;
            actions.push(item);
        }

        return actions;
    };

    const requestsValidation = (volume) => {
        let result = true;
        let totalVolume = 0;
        for (const id of props.requestIds) {
            const request = props.rows.find(request => request.id === id);
            totalVolume += Number(request.estimatedLoadVolume);
        }

        if (Number(totalVolume.toFixed(2)) > volume) {
            result = false;
            props.setErrorMessage(`Cannot combine. Total request volume is ${totalVolume.toFixed(2)}m3. This is greater than the chosen container capacity of ${volume}m3.`);
        }

        return result;
    };

    const onAddNewLoadPlan = async (item) => {
        if (requestsValidation(item.containerType.volume)) {
            setUpdating(true);
            const result = {
                requestIds: props.requestIds,
                loadPlan: {}
            };

            const loadRequests = [];
            props.requestIds.map(async (id, index) => {
                const request = props.rows.find(request => request.id === id);
                loadRequests.push({       
                    id: request.id,
                    updateStatus: ModCodes.NEW,
                    customerOrganisation: request.customerOrganisation,
                    customerPersonName: request.customerPersonName,
                    request: request,
                    loadOrder: index + 1,
                    volumeEditable: true,
                    volume: request.estimatedLoadVolume
                });
            });

            // Request might have different org or des
            result.loadPlan = {
                containerType: {
                    id: item.containerType.id
                },
                origin: {
                    id: item.origin.id
                },
                destination: {
                    id: item.destination.id
                },
                departureDate: item.departureDate,
                loadRequests: loadRequests
            };

            loadPlanStore.consolidateRequests(result)
            .then(response => {
                if (props.onStatusChange) {
                    props.onStatusChange({severity: 'info', message: response.message });
                }
                setUpdating(false);
                setAddContainerType(false);
            }).catch(error => {
                props.setErrorMessage(`${error.message || DEFAULT_ERROR_MESSAGE}`);
                setUpdating(false);
                setAddContainerType(false);
            });
        }
        onMenuClose();
    };


    const closeAddContainerTypeDialog = () => {
        setAddContainerType(false);
        onMenuClose();
    };

    const trimStatusTitle = (title) => {
        if (title) {
            return title.replace('Revert to ', '');
        }
    };
    
    const setErrorMessage = (error) => {
        props.setErrorMessage(error);
    };

    return (
        <div>
            <AddContainerControl
                defaultOriginId={firstRequest?.originBranch.id || ''} // use the first request org and des as default
                defaultDestinationId={firstRequest?.destinationBranch.id || ''}
                onAddClick={(item) => onAddNewLoadPlan(item)}
                openDialog={addContainerType}
                closeDialog={closeAddContainerTypeDialog}
                showToggleButton={false}
                setErrorMessage={setErrorMessage}
            />
            <ConfirmDialog
                open={openDialog}
                handleClose={onDialogClose}
                handleCancel={onDialogCancel}
                handleConfirm={onDialogConfirm}
                dialogTitle={'Confirm Status Change'}
                dialogMessage={`Change from ${props.statusCode} to ${trimStatusTitle(selectedStatus?.title)}?`}
            />
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                onClick={showStatusDropdown}
                style={{ width: '200px' }}
                disabled={props.disabled || updating || getActions(props.statusCode.toLowerCase()).length === 0}
            >
                {updating ?
                    <Spinner color="inherit" size={24} /> :
                    <Fragment>
                        {'Actions'} <ArrowDropDownIcon fontSize="small" />
                    </Fragment>
                }
            </Button>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={onMenuClose}
            >
                {getActions(props.statusCode.toLowerCase()).map((item, ix) => (
                    !(item.mode === 'single' && props.requestIds.length > 1) && !(item.mode === 'multiple' && props.requestIds.length === 1) && <StyledMenuItem selected={false} key={ix} className={classes.statusMenuItem} 
                    onClick={(event) => item.action(event, item)} >
                        <ListItemText primary={item.title} />
                    </StyledMenuItem>
                ))}
            </StyledMenu>
        </div>
    );
}
