import axios from 'axios';
import jsonServerProvider from 'ra-data-json-server';
import { fetchUtils } from 'react-admin';
const ApiHostUrl = process.env.REACT_APP_API_HOST;
const getConfig = () => {
    const userToken = localStorage.getItem('userToken');
    return {
        baseURL: ApiHostUrl,
        headers: {
            'Authorization': `Bearer ${userToken}`,
            'X-Requested-With': 'foobar',
        },
        withCredentials: true
    }
}
const httpClient = (url, options = {}) => {
    const userToken = localStorage.getItem('userToken');
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    options.headers.set('Authorization', `Bearer ${userToken}`);
    // add your own headers here
    options.headers.set('X-Requested-With', 'foobar');
    options.mode = 'cors';
    options.credentials = 'include';
    return fetchUtils.fetchJson(url, options);
};
export default class Api {

    dataProvider = jsonServerProvider(ApiHostUrl, httpClient);

    static checkHeader = (headers) => {
        if (Object.keys(headers).length > 0) {
            const newHeaders = { ...getConfig().headers, ...headers };
            const newConfig = { ...getConfig(), headers: newHeaders }
            return newConfig;
        } else {
            return getConfig();
        }
    }

    static get = (url, headers = {}) => {
        return new Promise((resolve, rejects) => {
            const config = this.checkHeader(headers);
            axios.get(url, config).then(response => {
                switch (response.status) {
                    case 200:
                    case 204:
                        resolve(response.data);
                        break;
                    default:
                        rejects(response);
                }
            })
                .catch((error) => {
                    // Error
                    if (error.response) {
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        rejects(error.response.data);
                    } else if (error.request) {
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        rejects(error.request);
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        rejects(error.message);
                    }
                });
        })
    }

    static post = (url, data, headers = {}) => {
        return new Promise((resolve, rejects) => {
            const config = this.checkHeader(headers);
            axios.post(url, data, config).then(response => {
                switch (response.status) {
                    case 200:
                    case 204:
                        resolve(response.data);
                        break;
                    default:
                        rejects(response);
                }
            })
                .catch((error) => {
                    // Error
                    if (error.response) {
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        rejects(error.response.data);
                    } else if (error.request) {
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        rejects(error.request);
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        rejects(error.message);
                    }
                });
        })
    }

    static put = (url, data, headers = {}) => {
        return new Promise((resolve, rejects) => {
            const config = this.checkHeader(headers);
            axios.put(url, data, config).then(response => {
                switch (response.status) {
                    case 200:
                    case 204:
                        resolve(response.data);
                        break;
                    default:
                        rejects(response);
                }
            })
                .catch((error) => {
                    // Error
                    if (error.response) {
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        rejects(error.response.data);
                    } else if (error.request) {
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        rejects(error.request);
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        rejects(error.message);
                    }
                });
        })
    }

    static delete = (url, headers = {}) => {
        return new Promise((resolve, rejects) => {
            const config = this.checkHeader(headers);
            axios.delete(url, config).then(response => {
                switch (response.status) {
                    case 200:
                    case 204:
                        resolve(response.data);
                        break;
                    default:
                        rejects(response);
                }
            })
                .catch((error) => {
                    // Error
                    if (error.response) {
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        rejects(error.response.data);
                    } else if (error.request) {
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        rejects(error.request);
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        rejects(error.message);
                    }
                });
        })
    }


    // static blobGet = (url: string, headers: any = {}) => {
    //     return new Promise((resolve, rejects) => {
    //         axios.get(url, { baseURL: ApiHostUrl, responseType: 'blob', headers, withCredentials: true }).then(response => {
    //              switch(response.status) {
    //                  case 200:   
    //                  case 204: 
    //                     const type = response.headers['content-type'].trim().replace(/"/g, '');
    //                     const fileName = (response.headers['content-disposition'] != null ? response.headers['content-disposition'].split(';')[1].trim().split('=')[1].replace(/"/g, '') : moment().format('DD-MM-YYYY'));

    //                     resolve({ blob: response.data, fileName, type });
    //                  break;                      
    //                  default:
    //                      rejects(response);
    //              }
    //          }).catch(error => {
    //              rejects(error);
    //          })
    //      })
    // }

    // static blobPost = (url: string, data: any, headers: any = {}) => {
    //     return new Promise((resolve, rejects) => {
    //         axios.post(url, data, { baseURL: ApiHostUrl, responseType: 'blob', headers, withCredentials: true }).then(response => {
    //              switch(response.status) {
    //                  case 200:   
    //                  case 204: 
    //                     const type = response.headers['content-type'].trim().replace(/"/g, '');
    //                     const fileName = (response.headers['content-disposition'] != null ? response.headers['content-disposition'].split(';')[1].trim().split('=')[1].replace(/"/g, '') : moment().format('DD-MM-YYYY'));

    //                     resolve({ blob: response.data, fileName, type });
    //                  break;                      
    //                  default:
    //                      rejects(response);
    //              }
    //          }).catch(error => {
    //              rejects(error);
    //          })
    //      })
    // }


    static exec = (url, method, data = {}, headers = {}) => {
        return new Promise((resolve, rejects) => {
            axios({ url, baseURL: ApiHostUrl, headers, method, data, withCredentials: true }).then(response => {
                switch (response.status) {
                    case 200:
                    case 204:
                        resolve(response.data);
                        break;
                    default:
                        rejects(response);
                }
            }).catch(error => {
                rejects(error);
            })
        })
    }

    static download = (url, filename, headers = {}) => {
        // window.open(ApiHostUrl + url);
        return new Promise((resolve, rejects) => {
            const config = this.checkHeader(headers);
            config.url = url;
            config.method = 'GET';
            config.responseType = 'blob';
            
            axios(config).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }).catch(error => {
                // reject error.response.data if the config.responseType is not 'bolb'
                rejects(error.response);
            });
        })
    }
}
