import Api from './api';

export default class LoadRequestStore {

    async deleteLoadRequest(id) {
        return new Promise(async (resolve, reject) => {
            Api.delete(`/load_requests/${id}`).then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
        });
    }
}