import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typeography from '@material-ui/core/Typography';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Divider from '@material-ui/core/Divider';

import classes from './weekAdjuster.module.css';

const WeekAdjuster = (props) => {
    const { weekNumber, subtitle, onNext, onPrevious } = props;

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
        >
            <Grid item>
                <ArrowLeftIcon 
                    onClick={onPrevious} 
                    className={classes.iconButton} 
                    fontSize='large'    
                />
            </Grid>
            <Grid item>
                <Grid container >
                    <Typeography variant="subtitle1">{`Week ${weekNumber}`} &nbsp; &middot; &nbsp; {subtitle}</Typeography> 
                </Grid>
            </Grid>
            <Grid item>
                <ArrowRightIcon 
                    onClick={onNext} 
                    className={classes.iconButton} 
                    fontSize='large'
                    />
            </Grid>
        </Grid>
    )
}

export default WeekAdjuster;