import React, { useEffect, useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, Loading, Error } from 'react-admin';
import { Formik, Field, FieldArray, getIn } from 'formik';
import { useHistory } from "react-router-dom";
import { useIsMountedRef } from '../../hooks';

import {
    Button,
    CircularProgress,
    MuiSelect,
    CardActions, Card, CardContent,
    Container, Grid,
    FormControl, FormHelperText,
    Input, InputAdornment, InputLabel,
    LinearProgress, MenuItem, Paper,
    Select, TextField,
    Typography
} from '@material-ui/core';

import { PageWrapper } from '../../components';
import classes from './requestDetailsForm.module.css';

const RequestDetailsForm = (props) => {
    const history = useHistory();
    const dataProvider = useDataProvider();
    const isMountedRef = useIsMountedRef();

    const [branches, setBranches] = useState(props.branches);
    const [branchesLoading, setBranchesLoading] = useState(props.branchesLoading);
    const [users, setUsers] = useState([]);
    const [usersLoading, setUsersLoading] = useState(true);
    const [linehaulTypes, setLinehaulTypes] = useState([]);
    const [linehaulTypesLoading, setLinehaulTypesLoading] = useState(true);
    const [timeframes, setTimeframes] = useState([]);
    const [timeframesLoading, setTimeframesLoading] = useState(true);

    useEffect(() => {
        //Users drop down
        dataProvider.getList('users', {
            pagination: { page: 1, perPage: false },
            sort: { field: 'title', order: 'ASC' }
        }).then(response => {
            if (isMountedRef.current) {
                setUsers(response.data);
                setUsersLoading(false);
            }
        }).catch(error => {
            console.log(`Error getting users`);
            console.log(error);
        });

        //Linehaul Types
        dataProvider.getList('linehaul_types', {
            pagination: { page: 1, perPage: false },
            sort: { field: 'title', order: 'ASC' }
        }).then(response => {
            if (isMountedRef.current) {
                setLinehaulTypes(response.data);
                setLinehaulTypesLoading(false);
            }
        }).catch(error => {
            console.log(`Error getting linehaul types`);
            console.log(error);
        });

        dataProvider.getList('request_timeframes', {
            pagination: { page: 1, perPage: false },
            sort: { field: 'displayOrder', order: 'ASC' }
        }).then(response => {
            if (isMountedRef.current) {
                setTimeframes(response.data);
                setTimeframesLoading(false);
            }
        }).catch(error => {
            console.log(`Error request timeframes`);
            console.log(error);
        });
    }, []);

    const {
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
    } = props;

    const formatDate = (value) => {
        value = value.toString();
        if (value.length === 1) {
            return '0' + value;
        } else {
            return value;
        }
    };

    const handleDeliveryDateChange = (event) => {
        props.setFieldValue('requestTimeframeId', '', true);
        props.setFieldValue('requestedDeliveryDate', event.target.value, true);
        props.setFieldTouched('requestedDeliveryDate', true, false);
    };

    const handleRequestTimeframeChange = (event) => {
        props.setFieldValue('requestedDeliveryDate', '', true);
        props.setFieldValue('requestTimeframeId', event.target.value, true);
        props.setFieldTouched('requestTimeframeId', true, false);
    };

    const today = new Date();
    const startDate = today.getFullYear() + '-' + formatDate(today.getMonth() + 1) + '-' + formatDate(today.getDate());

    return (
        <Grid item container xs={12} sm={9}>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Request Details
                </Typography>
            </Grid>

            <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField id="estimatedLoadVolume"
                        className={classes.inputItem}
                        value={values.estimatedLoadVolume}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">m³</InputAdornment>,
                        }}
                        label="Estimated Load Volume"
                        type="number"
                        variant="filled"
                        error={errors.estimatedLoadVolume && touched.estimatedLoadVolume}
                        helperText={errors.estimatedLoadVolume}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!props.canEdit}
                        required
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl variant="filled" className={classes.inputItem} error={errors.linehaulTypeId && touched.linehaulTypeId} required>
                        <InputLabel id="linehaulTypeLabel">Linehaul Type</InputLabel>
                        <Select
                            IconComponent={linehaulTypesLoading ?
                                props => (<CircularProgress size={20} />) :
                                void 0}
                            labelId="linehaulTypeLabel"
                            id="linehaulType"
                            value={values.linehaulTypeId}
                            onChange={handleChange('linehaulTypeId')}
                            onBlur={handleBlur('linehaulTypeId')}
                            disabled={!props.canEdit}>
                            {linehaulTypes.map(lt => {
                                return <MenuItem
                                    value={lt.id}
                                    key={lt.id}
                                >{lt.title}</MenuItem>
                            })}
                        </Select>
                        <FormHelperText>{errors.linehaulTypeId}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} />

                <Grid item xs={12} md={4}>
                    <TextField
                        className={classes.inputItem}
                        error={errors.requestedUpliftDate && touched.requestedUpliftDate}
                        id="requestedUpliftDate"
                        value={values.requestedUpliftDate}
                        label="Uplift Date"
                        type="date"
                        variant="filled"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.requestedUpliftDate}
                        disabled={!props.canEdit}
                        required
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        className={classes.inputItem}
                        error={errors.requestedDeliveryDate && touched.requestedDeliveryDate}
                        id="requestedDeliveryDate"
                        value={values.requestedDeliveryDate}
                        label="Delivery Date"
                        type="date"
                        variant="filled"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{ min: values.requestedUpliftDate }}
                        onChange={handleDeliveryDateChange}
                        onBlur={handleBlur}
                        helperText={errors.requestedDeliveryDate}
                        disabled={!props.canEdit}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <FormControl variant="filled" className={classes.inputItem} error={errors.requestTimeframeId && touched.requestTimeframeId}>
                        <InputLabel id="requestTimeframeLabel">Timeframe</InputLabel>
                        <Select
                            IconComponent={timeframesLoading ?
                                props => (<CircularProgress size={20} />) :
                                void 0}
                            disabled={timeframesLoading || !props.canEdit}
                            labelId="requestTimeframeLabel"
                            id="requestTimeframe"
                            value={values.requestTimeframeId}
                            onChange={handleRequestTimeframeChange}
                            onBlur={handleBlur('requestTimeframeId')}
                        >
                            {timeframes.map(b => {
                                return <MenuItem
                                    selected={values.requestTimeframeId === b.id}
                                    value={b.id}
                                    key={b.id}
                                >{b.title}</MenuItem>
                            })}
                        </Select>
                        <FormHelperText>{errors.requestTimeframeId}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} />

                <Grid item xs={12} md={6}>
                    <FormControl variant="filled" className={classes.inputItem} error={errors.originBranchId && touched.originBranchId} required>
                        <InputLabel id="originBranchLabel">Origin Branch</InputLabel>
                        <Select
                            IconComponent={branchesLoading ?
                                props => (<CircularProgress size={20} />) :
                                void 0}
                            disabled={branchesLoading || !props.canEdit}
                            labelId="originBranchLabel"
                            id="originBranch"
                            value={values.originBranchId}
                            onChange={handleChange('originBranchId')}
                            onBlur={handleBlur('originBranchId')}
                        >
                            {branches.map(b => {
                                return <MenuItem
                                    selected={values.originBranchId === b.id}
                                    value={b.id}
                                    key={b.id}
                                >{b.title}</MenuItem>
                            })}
                        </Select>
                        <FormHelperText>{errors.originBranchId}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl variant="filled" className={classes.inputItem} error={errors.destinationBranchId && touched.destinationBranchId} required>
                        <InputLabel id="destinationBranchLabel">Destination Branch</InputLabel>
                        <Select
                            IconComponent={branchesLoading ?
                                props => (<CircularProgress size={20} />) :
                                void 0}
                            disabled={branchesLoading || !props.canEdit}
                            labelId="destinationBranchLabel"
                            id="destinationBranch"
                            value={values.destinationBranchId}
                            onChange={handleChange('destinationBranchId')}
                            onBlur={handleBlur('destinationBranchId')}
                        >
                            {branches.map(b => {
                                return <MenuItem
                                    selected={values.destinationBranchId === b.id}
                                    value={b.id}
                                    key={b.id}
                                >{b.title}</MenuItem>
                            })}
                        </Select>
                        <FormHelperText>{errors.destinationBranchId}</FormHelperText>
                    </FormControl>
                </Grid>
                
                <Grid item xs={12} />

                <Grid item sm={12}>
                    <TextField id="upliftDateConstraints"
                        className={classes.inputItem}
                        value={values.upliftDateConstraints}
                        label="Notes"
                        variant="filled"
                        multiline
                        helperText={errors.upliftDateConstraints}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!props.canEdit}
                    />
                </Grid>

                <Grid item xs={12} />

                <Grid item xs={12} md={6}>
                    <FormControl variant="filled" className={classes.inputItem} error={errors.assignedUserId && touched.assignedUserId} required>
                        <InputLabel id="assignedUserLabel">Assigned User</InputLabel>
                        <Select
                            IconComponent={usersLoading ?
                                props => (<CircularProgress size={20} />) :
                                void 0}
                            disabled={usersLoading || !props.canEdit}
                            labelId="assignedUserLabel"
                            id="assignedUser"
                            value={values.assignedUserId}
                            onChange={handleChange('assignedUserId')}
                            onBlur={handleBlur('assignedUserId')}
                        >
                            {users.map(u => {
                                return <MenuItem
                                    value={u.id}
                                    key={u.id}
                                >{u.displayName}</MenuItem>
                            })}
                        </Select>
                        <FormHelperText>{errors.assignedUserId}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField id="freightAllowance"
                        className={classes.inputItem}
                        value={values.freightAllowance}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        label="Freight Allowance"
                        type="number"
                        variant="filled"
                        error={errors.freightAllowance && touched.freightAllowance}
                        helperText={errors.freightAllowance}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!props.canEdit}
                        required
                    />
                </Grid>
            </Grid>
        </Grid>
    )
};

export default RequestDetailsForm;