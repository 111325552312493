
import React, { useState, useEffect } from 'react';
import { Button, Snackbar, Typography, Grid } from '@material-ui/core';
import { Loading } from 'react-admin';
import ArrowRight from '@material-ui/icons/ArrowRight';
import SaveIcon from '@material-ui/icons/Save';
import MuiAlert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import { RequestStore, LoadPlanStore, LoadRequestStore } from '../../../stores';
import {
    DraggableRequestGrid,
    SelectTable,
    WeekAdjuster,
    AddContainerControl
} from '../../../components';
import { ModCodes, FormatDate } from '../../../utilities';
import classes from './selectLoadPlans.module.css';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SelectLoadPlans = (props) => {

    const { request, onNext } = props;

    const [working, setWorking] = useState(false);
    const [reponseMessage, setResponseMessage] = useState({
        severity: "",
        message: ""
    });
    const [volumeAllocated, setVolumeAllocated] = useState(0);
    const [loadPlanOptions, setLoadPlanOptions] = useState(null);
    const [loadPlanOptionsLoading, setLoadPlanOptionsLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState([]);
    const [selectedLoadPlans, setSelectedLoadPlans] = useState([]);
    const [deletedLoadPlans, setDeletedLoadPlans] = useState([]);
    const [week, setWeek] = useState(null);
    const [weekStart, setWeekStart] = useState(null);
    const [weekChanging, setWeekChanging] = useState(false);
    const [newAddedLoadPlanId, setNewAddedLoadPlanId] = useState(null);
    const [exact, setExact] = useState(true);

    const requestStore = new RequestStore();
    const loadPlanStore = new LoadPlanStore();
    const loadRequestStore = new LoadRequestStore();

    useEffect(() => {
        getLoadplans();
    }, [weekStart, exact]);

    useEffect(() => {
        if (newAddedLoadPlanId) {
            handleCheck(newAddedLoadPlanId);
        }
    }, [loadPlanOptions]);

    const formatPlan = (lp) => {
        const plan = {
            id: lp.id,
            modCode: (lp.id) ? ModCodes.MODIFIED : ModCodes.NEW,
            departureDate: lp.departureDate,
            formattedDepartureDate: FormatDate.displayDate(lp.departureDate),
            containerTypeId: lp.containerType?.id || '',
            displayId: lp.displayId,
            containerTypeTitle: lp.containerType?.title || '',
            containerTypeCapacity: lp.containerType?.volume || '',
            volumeAvailable: lp.volumeAvailable,
            origin: lp.origin?.title || '',
            originId: lp.origin?.id || '',
            destination: lp.destination?.title || '',
            destinationId: lp.destination?.id || '',
            loadRequests: lp.loadRequests
        };
        return plan;
    };

    const getLoadplans = () => {
        if (request) {
            setWeekChanging(true);
            requestStore.getAvailableLoadPlan(request.id, weekStart, exact)
                .then(response => {
                    const tempSelectedLoadPlans = [...selectedLoadPlans];
                    let volumeAlreadyAllocated = 0;
                    const loadPlans = response.loadPlans.map(lp => {
                        const plan = formatPlan(lp);

                        // Check if it already has current request
                        const existingLoadRequetIndex = plan.loadRequests.findIndex(lr => lr.request.id === request.id);
                        if (existingLoadRequetIndex !== -1) {
                            // If not already selected
                            const existingSelectedIndex = tempSelectedLoadPlans?.loadRequests?.findIndex(lr => lr.request.id === request.id);
                            if (!existingSelectedIndex || existingSelectedIndex === -1) {
                                plan.loadRequests[existingLoadRequetIndex].volumeEditable = true;
                                const existingSameLoadPlans = tempSelectedLoadPlans.find(item => item.id === plan.id);
                                if (!existingSameLoadPlans) {
                                    // No same load plan then add plan to beginning of the tempSelectedLoadPlans
                                    tempSelectedLoadPlans.unshift(plan);
                                }
                                volumeAlreadyAllocated += plan.loadRequests[existingLoadRequetIndex].volume;
                            }
                        }
                        return plan;
                    });
                    setVolumeAllocated(volumeAlreadyAllocated);
                    // Add any added/selected loadplan
                    const respWeekStart = new Date(response.week.startDate);
                    const respWeekEnd = new Date(response.week.endDate);
                    for (let plan of tempSelectedLoadPlans) {
                        const departDate = new Date(plan.departureDate);
                        if (plan.modCode === ModCodes.NEW
                            && departDate >= respWeekStart
                            && departDate <= respWeekEnd) {
                            loadPlans.unshift(plan);
                        }
                    }
                    setWeek(response.week);
                    setLoadPlanOptions(loadPlans);
                    setSelectedLoadPlans(tempSelectedLoadPlans);
                    setLoadPlanOptionsLoading(false);
                    setWeekChanging(false);
                    if (!weekStart) {
                        // first run
                        const newDate = new Date(response.week.startDate);
                        const dateParam = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`;
                        setWeekStart(dateParam);
                    }
                })
                .catch(error => {
                    console.log(error);
                    setWeekChanging(false);
                    setResponseMessage({
                        severity: "error",
                        message: error.message || "Get load plan failed. Please try again"
                    });
                });
        }
    };

    const handleSnackbarClose = () => {
        setResponseMessage({
            severity: "",
            message: ""
        });
    };

    const getVolumeRemainingToAllocate = () => {
        return parseFloat((request.estimatedLoadVolume - volumeAllocated).toFixed(2));
    };

    const updateLoadPLans = async (loadPlan) => {
        const data = {
            containerType: {
                id: loadPlan.containerTypeId
            },
            origin: {
                id: loadPlan.originId
            },
            destination: {
                id: loadPlan.destinationId
            },
            departureDate: loadPlan.departureDate,
            loadRequests: loadPlan.loadRequests
        };

        if (loadPlan.modCode === ModCodes.MODIFIED) {
            data.id = loadPlan.id;
            const step = 1;
            return loadPlanStore.editLoadPlan(data, step)
                .then(response => {
                    // update load plan with new load request id and change status
                    const tempSelectedLoadPlans = [ ...selectedLoadPlans ];
                    const loadPlan = selectedLoadPlans.find(lp => lp.id === response.id);
                    const loadPlanIndex = selectedLoadPlans.findIndex(lp => lp.id === response.id);
                    if (loadPlan) {
                        for (const lr of loadPlan.loadRequests) {
                            if (lr.request.id === request.id) {
                                // Only one load request need update in this load plan
                                const loadRequest = response.loadRequests.find(item => item.request.id === request.id);
                                if (loadRequest) {
                                    lr.id = loadRequest.id;
                                }
                                lr.updateStatus = ModCodes.MODIFIED;
                                break;
                            }
                        }
                        tempSelectedLoadPlans[loadPlanIndex] = loadPlan;
                        setSelectedLoadPlans(tempSelectedLoadPlans);
                    }

                    return {
                        success: true,
                        result: `The load plan ${loadPlan.displayId} has been updated successfully`
                    };
                }).catch(error => {
                    return {
                        success: false,
                        result: error.message || `Update load plan ${loadPlan.displayId} failed, please try again`
                    };
                });
        } else if (loadPlan.modCode === ModCodes.NEW) {
            return loadPlanStore.addLoadPlan(data)
                .then(response => {
                    // Update the loadPlanOptions with response
                    const tempLoadPlanOptions = [ ...loadPlanOptions ];
                    const newLoadPlanIndex = loadPlanOptions.findIndex(item => item.id === newAddedLoadPlanId);
                    tempLoadPlanOptions[newLoadPlanIndex] = formatPlan(response);
                    const tempSelectedLoadPlans = [ ...selectedLoadPlans ];
                    tempSelectedLoadPlans.map(item => {
                        // Might need to change multiple selected load plan
                        if (item.id === newAddedLoadPlanId) {
                            // Only one load request fit this requirement
                            const loadRequestIndex = item.loadRequests.findIndex(lr => lr.request.id === request.id);
                            item.id = response.id;
                            item.modCode = ModCodes.MODIFIED;
                            if (loadRequestIndex > -1) {
                                item.loadRequests[loadRequestIndex].updateStatus = ModCodes.MODIFIED;
                                const newLoadRequest = response.loadRequests.find(lr => lr.request.id === request.id);
                                if (newLoadRequest) {
                                    item.loadRequests[loadRequestIndex].id = newLoadRequest.id;
                                }
                            }
                        }
                    });
                    setSelectedLoadPlans(tempSelectedLoadPlans);

                    // Update NewAddedLoadPlanId as null then update load plan options, becasue the changes of loadplan option will trigger the handleCheck()
                    setNewAddedLoadPlanId(null);
                    setLoadPlanOptions(tempLoadPlanOptions);
                    return {
                        success: true,
                        result: `The load plan ${loadPlan.displayId} has been created successfully`
                    };
                }).catch(error => {
                    return {
                        success: false,
                        result: error.message || "Create load plan failed, please try again"
                    };
                });
        }
    };

    const deleteLoadPLans = async (loadPlan) => {
        if (loadPlan.modCode !== ModCodes.NEW) {
            // The load plan in deletedLoadPlan has completed loadRequest info, it can be used for deleting
            loadPlan = deletedLoadPlans.find(lp => lp.id === loadPlan.id);
            if (loadPlan) {
                const loadRequest = loadPlan.loadRequests.find(loadRequest => loadRequest.request.id === request.id);
                if (loadRequest) {
                    // Only delete load plan associated with the request
                    return loadRequestStore.deleteLoadRequest(loadRequest.id)
                    .then(response => {
                        if (response.success) {
                            // remove load plan from load plan options if remove successfully
                            if (response.result.loadPlanRemoved) {
                                // update all load plan options array
                                const tempLoadPlans = [...loadPlanOptions];
                                const loadPlanIndex = loadPlanOptions.findIndex(item => item.id === loadPlan.id);
                                tempLoadPlans.splice(loadPlanIndex, 1);
                                setLoadPlanOptions(tempLoadPlans);
                            }
                            // update deleted load plan array
                            const tempDeletedLoadPlans = [...deletedLoadPlans];
                            const deletedLoadPlanIndex = deletedLoadPlans.findIndex(item => item.id === loadPlan.id);
                            tempDeletedLoadPlans.splice(deletedLoadPlanIndex, 1);
                            setDeletedLoadPlans(tempDeletedLoadPlans);
                            return {
                                success: true,
                                result: `The load plan ${loadPlan.displayId} has been updated successfully`
                            };
                        } else {
                            return {
                                success: false,
                                result: response.message
                            };
                        }
                    }).catch(error => {
                        return {
                            success: false,
                            result: error.message
                        };
                    })
                } else {
                    // Unchecked load plan && load requests does not include this request
                    // Ignore the delete action
                    return {
                        success: true,
                        result: ''
                    };
                }
            } else {
                // Ignore the delete action
                return {
                    success: true,
                    result: ''
                };
            }
        } else {
            const tempLoadPlans = [...loadPlanOptions];
            const index = loadPlanOptions.findIndex(item => item.id === loadPlan.id);
            tempLoadPlans.splice(index, 1);
            setLoadPlanOptions(tempLoadPlans);
            return {
                success: true,
                result: ''
            };
        }
    };

    const loadPlanHasInvalidVolume = (loadPlans) => {
        for (const loadPlan of loadPlans) {
            for (const loadRequest of loadPlan.loadRequests) {
                if (loadRequest.volume === 0) {
                    return true;
                }
            }
        }
        return false;
    };

    const submitLoadPlanForValidation = async (goToNext) => {
        setWorking(true);
        // Must select load plan then go to next
        // Save does not require select load plan
        // Save might just remove load plan after unchecking the container
        if (selectedLoadPlans.length === 0 && goToNext) {
            setResponseMessage({
                severity: "error",
                message: "Please select or create a load plan to proceed to the next step"
            });
            setWorking(false);
        } else if (loadPlanHasInvalidVolume(selectedLoadPlans)) {
            setResponseMessage({
                severity: "error",
                message: "Load plan cannot have zero volume."
            });
            setWorking(false);
        } else {
            const promises = loadPlanOptions.map(async item => {
                const selectedLoadplan = selectedLoadPlans.find(lp => lp.id === item.id);
                if (selectedLoadplan) {
                    // update load plan
                    return updateLoadPLans(selectedLoadplan);
                } else {
                    // delete load plan
                    return deleteLoadPLans(item);
                }
            });
            const receivedData = await Promise.all(promises);
            if (receivedData.length === loadPlanOptions.length) {
                // Show success or error messages
                const error = receivedData.find(item => item.success === false);
                if (error) {
                    setResponseMessage({
                        severity: "error",
                        message: error.result
                    });
                    setWorking(false);
                } else {
                    // Clean deleted load plan array
                    setDeletedLoadPlans([]);
                    if (goToNext) {
                        onNext();
                        setWorking(false);
                    } else {
                        setWorking(false);
                        setResponseMessage({
                            severity: "success",
                            message: "Load plans have been updated successfully"
                        });
                    }
                }
            }
        }
    };

    const onAddNewLoadPlan = (loadPlan) => {
        setNewAddedLoadPlanId(null);
        const newPlan = {
            id: loadPlan.id,
            modCode: ModCodes.NEW,
            departureDate: loadPlan.departureDate,
            formattedDepartureDate: FormatDate.displayDate(loadPlan.departureDate),
            containerTypeId: loadPlan.containerType?.id || '',
            containerTypeTitle: loadPlan.containerType?.title || '',
            containerTypeCapacity: loadPlan.containerType?.volume || '',
            volumeAvailable: loadPlan.containerType?.volume || '',
            origin: loadPlan.origin?.title || '',
            originId: loadPlan.origin?.id || '',
            destination: loadPlan.destination?.title || '',
            destinationId: loadPlan.destination?.id || '',
            loadRequests: loadPlan.loadRequests
        };
        const tempLoadPlans = [...loadPlanOptions];
        tempLoadPlans.unshift(newPlan);
        setLoadPlanOptions(tempLoadPlans);
        // This loadPlan.id is generated locally
        setNewAddedLoadPlanId(loadPlan.id);
    };

    const name = 'Select Container';

    const headerColumns = [
        { label: 'Load Plan', value: 'displayId' },
        { label: 'Container Type', value: 'containerTypeTitle' },
        { label: 'Origin', value: 'origin' },
        { label: 'Destination', value: 'destination' },
        { label: 'Departure', value: 'formattedDepartureDate' },
        { label: 'Capacity', value: 'containerTypeCapacity' },
        { label: 'Volume Available', value: 'volumeAvailable' }
    ];

    const handleCheck = (id) => {
        const tempSelectedLoadPlans = [...selectedLoadPlans];
        const itemIndex = tempSelectedLoadPlans.findIndex(plan => plan.id === id);
        if (itemIndex === -1) {
            // handle check
            const checkedLoadPlanIndex = deletedLoadPlans.findIndex(plan => plan.id === id);
            if (checkedLoadPlanIndex !== -1) {
                // Remove the load plan from deletedLoadPlan array if load plan been checked again
                deletedLoadPlans.splice(checkedLoadPlanIndex, 1);
            }

            const checkedPlan = loadPlanOptions.find(plan => plan.id === id);
            if (checkedPlan) {
                const planCopy = JSON.parse(JSON.stringify(checkedPlan));
                if (!planCopy.loadRequests) {
                    planCopy.loadRequests = [];
                }
                // Check to see if this request is already part of load plan
                const existingLoadrequest = planCopy.loadRequests.find(lr => lr.request.id === request.id);
                const newItemOrder = planCopy.loadRequests.length + 1;
                let volumeToAllocate = getVolumeRemainingToAllocate();
                if (volumeToAllocate > planCopy.volumeAvailable) {
                    volumeToAllocate = planCopy.volumeAvailable;
                }
                const newAllocatedAmount = volumeAllocated + volumeToAllocate;
                setVolumeAllocated(newAllocatedAmount);

                if (existingLoadrequest) {
                    existingLoadrequest.volumeEditable = true;
                    existingLoadrequest.volume = volumeToAllocate;
                } else {
                    // Else make new one
                    // Add editable load request
                    planCopy.loadRequests.push({
                        id: request.id,
                        updateStatus: ModCodes.NEW,
                        customerOrganisation: request.customerOrganisation,
                        customerPersonName: request.customerPersonName,
                        request: request,
                        loadOrder: newItemOrder,
                        volumeEditable: true,
                        volume: volumeToAllocate < 0 ? 0 : volumeToAllocate
                    });
                }
                tempSelectedLoadPlans.push(planCopy);
            }
        }
        else {
            // Handle uncheck
            const loadPlan = tempSelectedLoadPlans[itemIndex];
            const editableRequest = loadPlan.loadRequests.find(r => r.volumeEditable);
            setVolumeAllocated(volumeAllocated > 0 ? volumeAllocated - editableRequest.volume : editableRequest.volume);
            tempSelectedLoadPlans.splice(itemIndex, 1);
            // Update deleted load plan
            const loadRequest = loadPlan.loadRequests.find(lr => lr.id === lr.request.id);
            // if loadRequest exist then it has been added then deleted locally, so don't send server a delete request
            if (!loadRequest) {
                setDeletedLoadPlans([...deletedLoadPlans, loadPlan]);
            }
        }
        setSelectedLoadPlans(tempSelectedLoadPlans);
    };

    const isSelected = (id) => {
        const loadPlanIndex = selectedLoadPlans.findIndex(plan => plan.id === id);
        const selected = loadPlanIndex !== -1;
        return selected;
    };

    const handleExpand = (id) => {
        const tempExpandedRows = [...expandedRows];
        const itemIndex = tempExpandedRows.indexOf(id);
        if (itemIndex === -1) {
            tempExpandedRows.push(id);
        }
        else {
            tempExpandedRows.splice(itemIndex, 1);
        }
        setExpandedRows(tempExpandedRows);
    };

    const isExpanded = (id) => {
        return isSelected(id) || expandedRows.indexOf(id) != -1;
    };

    const onLoadRequestReorder = (loadRequestId, items) => {
        // Update new order
        items.forEach((item, index) => {
            item.loadOrder = index + 1;
            // Mark the updateStatus so api will change load order for each load request
            item.updateStatus = ModCodes.MODIFIED;
        });
        const tempSelectedPlans = [...selectedLoadPlans];
        const planIndex = tempSelectedPlans.findIndex(plan => plan.id === loadRequestId);
        const plan = selectedLoadPlans[planIndex];
        const tempPlan = { ...plan };
        tempPlan.loadRequests = items;
        tempSelectedPlans[planIndex] = tempPlan;
        setSelectedLoadPlans(tempSelectedPlans);
    };

    const onGridVolumeChange = (loadPlanId, loadRequestId, volume) => {
        const tempSelectedPlans = [...selectedLoadPlans];
        const plan = tempSelectedPlans.find(plan => plan.id === loadPlanId);
        const loadRequest = plan.loadRequests.find(request => request.id === loadRequestId);
        const originalVolume = loadRequest.volume;
        const newAllocatedVolume = volumeAllocated - originalVolume + volume;
        setVolumeAllocated(newAllocatedVolume);
        loadRequest.volume = volume;
        loadRequest.updateStatus = loadRequest.updateStatus ? loadRequest.updateStatus : ModCodes.MODIFIED;
        setSelectedLoadPlans(tempSelectedPlans);
    };

    const onWeekChange = (dayChangeAmount) => {
        let newDate = new Date(week.startDate);
        newDate.setDate(newDate.getDate() + dayChangeAmount);
        const dateParam = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`;
        setWeekStart(dateParam);
    };

    // This is the content of the expanded rows
    const renderItem = (loadPlanId) => {
        let data = selectedLoadPlans.find(loadPlan => loadPlan.id === loadPlanId);
        if (!data) {
            data = loadPlanOptions.find(loadPlan => loadPlan.id === loadPlanId);
        }

        return (
            <div className={classes.collapseContent}>
                {!data.loadRequests || data.loadRequests.length === 0 ?
                    <div>Nothing</div> :
                    <DraggableRequestGrid
                        key={request.id}
                        isSelected={() => isSelected(data.id)}
                        loadRequestId={data.id}
                        volumeAvailable={data.volumeAvailable}
                        loadRequests={data.loadRequests}
                        onReorder={onLoadRequestReorder}
                        onVolumeChange={onGridVolumeChange}
                    />}
            </div>);
    };

    const getTableData = () => {
        // Table reload so clean selected load plans
        setSelectedLoadPlans([]);
        setExact(!exact);
    };

    const setErrorMessage = (error) => {
        setResponseMessage({
            severity: "error",
            message: error
        });
    };

    if (!request) return <Loading />;

    if (working) return <Skeleton animation="wave" />;

    return (
        <Grid item xs={12} sm={12}>
            <Snackbar open={reponseMessage.message} autoHideDuration={5000} onClose={handleSnackbarClose}>
                <Alert severity={reponseMessage.severity}>
                    {reponseMessage.message}
                </Alert>
            </Snackbar>

            <Grid container spacing={3} style={{ paddingTop: '15px' }}>
                <Grid item xs={12} sm={12}>
                    {loadPlanOptionsLoading ? (<Skeleton animation="wave" />) :
                        (<Grid container spacing={2}>
                            <Grid item xs={12} className={classes.page_content_title_frame}>
                                <Typography variant="h6">
                                    Select a Load Plan (Or Create a New One)
                                </Typography>

                                <div className={classes.page_content_button_group_frame}>
                                    {week && <WeekAdjuster
                                        weekNumber={week.weekNumber}
                                        subtitle={`${FormatDate.displayDate(week.startDate)} - ${FormatDate.displayDate(week.endDate)}`}
                                        onPrevious={() => onWeekChange(-7)}
                                        onNext={() => onWeekChange(7)}
                                    />}
                                    <AddContainerControl
                                        defaultOriginId={request.originBranch.id}
                                        defaultDestinationId={request.destinationBranch.id}
                                        onAddClick={(item) => onAddNewLoadPlan(item)}
                                        setErrorMessage={setErrorMessage}
                                    />
                                </div>
                            </Grid>

                            {weekChanging ? <Skeleton animation="wave" variant="rect" height={200} width={'100%'} /> : <Grid item xs={12}>
                                <SelectTable
                                    name={name}
                                    headerColumns={headerColumns}
                                    data={loadPlanOptions}
                                    onCheckEvent={handleCheck}
                                    onExpandEvent={handleExpand}
                                    collapseContent={renderItem}
                                    isSelected={isSelected}
                                    allowSelect={true}
                                    exact={exact}
                                    getTableData={getTableData}
                                    isExpanded={isExpanded}
                                />
                            </Grid>}
                        </Grid>)
                    }
                </Grid>

                <Grid className={classes.save_form_btn}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        startIcon={<SaveIcon />}
                        onClick={() => submitLoadPlanForValidation(false)}
                    >
                        Save
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        endIcon={<ArrowRight />}
                        onClick={() => submitLoadPlanForValidation(true)}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default SelectLoadPlans;