import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
    Button, Grid,TextField,
    Typography
} from '@material-ui/core';

import classes from './reportForm.module.css';

const reportValidationSchema = () => {
    return Yup.object().shape({
        startDate: Yup.date()
            .required('Required'),
        endDate: Yup.date()
            .required('Required')
            .min(Yup.ref('startDate'), "The End Date must come after the Start Date"),
    });
};

const reportForm = (props) => {

    const formatDate = (value) => {
        const date = new Date(value);
        return `${date.getFullYear()}${(date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1)}${(date.getDate() < 10 ? '0' : '') + date.getDate()}`;
    };

    const onSubmit = (values, type) => {
        values.type = type;
        
        const fileName = `${props.title.replaceAll(' ', '_')}_${formatDate(values.startDate)}-${formatDate(values.endDate)}.${type}`;
        props.onSubmit(values, fileName);
    };

    return (
        <Formik
            initialValues={props.formValues}
            enablereinitialize
            validationSchema={reportValidationSchema}
            onSubmit={(values) => onSubmit(values, 'xlsx')}
            render={({ isSubmitting, errors, touched, values, handleBlur, handleChange }) => (
                <Form className={classes.form}>
                    <Grid item container xs={16} sm={14} spacing={2}>
                        <Grid item xs={18} sm={5}>
                            <TextField
                                className={classes.input_item}
                                error={errors.startDate && touched.startDate}
                                id="startDate"
                                value={values.startDate}
                                label="Start Date"
                                type="date"
                                variant="filled"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={errors.startDate}
                                required
                            />
                        </Grid>

                        <Grid item xs={18} sm={5}>
                            <TextField
                                className={classes.input_item}
                                error={errors.endDate && touched.endDate}
                                id="endDate"
                                value={values.endDate}
                                label="End Date"
                                type="date"
                                variant="filled"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={errors.endDate}
                                required
                            />
                        </Grid>
                        <Grid Container item xs={2} sm={2} className={classes.button_group}>
                        <Grid item xs={1} sm={1}>
                                <Button
                                    id="xlsxBtn"
                                    type="submit"
                                    onClick={() => onSubmit(values, 'xlsx')}
                                    className={classes.report_button}
                                    variant="contained"
                                > Generate
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            )}
        />
    )
};

export default reportForm;