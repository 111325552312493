import React, { useEffect, useState } from 'react';
import { useDataProvider, Loading, Error } from 'react-admin';
import { useHistory } from "react-router-dom";
import { RequestStore, LoadPlanStore } from '../../../stores';
import { Button, FormControl, Grid, IconButton, InputLabel, InputBase, Paper, Select, MenuItem, Snackbar, Typography} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import MuiAlert from '@material-ui/lab/Alert';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import Skeleton from '@material-ui/lab/Skeleton';

import { DraggableRequestGrid, SelectTable, WeekAdjuster, Spinner, AddContainerControl } from '../../../components';
import { FormatDate, ModCodes } from '../../../utilities';
import authProvider from '../../../providers/authProvider';
import classes from './loadPlansList.module.css';
import { useIsMountedRef } from '../../../hooks'

const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const LoadPlansList = () => {
    const history = useHistory();
    const dataProvider = useDataProvider();
    const isMountedRef = useIsMountedRef();

    const [anchorEl, setAnchorEl] = useState(null);
    const [working, setWorking] = useState(true);
    const [week, setWeek] = useState(null);
    const [weekStart, setWeekStart] = useState(localStorage.getItem('weekStart') || FormatDate.getMonday());
    const [loadPlans, setLoadPlans] = useState([]);
    const [loadPlanDisplayId, setLoadPlanDisplayId] = useState('');
    const [expandedRows, setExpandedRows] = useState([]);
    const [selectedLoadPlans] = useState([]);
    const [publishDetails, setPublishDetails] = useState(null);
    const [branchFilter, setBranchFilter] = useState('none');
    const [driverFilter, setDriverFilter] = useState('none');
    const [vehicleFilter, setVehicleFilter] = useState('none');
    const [branches, setBranches] = useState([]);
    const [branchesLoading, setBranchesLoading] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [addContainerType, setAddContainerType] = useState(false);
    const [responseMessage, setResponseMessage] = useState({
        severity: 'info',
        message: ''
    });

    const name = 'Select Container';

    const headerColumns = [
        { label: 'ID', value: 'displayId' },
        { label: 'Container Type', value: 'containerTypeTitle' },
        { label: 'Container #', value: 'containerNumber' },
        { label: 'Origin', value: 'origin' },
        { label: 'Destination', value: 'destination' },
        { label: 'Departure Date', value: 'departureDate' },
        { label: 'Container Capacity', value: 'containerCapacity' },
        { label: 'Volume Available', value: 'volumeAvailable' }
    ];

    const requestStore = new RequestStore();
    const loadPlanStore = new LoadPlanStore();

    useEffect(() => {
        setWorking(true);
        getLoadPlans(weekStart, buildFilter());
        getPublishStatus(weekStart)
    }, [weekStart]);

    useEffect(() => {
        loadData('branches');
        loadData('vehicles');
        loadData('users/drivers');
    }, []);
    
    const onWeekChange = (dayChangeAmount) => {
        let newDate = new Date(week.startDate);
        newDate.setDate(newDate.getDate() + dayChangeAmount);
        const dateParam = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`;
        setWeekStart(dateParam);
        localStorage.setItem('weekStart', dateParam);
    };

    const loadData = list => {
        dataProvider.getList(list, {
            pagination: { page: 1, perPage: false },
            filter: { isPickup: 'true' },
            sort: { field: 'title', order: 'ASC' }
        }).then(response => {
            if (isMountedRef.current) {
                if (list === 'branches') {
                    setBranches(response.data);
                    setBranchesLoading(false);
                } else if (list === 'users/drivers') {
                    setDrivers(response.data);
                } else if (list === 'vehicles') {
                    setVehicles(response.data);
                }
            }
        }).catch(error => {
            setResponseMessage({
                severity: "error",
                message: `Error getting ${list} list, please try again`
            });
        });
    };

    const checkLoadPlanReadyToPublish = (loadPlan) => {
        let result = true;
        const processedStatuses = ['Queued', 'Published'];
        for (const item of loadPlan.loadRequests) {
            if (item.request && !processedStatuses.includes(item.request.requestStatus.title)) {
                result = false;
                break;
            }
        }

        if (result) {
            for (const item of loadPlan.journeyLegs) {
                if (!item.vehicle?.id) {
                    result = false;
                    break;
                }
            }
        }

        return result;
    };

    const checkLoadPlanHasAgencyBooking = (journeyLegs) => {
        let result = false;
        for (const item of journeyLegs) {
            if(item.agencyBooking && item.agencyBooking.id) {
                result = true;
                break;
            }
        }
        return result;
    };

    const getLoadPlans = (weekStart, filter) => {
        requestStore.getLoadPlansByWeek(weekStart, filter)
            .then(response => {
                const loadPlans = response.loadPlans.map(lp => {
                    const plan = {
                        id: lp.id,
                        displayId: lp.displayId,
                        modCode: (lp.id) ? ModCodes.MODIFIED : ModCodes.NEW,
                        departureDate: FormatDate.displayDate(lp.departureDate),
                        containerNumber: lp.containerNumber || '',
                        containerTypeId: lp.containerType?.id || '',
                        containerTypeTitle: lp.containerType?.title || '',
                        containerCapacity: lp.containerType.volume,
                        volumeAvailable: lp.volumeAvailable,
                        origin: lp.origin?.title || '',
                        originId: lp.origin?.id || '',
                        destination: lp.destination?.title || '',
                        destinationId: lp.destination?.id || '',
                        loadRequests: lp.loadRequests,
                        readyToPublish: checkLoadPlanReadyToPublish(lp),
                        hasAgencyBooking: checkLoadPlanHasAgencyBooking(lp.journeyLegs),
                    };

                    return plan;
                });
                setLoadPlans(loadPlans);
                setWeek(response.week);
                setWorking(false);
            })
            .catch(error => {
                console.log(error);
                setWorking(false);
                setResponseMessage({
                    severity: "error",
                    message: error.message || "Get load plan failed, please try again"
                });
            });
    };

    const getPublishStatus = weekStart => {
        loadPlanStore.getPublishStatus(weekStart)
            .then(response => {
                if (response.success) {
                    setPublishDetails(response.result);
                }
            })
            .catch(error => {
                console.log(error);
                setResponseMessage({
                    severity: "error",
                    message: error.message || "Get publish status failed, please try again"
                });
            });
    };

    const publishForWeek = () => {
        setWorking(true);
        requestStore.publishLoadPlansForWeek(weekStart)
            .then(response => {
                if (response.success) {
                    setPublishDetails(response.result.activity);
                }                
                setWorking(false);
            })
            .catch(error => {
                setResponseMessage({
                    severity: "error",
                    message: error.message || 'Publish failed, please try again later'
                });
                setWorking(false);
            });
    };

    const handleExpand = (id) => {
        const tempExpandedRows = [...expandedRows];
        const itemIndex = tempExpandedRows.indexOf(id);
        if (itemIndex === -1) {
            tempExpandedRows.push(id);
        }
        else {
            tempExpandedRows.splice(itemIndex, 1);
        }
        setExpandedRows(tempExpandedRows);
    };

    const isExpanded = (id) => {
        return isSelected(id) || expandedRows.indexOf(id) !== -1;
    };

    const isSelected = (id) => {
        const loadPlanIndex = selectedLoadPlans.findIndex(plan => plan.id === id);
        const selected = loadPlanIndex !== -1;
        return selected;
    };

    const renderItem = (loadPlanId) => {
        let data = selectedLoadPlans.find(p => p.id === loadPlanId);
        if (!data) {
            data = loadPlans.find(p => p.id === loadPlanId);
        }

        return (
            <div className={classes.collapseContent}>
                <DraggableRequestGrid
                    key={loadPlanId}
                    isSelected={() => isSelected(data.id)}
                    loadRequestId={data.id}
                    volumeAvailable={data.volumeAvailable}
                    loadRequests={data.loadRequests}
                />
            </div>
        );
    };

    const onLoadClick = (ev, id) => {
        history.push(`loadplan/${id}`);
    };

    const onPublish = ev => {
        publishForWeek();
    };

    const publishToolbar = () => {
        if (publishDetails) {
            // has been published
            const publishDate = FormatDate.displayDate(new Date(publishDetails.createdDate));
            return (<div className={classes.contentActions}>
                    <div>Published on {publishDate} by {publishDetails.updatedBy.firstName}</div>
                </div>);
        } else if (authProvider.highLevelUser() && !working && loadPlans.length) {
            return <div className={classes.contentActions}>
                <Button
                    id="publishBtn"
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={onPublish}
                    startIcon={working ? <Spinner color="inherit" size={24} /> : <PublishIcon />}
                    disabled={authProvider.readOnly()}
                >
                    Publish
                </Button>
            </div>;
        } else {
            return null;
        }
    };

    const resetFilter = () => {
        setBranchFilter('none');
        setDriverFilter('none');
        setVehicleFilter('none');
    };

    const buildFilter = (list, value) => {
        const filter = {
            branchId: branchFilter,
            driverId: driverFilter,
            vehicleId: vehicleFilter
        };
        if (list === 'branch') {
            filter.branchId = value;
            filter.driverId = filter.vehicleId = 'none';
            setBranchFilter(value);
        } else if (list === 'driver') {
            filter.driverId = value;
            filter.branchId = filter.vehicleId = 'none';
            setDriverFilter(value);
        } else if (list === 'vehicle') {
            filter.vehicleId = value;
            filter.driverId = filter.branchId = 'none';
            const vehicle = vehicles.find(b => b.id === value);
            if (vehicle) {
                filter.vehicleName = vehicle.title;
                setVehicleFilter(value);
            }
        }

        if (filter.branchId !== 'none') {
            filter.branchName = branches.find(b => b.id === filter.branchId).title;
        }
        if (filter.driverId !== 'none') {
            const driver = drivers.find(b => b.id === filter.driverId);
            filter.driverName = `${driver.firstName} ${driver.lastName}`;
        }
        if (filter.vehicleId !== 'none') {
            filter.vehicleName = vehicles.find(b => b.id === filter.vehicleId).title;
        }

        return filter;
    };

    const handleFilterChange = (list, value) => {
        resetFilter();
        const filter = buildFilter(list, value);
        getLoadPlans(weekStart, filter);
    };

    const filterResults = async () => {
        const filter = buildFilter();
        await requestStore.downloadLoadPlansByWeek(weekStart, filter).catch(error => {
            if (error && error.status === 500) {
                setResponseMessage({
                    severity: "error",
                    message: "Internal server error in 'downloadLoadPlansByWeek'. Please contact the administrator."
                });
            }
        });
    };

    const clearResponseMessage = () => {
        setResponseMessage({
            severity: responseMessage.severity,
            message: ''
        });
    };

    const handleSnackbarClose = () => {
        clearResponseMessage();
    };

    const onMenuClose = () => {
        setAnchorEl(null);
    };

    const closeAddContainerTypeDialog = () => {
        setAddContainerType(false);
        onMenuClose();
    };

    const onAddNewLoadPlan = (item) => {
        setWorking(true);
        const data = {
            ...item,
            origin: {
                id: item.origin.id
            },
            destination: {
                id: item.destination.id
            }
        };

        loadPlanStore.addLoadPlan(data)
        .then(response => {
            getLoadPlans(weekStart, buildFilter());
        })
        .catch(error => {
            setResponseMessage({
                severity: "error",
                message: error.message || 'Add load plan failed, please try again later'
            });
            setWorking(false);
        });
    };

    const setErrorMessage = (error) => {
        setResponseMessage({
            severity: "error",
            message: error
        });
    };

    const searchLoadPlan = () => {
        loadPlanStore.searchByDisplayId(loadPlanDisplayId)
        .then(response => {
            const week = response.departureDate.slice(0,10);
            setWeekStart(week);
            localStorage.setItem('weekStart', week);
        })
        .catch(error => {
            console.log('error', error);
            setResponseMessage({
                severity: "error",
                message: error.message || "Search load plan failed, please try again"
            });
        });
    };

    const searchLoadPlanId = (event) => {
        const id = event.target.value.trim();
        setLoadPlanDisplayId(id);
        if (event.keyCode === 13) {
            searchLoadPlan(id);
        }
    };

    // const disableScheduleDownload = (branchFilter === 'none' && driverFilter === 'none' && vehicleFilter === 'none') || !publishDetails;
    const disableScheduleDownload = (branchFilter === 'none' && vehicleFilter === 'none') || !publishDetails; // Might need driverFilter later

    return (
        <div className={classes.pageRoot}>
            <Snackbar open={responseMessage.message != ''} autoHideDuration={5000} onClose={handleSnackbarClose}>
                <Alert severity={responseMessage.severity}>
                    {responseMessage.message}
                </Alert>
            </Snackbar>

            <Grid container className={classes.page_title_frame}>
                <Typography variant="h5" style={{ textTransform: 'capitalize' }}>
                    Load Plans
                </Typography>

                <div className={classes.page_title_action_frame} >
                    <Paper className={classes.page_search_box}>
                        <InputBase
                            placeholder="Search Load Plan ID"
                            inputProps={{ 'aria-label': 'search load plan ID' }}
                            onKeyUp={event => searchLoadPlanId(event)}
                        />
                        <IconButton type="submit" aria-label="search" onClick={searchLoadPlan}>
                            <SearchIcon />
                        </IconButton>
                    </Paper>
 
                    {!branchesLoading && <AddContainerControl
                        defaultOriginId={''}
                        defaultDestinationId={''}
                        onAddClick={(item) => onAddNewLoadPlan(item)}
                        openDialog={addContainerType}
                        closeDialog={closeAddContainerTypeDialog}
                        showToggleButton={true}
                        setErrorMessage={setErrorMessage}
                        branches={branches}
                    />}
                </div>
            </Grid>

            <Paper className={classes.contentWrap}>
                <div className={classes.content}>
                    <Grid container spacing={2} direction="column">
                        <Grid item xs={12} className={classes.page_content_title_frame}>
                            <div className={classes.filter}>
                                <FormControl variant="filled" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Branch</InputLabel>
                                    <Select 
                                        id='branchselect'
                                        value={branchFilter}
                                        onChange={(event) => handleFilterChange('branch', event.target.value)}
                                    >
                                        <MenuItem key={'none'} value={'none'}><em>None</em></MenuItem>
                                        {branches.map(b => 
                                            <MenuItem key={b.id} value={b.id}>{b.title}</MenuItem>
                                        )}                                        
                                    </Select>
                                </FormControl>
                                {/* <FormControl variant="filled" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Driver</InputLabel>
                                    <Select 
                                        id='driverselect'
                                        value={driverFilter}
                                        onChange={(event) => handleFilterChange('driver', event.target.value)}
                                    >
                                        <MenuItem key={'none'} value={'none'}><em>None</em></MenuItem>
                                        {drivers.map(b => 
                                            <MenuItem key={b.id} value={b.id}>{b.firstName} {b.lastName}</MenuItem>
                                        )}                                        
                                    </Select>
                                </FormControl> */}
                                <FormControl variant="filled" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Vehicle</InputLabel>
                                    <Select 
                                        id='vehicleselect'
                                        value={vehicleFilter}
                                        onChange={(event) => handleFilterChange('vehicle', event.target.value)}
                                    >
                                        <MenuItem key={'none'} value={'none'}><em>None</em></MenuItem>
                                        {vehicles.map(b => 
                                            <MenuItem key={b.id} value={b.id}>{b.title}</MenuItem>
                                        )}                                        
                                    </Select>
                                </FormControl>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    onClick={filterResults}
                                    className={classes.button}
                                    startIcon={<GetAppIcon />}
                                    disabled={disableScheduleDownload}
                                >
                                    Download
                                </Button>
                            </div>

                            <div className={classes.page_content_button_group_frame}>
                                {week && <WeekAdjuster
                                    weekNumber={week.weekNumber}
                                    subtitle={`${FormatDate.displayDate(week.startDate)} - ${FormatDate.displayDate(week.endDate)}`}
                                    onPrevious={() => onWeekChange(-7)}
                                    onNext={() => onWeekChange(7)}
                                />}
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            {working ? 
                                <Skeleton variant="rect" animation="wave" height={300} style={{marginTop: '10px'}} /> :
                                <SelectTable
                                    name={name}
                                    headerColumns={headerColumns}
                                    data={loadPlans}
                                    onCheckEvent={() => {}}
                                    onExpandEvent={handleExpand}
                                    collapseContent={renderItem}
                                    isSelected={isSelected}
                                    isExpanded={isExpanded}
                                    allowSelect={false}
                                    allowIndicator={true}
                                    onEdit={onLoadClick}
                                />}
                                {working || loadPlans.length ? null : <div className={classes.emptyResults}>No Load Plans found</div>}
                        </Grid>

                        {publishToolbar()}
                    </Grid>
                </div>
            </Paper>
        </div>
    )
}

export default LoadPlansList;