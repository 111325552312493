import React from 'react';
import {
    List, Datagrid, TextField, DateField, Show, SimpleShowLayout, ShowButton, Create, Edit, SimpleForm, TextInput, DateInput, required, SelectInput,
    ReferenceInput, DateTimeInput, Filter
} from 'react-admin';
import { Typography, Button, Link } from '@material-ui/core';
import ArrowRight from '@material-ui/icons/ArrowRight';

import { ViewButton, TextNullableField, ShowNullableField } from '../components';
import classes from './reactAdmin.module.css';

const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
const dateTimeOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };

const AgencyBookingFilter = (props) => (
    <div className={classes.react_admin_page_filter}>
        <Filter {...props}>
            <TextInput label="Search Booking Number" source="bookingNumber" alwaysOn />
        </Filter>
    </div>
);

export const AgencyBookingList = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>{props.options.label}</Typography>
        <List {...props} filters={<AgencyBookingFilter />} sort={{ field: 'bookingDate', order: 'DESC' }}>
            <Datagrid rowClick="show">
                <TextField source="agency.name" label="Agency" />
                <TextNullableField source="bookingNumber" label="Booking Number" />
                <TextNullableField source="bookingReference" label="Booking Reference" />
                <DateField source="bookingDate" label="Booking Date" options={dateOptions} locales="en-NZ" />
                <TextNullableField source="vehicle" label="Vehicle" />
                <TextNullableField source="serviceOperator" label="Service Operator" />
                <TextNullableField source="serviceNumber" label="Service Number" />
                <TextNullableField source="loadPlan.displayId" label="LoadPlan" />
                <TextField source="origin.title" label="Origin Branch" />
                <TextField source="destination.title" label="Destination Branch" />
                <DateField source="departureCutOffDateTime" label="Departure CutOff" showTime options={dateTimeOptions} locales="en-NZ" />
                <DateField source="arrivalScheduledDateTime" label="Scheduled Arrival" showTime options={dateTimeOptions} locales="en-NZ" />
                <ViewButton />
            </Datagrid>
        </List>
    </div>
);

const LoadPlanLinkButton = ({ record = {}, source }) => {
    if (record[source] && record[source]['id']) {
        return (
            <Link color="inherit" underline="none" href={`/#/loadplan/${record[source]['id']}`} >
                <Button variant="contained" color="primary" endIcon={<ArrowRight />}>
                    View Load Plan
                </Button>
            </Link>
        );
    } else {
        return null;
    }
};

export const AgencyBookingShow = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>{props.options.name}</Typography>
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="agency.name" label="Agency" />
                <ShowNullableField source="bookingNumber" label="Booking Number" />
                <ShowNullableField source="bookingReference" label="Booking Reference" />
                <DateField source="bookingDate" label="Booking Date" options={dateOptions} locales="en-NZ" />
                <ShowNullableField source="vehicle" label="Vehicle" />
                <ShowNullableField source="serviceOperator" label="Service Operator" />
                <ShowNullableField source="serviceNumber" label="Service Number" />
                <ShowNullableField source="loadPlan.displayId" label="LoadPlan" />
                <TextField source="origin.title" label="Origin Branch" />
                <TextField source="destination.title" label="Destination Branch" />
                <DateField source="departureCutOffDateTime" label="Departure CutOff" showTime options={dateTimeOptions} locales="en-NZ" />
                <DateField source="arrivalScheduledDateTime" label="Scheduled Arrival" showTime options={dateTimeOptions} locales="en-NZ" />
                <ShowNullableField source="notes" label="Notes" multiline />
                <LoadPlanLinkButton source="loadPlan" />
            </SimpleShowLayout>
        </Show>
    </div>
);

const formatDate = (value) => {
    value = value.toString();
    if (value.length === 1) {
        return '0' + value;
    } else {
        return value
    }
};

const today = new Date();
const bookingDate = today.getFullYear() + '-' + formatDate(today.getMonth() + 1) + '-' + formatDate(today.getDate());

export const AgencyBookingCreate = (props) => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_add_header}>Create {props.options.name}</Typography>
        <Create {...props}>
            <SimpleForm>
                <ReferenceInput label="Agency" source="agency.id" reference="agencies">
                    <SelectInput optionText="name" validate={required()} />
                </ReferenceInput>
                <TextInput source="bookingNumber" label="Booking Number" validate={required()} />
                <TextInput source="bookingReference" label="Booking Reference" validate={required()} />
                <DateInput source="bookingDate" label="Booking Date" validate={required()} defaultValue={bookingDate} />
                <TextInput source="vehicle" label="Vehicle" validate={required()} />
                <TextInput source="serviceOperator" label="Service Operator" validate={required()} />
                <TextInput source="serviceNumber" label="Service Number" validate={required()} />
                <ReferenceInput label="Origin" source="origin.id" reference="branches">
                    <SelectInput optionText="title" validate={required()} />
                </ReferenceInput>
                <ReferenceInput label="Destination" source="destination.id" reference="branches">
                    <SelectInput optionText="title" validate={required()} />
                </ReferenceInput>
                <DateTimeInput source="departureCutOffDateTime" label="Departure CutOff" validate={required()} />
                <DateTimeInput source="arrivalScheduledDateTime" label="Scheduled Arrival" validate={required()} />
                <TextInput source="notes" label="Notes" multiline />
            </SimpleForm>
        </Create>
    </div>
);

export const AgencyBookingEdit = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>Edit {props.options.name}</Typography>
        <Edit {...props}>
            <SimpleForm>
                <ReferenceInput label="Agency" source="agency.id" reference="agencies">
                    <SelectInput optionText="name" validate={required()} />
                </ReferenceInput>
                <TextInput source="bookingNumber" label="Booking Number" />
                <TextInput source="bookingReference" label="Booking Reference" />
                <DateInput source="bookingDate" label="Booking Date" validate={required()} />
                <TextInput source="vehicle" label="Vehicle" />
                <TextInput source="serviceOperator" label="Service Operator" />
                <TextInput source="serviceNumber" label="Service Number" />
                <ReferenceInput label="Origin" source="origin.id" reference="branches">
                    <SelectInput optionText="title" validate={required()} />
                </ReferenceInput>
                <ReferenceInput label="Destination" source="destination.id" reference="branches">
                    <SelectInput optionText="title" validate={required()} />
                </ReferenceInput>
                <DateTimeInput source="departureCutOffDateTime" label="Departure CutOff" />
                <DateTimeInput source="arrivalScheduledDateTime" label="Scheduled Arrival" />
                <TextInput source="notes" label="Notes" multiline />
            </SimpleForm>
        </Edit>
    </div>
);