import React, { Fragment, Component } from 'react';
import { Formik, Field, Form } from 'formik';
import { MaterialTextField } from '../../components/MaterialUIWithFormik';
import { Chip, Link } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { Spinner } from '../../components';
import * as Yup from 'yup';
import {
  Button, IconButton, TextField, Container, Card, InputLabel, FormControl, Typography, ListItemIcon, Grid
} from '@material-ui/core';
import classes from './setPassword.module.css';
import authProvider from '../../providers/authProvider';

const passwordValidationSchema = () => {
  return Yup.object().shape({
    password: Yup.string()
      .min(6, 'Password has to be longer than 6 characters!')
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Password confirmation is required!'),
  });
};

class SetPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formValue: {
        password: '',
        confirmPassword: '',
      },
      updateSuccess: false
    };
  }

  handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    const token = this.props.match.params.token;
    console.log('form submit:', token, values)
    const pass = {
      current: null,
      password: values.password
    }
    authProvider.setPassword(token, pass)
      .then(response => {
        if (response.success) {
          this.setState({
            updateSuccess: true
          })
        }
      })
    actions.setSubmitting(false);
  }

  render() {

    return (
      <div className={classes.form_frame}>
        <h3>Welcome To Crown</h3>
        {!this.state.updateSuccess && <p>Please set your password</p>}
        {this.state.updateSuccess ? <div className={classes.form_title_content}><Chip
          label="Your password has been set up"
          color="primary"
          deleteIcon={<DoneIcon />}
          className={classes.success_message_chip}
        />
          <Link href="/" style={{ color: '#0090E9', paddingTop: '15px' }}>Log in</Link>
        </div>
          :
          <Formik
            initialValues={this.state.formValue}
            // enableReinitialize={false}
            validationSchema={passwordValidationSchema}
            onSubmit={this.handleSubmit}
            render={({ isSubmitting, errors, touched }) => (
              <Form>
                <div className={classes.form_content}>
                  <Grid item xs={12} sm={12}>
                    <Grid item xs={12} sm={12}>
                      <Field
                        label="Password"
                        component={MaterialTextField}
                        name="password"
                        type="password"
                        helperText={errors.password}
                        error={errors.password && touched.password}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Field
                        label="Confirm Password"
                        component={MaterialTextField}
                        name="confirmPassword"
                        type="password"
                        helperText={errors.confirmPassword}
                        error={errors.confirmPassword && touched.confirmPassword}
                      />
                    </Grid>
                  </Grid>
                  <div className={classes.from_actions}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      {isSubmitting ? <Spinner /> : null} Submit
                  </Button>
                  </div>
                </div>
              </Form>
            )}
          />}
      </div>
    );
  }
}
export default SetPassword;
