import * as Yup from "yup";

const twoDecimalPlace = RegExp(/^\s*-?\d+(\.\d{1,2})?\s*$/);

const requestValidationSchema = () => {
    return Yup.object().shape({
        interactId: Yup.string()
            .required("Required")
            .test(
                'validInteractId',
                'Invalid Interact ID',
                function (value) {
                    const { interactId } = this.parent;
                    if (interactId) {
                        const items = interactId.split('-');
                        if (items.length === 3) {
                            if (items[0].length === 4 && items[1].length === 5 && items[2].length === 2) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }
            ),
        customerPersonName: Yup.string()
            .required("Required")
            .max(200, "Name cannot exceed 200 characters"),
        customerTypeId: Yup.string()
            .required("Required"),
        customerOrganisation: Yup.string()
            .max(200, "Corporation name cannot exceed 200 characters"),
        estimatedLoadVolume: Yup.number()
            .required("Required")
            .moreThan(0, "Estimated volume must be greater than 0")
            .test(
                'twoDecimalPlace',
                'Estimated volume cannot have more than 2 decimal places',
                function (value) {
                const { estimatedLoadVolume } = this.parent;
                if (estimatedLoadVolume) {
                    return twoDecimalPlace.test(estimatedLoadVolume.toString());
                } else {
                    return false;
                }
              })
              .max(99999999, "Estimated volume cannot be greater than 99,999,999"),
        freightAllowance: Yup.number()
            .required("Required")
            .min(0, "Freight allowance must be either greater than 0")
            .max(99999999, "Freight allowance cannot be greater than 99,999,999"),
        requestedUpliftDate: Yup.date()
            .required("Required"),
        requestedDeliveryDate: Yup.date()
            .min(Yup.ref('requestedUpliftDate'), "The Delivery Date must come after the Uplift Date"),
        requestTimeframeId: Yup.string().test(
            'deliveryDateAndTimeframe',
            'Either delivery date or timeframe is required',
            function (value) {
            const { requestedDeliveryDate } = this.parent;
            const { requestTimeframeId } = this.parent;
            if (!requestedDeliveryDate && !requestTimeframeId) return value != null
            return true
            }),
        linehaulTypeId: Yup.string()
            .required('Required'),
        originBranchId: Yup.string()
            .required('Required'),
        destinationBranchId: Yup.string()
            .required('Required')
            .notOneOf([Yup.ref('originBranchId'), null], 'Request cannot have same origin branch and destination branch'),
        upliftDateConstraints: Yup.string()
            .max(1024, "Notes cannot exceed 1024 characters"),
        assignedUserId: Yup.string()
            .required('Required'),
        addresses: Yup.array()
            .of(Yup.object().shape({
                addressLine_1: Yup.string()
                    .max(400, "Address cannot exceed 400 characters"),
                addressLine_2: Yup.string()
                    .max(400, "Address cannot exceed 400 characters"),
                city: Yup.string()
                    .required('Required')
                    .max(200, "City cannot exceed 200 characters"),
                addressUseId: Yup.string()
                    .required('Required')
            }))
            .required('Must have an address')
    });
};

export default requestValidationSchema;