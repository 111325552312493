//@flow
import axios from 'axios';

const ApiHostUrl = process.env.REACT_APP_API_HOST;

export default class VersionStore {
    async getApi() {
        return new Promise((resolve, reject) => {
            axios.get('/service/version',  { baseURL: ApiHostUrl, headers: {}, withCredentials: true }).then(response => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        })
    }
}
