
import React, { useState, useEffect } from 'react';
import {
    Snackbar, Container, Grid
} from '@material-ui/core';
import { useDataProvider, Loading } from 'react-admin';
import { useHistory } from "react-router-dom";
import { RequestSummaryHeader, Spinner, FormStepper } from '../../components';

import MuiAlert from '@material-ui/lab/Alert';
import SelectLoadPlans from './selectLoadPlans';
import AddJourneys from './addJourneys';
import SelectVehicles from './selectVehicles';


const DEFAULT_ERROR_MESSAGE = 'Get request failed. Please try again';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const LoadPlan = (props) => {
    const dataProvider = useDataProvider();
    const history = useHistory();
    const [request, setRequest] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [activeStep, setActiveStep] = React.useState(0);
    const [loading, setLoading] = useState(true);

    const getStepComponent = (step) => {
        switch (step) {
            case 0:
                return <SelectLoadPlans request={request} onNext={nextStep} />;
            case 1:
                return <AddJourneys request={request} onNext={nextStep} onBack={previousStep} />;
            case 2:
                return <SelectVehicles request={request} onBack={previousStep} />;
            default:
                return 'unknown step';
        }
    };

    const nextStep = () => {
        const nextStep = activeStep + 1;
        setActiveStep(nextStep);
        // Update router parameter
        const id = props.match.params.id;
        history.push(`/loadplan/${id}/${nextStep}`);
    };

    const previousStep = () => {
        const previousStep = activeStep - 1;
        setActiveStep(previousStep);
        // Update router parameter
        const id = props.match.params.id;
        history.push(`/loadplan/${id}/${previousStep}`);
    };

    useEffect(() => {
        const id = props.match.params.id;
        dataProvider.getOne('requests', { id: id })
            .then(response => {
                setRequest(response.data);
            }).catch(() => {
                setErrorMessage('ERROR');
            }).finally(() =>{
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        const pageIndex = props.match.params.pageIndex;
        setActiveStep(parseInt(pageIndex));
    }, [props.match.params.pageIndex]);

    const handleSnackbarClose = () => {
        setErrorMessage('');
    };

    const setUpdateStatusErrorMessage = (error) => {
        setErrorMessage({ errorMessage: error || DEFAULT_ERROR_MESSAGE });
    };

    if (loading) return <Loading />;

    if (!request) {
        return (
            <Container maxWidth="lg" className="container">
                <Snackbar open={errorMessage} autoHideDuration={5000} onClose={handleSnackbarClose}>
                    <Alert severity="error">
                        {errorMessage}
                    </Alert>
                </Snackbar>
                <Spinner />
            </Container>
        )
    }

    return (
        <Container maxWidth="lg" className="container">
            <Snackbar open={errorMessage} autoHideDuration={5000} onClose={handleSnackbarClose}>
                <Alert severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <RequestSummaryHeader request={request} setErrorMessage={setUpdateStatusErrorMessage} />
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormStepper activeStep={activeStep} />
                    {getStepComponent(activeStep)}
                </Grid>
            </Grid>
        </Container>
    )
};

export default LoadPlan;