import React, { Component } from 'react';
import './introBar.css';

class IntroBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }

  render() {
    return (
      <div className='introbar flexbox'>
        <h1>{this.props.title}</h1>
        <div className="flexgrow"></div>
        <div className="introbar-children">
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default IntroBar;