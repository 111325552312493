import React, { Fragment, useState, useRef, useEffect } from 'react';
import { withStyles, Button, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ConfirmDialog, Spinner } from '../../components';
import { RequestStore } from '../../stores';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useIsMountedRef } from '../../hooks'

import classes from './requestSummaryHeaderMenu.module.css';

const DEFAULT_ERROR_MESSAGE = 'Update request status failed. Please try again';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        width: '200px'
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export default function RequestSummaryHeaderMenu(props) {
    const [currentStatus, setCurrentStatus] = React.useState(null);
    const [selectedStatus, setSelectedStatus] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [updating, setUpdating] = React.useState(true);
    const [availableStatuses, setAvailableStatuses] = React.useState([]);
    const isMountedRef = useIsMountedRef();
    const requestStore = new RequestStore();

    useEffect(() => {
        setUpdating(true);
        if (props.requestId !== '') {
            getAvailableStatusesByRequestId(props.requestId);
        }
    }, []);

    useEffect(() => {
        if (availableStatuses && availableStatuses.length > 0) {
            const currentStatus = availableStatuses.find(status => status.current);
            setCurrentStatus(currentStatus);
        }
        else {
            setCurrentStatus(null);
        }
    }, [availableStatuses]);

    const getAvailableStatusesByRequestId = (id) => {
        requestStore.getAvailableStatusesByRequestIds([id])
            .then(response => {
                if (isMountedRef.current) {
                    setAvailableStatuses(response);
                    setUpdating(false);
                }
            }).catch(error => {
                console.log(error);
            });
    };

    const showStatusDropdown = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const updateStatus = async (status) => {
        setUpdating(true);
        // send request
        if (props.statusCode) {
            // Update multiple requests status
            if (props.requestIds.length > 0) {
                const promises = props.requestIds.map(async id => {
                    const data = {
                        id: id,
                        newStatusId: status.id
                    };

                    return requestStore.editRequestStatus(id, data)
                    .then(response => {
                        return {
                            success: true,
                            result: response
                        };
                    }).catch(error => {
                        return {
                            success: false,
                            result: error.message || DEFAULT_ERROR_MESSAGE
                        };
                    });
                });

                const receivedData = await Promise.all(promises);
                const error = receivedData.find(item => item.success === false);
                if (error) {
                    // set error msg
                    props.setErrorMessage(`${error.result || DEFAULT_ERROR_MESSAGE}`);
                    setUpdating(false);
                } else {
                    // update parent requests
                    if (props.onStatusChange) {
                        props.onStatusChange();
                    }
                    setUpdating(false);
                }
            }
        } else {
            // Update single request status
            const data = {
                id: props.requestId,
                newStatusId: status.id
            };
            requestStore.editRequestStatus(props.requestId, data)
                .then(response => {
                    // update current status
                    setAvailableStatuses(response.result);
                    setUpdating(false);
                    if (props.onStatusChange) {
                        props.onStatusChange();
                    }
                }).catch(error => {
                    // set error msg
                    props.setErrorMessage(`${error.message || DEFAULT_ERROR_MESSAGE}`);
                    setUpdating(false);
                });
        }
    };

    const onMenuClick = (event, status) => {
        event.preventDefault();
        if (status.confirm) {
            // show confirm dialog
            setOpenDialog(true);
            // set temporal status first, if confirm on the dialog, then change current status
            setSelectedStatus(status)
        } else if (status.id !== currentStatus.id) {
            // send udpate request
            updateStatus(status);
            onMenuClose();
        }
    };

    const onMenuClose = () => {
        setAnchorEl(null);
    };

    const onDialogClose = () => {
        setOpenDialog(false);
        // No change for menu
    };

    const onDialogCancel = () => {
        setOpenDialog(false);
        // No changes for menu
    };

    const onDialogConfirm = () => {
        setOpenDialog(false);
        // send update request
        updateStatus(selectedStatus);
        onMenuClose();
    };

    return (
        <div>
            <ConfirmDialog
                open={openDialog}
                handleClose={onDialogClose}
                handleCancel={onDialogCancel}
                handleConfirm={onDialogConfirm}
                dialogTitle={'Confirm Status Change'}
                dialogMessage={`Change from ${currentStatus?.title} to ${selectedStatus?.title}?`}
            />
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                onClick={showStatusDropdown}
                style={{ width: '200px' }}
                disabled={props.disabled}
            >
                {updating ?
                    <Spinner color="inherit" size={24} /> :
                    <Fragment>
                        {currentStatus?.title || ''} <ArrowDropDownIcon fontSize="small" />
                    </Fragment>
                }
            </Button>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={onMenuClose}
            >
                {availableStatuses.map(status => {
                    return (
                        <StyledMenuItem selected={currentStatus && status.id === currentStatus.id} key={status.id} className={classes.statusMenuItem} onClick={event => onMenuClick(event, status)} >
                            <ListItemText primary={status.title} />
                        </StyledMenuItem>
                    )
                })}
            </StyledMenu>
        </div>
    );
}
