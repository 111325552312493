import React from 'react';
import { Button } from '@material-ui/core';

import classes from './viewButton.module.css';

export default function ViewButton(props) {

    return (
        <Button color="secondary" variant="contained">View</Button>
    )
}