import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Authenticated } from 'react-admin';
import { Container, Grid} from '@material-ui/core';
import './home.css';
import { VersionStore } from '../../stores';
import packageJson from '../../../package.json';

const environment = process.env.REACT_APP_ENV;

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      version: 'Loading version details...'
    };
    this.version = new VersionStore();
  }

  componentWillMount() {
    this.getVersions();
  }

  getVersions() {
    this.version.getApi().then((api) => {
        this.setState({ version: `Web App v${packageJson.version} - Api Service v${api.version}` });
    });
  }

  render() {
    return (
      <Authenticated location={this.props.location}>
        <div>

          <Container maxWidth="lg" className="container">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <h1>Welcome</h1>
                {/* <Card>Welcome</Card> */}
              </Grid>
            </Grid>
          </Container>

          <Container maxWidth="lg" className="container">
            <p style={{textAlign: 'left', fontSize: '11px', color: 'grey'}}>{this.state.version}</p>
          </Container>

        </div>
      </Authenticated>
    );
  }
}

export default withRouter(HomePage);