import React from 'react';
import {
    Edit,
    SimpleForm,
    SelectInput,
    ReferenceInput,
    List,
    NumberField,
    Show,
    SimpleShowLayout,
    Datagrid,
    ReferenceField,
    TextField,
    TextInput,
    required,
    Create
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { TextNullableField, ShowNullableField } from '../../components';
import classes from '../reactAdmin.module.css';

export const LinehaulTypeList = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>{props.options.label}</Typography>
        <List {...props}>
            <Datagrid rowClick="show">
              <TextField source="title" />
              <TextNullableField source="code" />
              <TextNullableField source="description" />
              <NumberField source="displayOrder" />                
              <ReferenceField label="Transport mode" source="transportMode.id" reference="transport_modes">
                <TextField source="description" />
              </ReferenceField>
            </Datagrid>
        </List>
    </div>
);

export const LinehaulTypeShow = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>{props.options.name}</Typography>
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="title" />
                <ShowNullableField source="code" label="Code" />
                <ShowNullableField source="description" label="Description" />
                <NumberField source="displayOrder" />                
                <ReferenceField label="Transport mode" source="transportMode.id" reference="transport_modes">
                    <TextField source="description" />
                </ReferenceField>
                
            </SimpleShowLayout>
        </Show>
    </div>
);

export const LinehaulTypeEdit = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>Edit {props.options.name}</Typography>
        <Edit {...props}>
            <SimpleForm>
              <TextInput source="title" />
              <TextInput source="code" />
              <TextInput source="description" />
              <TextInput source="displayOrder" type="number" validate={required()} />
              <ReferenceInput label="Transport mode" source="transportMode.id" reference="transport_modes">
                <SelectInput optionText="title" />
              </ReferenceInput>
            </SimpleForm>
        </Edit>
    </div>
);

export const LinehaulTypeCreate = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_add_header}>Create {props.options.name}</Typography>
        <Create {...props}>
            <SimpleForm>
                <TextInput source="title" />
                <TextInput source="code" />
                <TextInput source="description" />
                <TextInput source="displayOrder" type="number" validate={required()} />
                <ReferenceInput label="Transport mode" source="transportMode.id" reference="transport_modes">
                    <SelectInput optionText="title" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    </div>
);