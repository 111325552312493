import React, { Component } from 'react';
import {
    Button, Icon, Container, CardMedia, CardContent, Chip, Breadcrumbs, Card, CardHeader, Avatar,
    CardActions, CardActionArea, Typography, Collapse, IconButton
  } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { ContainerCard } from '../'
import './cardList.css';

const useStyles = makeStyles((theme) => ({
    root: {
      // maxWidth: 345,
    },
  }));

export const CardList = (props) => {
    const classes = useStyles();
    return (
        props.data.map(value => {
            return(
                <ContainerCard data={value} />
            )
        })
    )
}
