import React from 'react';

import { FormatReactAdminTextField } from '../../utilities';
import './textNullableField.css';

const TextNullableField = ({ record = {}, source, label }) => {
  return (
    <span>{FormatReactAdminTextField.formatValue(record, source)}</span>
  );
};

export default TextNullableField;