// in src/App.js
import React from 'react';
import { Admin, Resource, fetchUtils, ListGuesser, EditGuesser } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import {
    AddressList, AddresShow, AddressEdit, 
    AgencyList, AgencyShow, AgencyCreate, AgencyEdit,
    BranchList, BranchShow, BranchEdit, BranchCreate,
    ContainerList, ContainerShow, ContainerEdit, ContainerCreate,
    DictionaryList, DictionaryShow, DictionaryEdit, DictionaryCreate,
    LinehaulTypeShow, LinehaulTypeList, LinehaulTypeEdit, LinehaulTypeCreate,
    ContainerTypeShow, ContainerTypeList, ContainerTypeEdit, ContainerTypeCreate,
    ServiceList,
    VehicleList, VehicleShow, VehicleEdit, VehicleCreate,
    // LoadPlanList, LoadPlanShow,
    AgencyBookingList, AgencyBookingShow, AgencyBookingCreate, AgencyBookingEdit
} from './crud';
import appRoutes from './appRoutes';
import { HomePage, Login } from './pages';
import { CrownLayout, Spinner } from './components';

import { UserList, UserShow, UserEdit, UserCreate } from './components/Users'

import authProvider from './providers/authProvider';

require('dotenv').config();

const ApiHostUrl = process.env.REACT_APP_API_HOST;

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('userToken'); 
    options.headers.set('Authorization', `Bearer ${token}`);
    // add your own headers here
    options.headers.set('X-Requested-With', 'foobar');
    options.mode = 'cors';
    options.credentials = 'include';
    return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(ApiHostUrl, httpClient);

const App = (props) => {
    const isNonAdmin = !authProvider.highLevelUser();
    const loading = !dataProvider || Object.keys(dataProvider).length === 0 || !appRoutes || appRoutes.length === 0 || !authProvider || Object.keys(authProvider).length === 0;

    const resources = [
            <Resource name="service" list={ServiceList} options={{ label: 'Admin Home' }} />,
            <Resource 
                name="addresses" 
                options={{ label: 'Addresses', name: 'Address' }} 
                list={isNonAdmin ? null : AddressList} 
                show={isNonAdmin ? null : AddresShow} 
                edit={isNonAdmin ? null : AddressEdit} 
            />,

            <Resource 
                name="address_types" 
                options={{ label: 'Address Types', name: 'Address Type' }} 
                list={isNonAdmin ? null : DictionaryList} 
                show={isNonAdmin ? null : DictionaryShow}
                edit={isNonAdmin ? null : DictionaryEdit} 
                create={isNonAdmin ? null : DictionaryCreate} 
            />,

            <Resource 
                name="address_uses" 
                options={{ label: 'Address Uses', name: 'Address Use' }} 
                list={isNonAdmin ? null : DictionaryList} 
                show={isNonAdmin ? null : DictionaryShow}
                edit={isNonAdmin ? null : DictionaryEdit} 
                create={isNonAdmin ? null : DictionaryCreate} 
            />,

            <Resource 
                name="agencies" 
                options={{ label: 'Agencies', name: 'Agency' }} 
                list={isNonAdmin ? null : AgencyList} 
                show={isNonAdmin ? null : AgencyShow} 
                edit={isNonAdmin ? null : AgencyEdit} 
                create={isNonAdmin ? null : AgencyCreate} 
            />,

            <Resource 
                name="branches" options={{ label: 'Branches', name: 'Branch' }} 
                list={isNonAdmin ? null : BranchList} 
                show={isNonAdmin ? null : BranchShow} 
                edit={isNonAdmin ? null : BranchEdit} 
                create={isNonAdmin ? null : BranchCreate} 
            />,

            <Resource 
                name="branch_types" 
                options={{ label: 'Branch Types', name: 'Branch Type' }} 
                list={isNonAdmin ? null : DictionaryList} 
                show={isNonAdmin ? null : DictionaryShow}
                edit={isNonAdmin ? null : DictionaryEdit} 
                create={isNonAdmin ? null : DictionaryCreate} 
            />,

            <Resource 
                name="container_types" 
                options={{ label: 'Container Types', name: 'Container Type' }} 
                list={isNonAdmin ? null : ContainerTypeList} 
                show={isNonAdmin ? null : ContainerTypeShow} 
                edit={isNonAdmin ? null : ContainerTypeEdit} 
                create={isNonAdmin ? null : ContainerTypeCreate} 
            />,

            <Resource 
                name="customer_types" 
                options={{ label: 'Customer Types', name: 'Customer Type' }} 
                list={isNonAdmin ? null : DictionaryList} 
                show={isNonAdmin ? null : DictionaryShow}
                edit={isNonAdmin ? null : DictionaryEdit} 
                create={isNonAdmin ? null : DictionaryCreate} 
            />,

            <Resource 
                name="linehaul_types" 
                options={{ label: 'Linehaul Types', name: 'Linehaul Type' }} 
                list={isNonAdmin ? null : LinehaulTypeList} 
                show={isNonAdmin ? null : LinehaulTypeShow} 
                edit={isNonAdmin ? null : LinehaulTypeEdit} 
                create={isNonAdmin ? null : LinehaulTypeCreate} 
            />,

    
            <Resource 
                name="request_statuses" 
                options={{ label: 'Request Statuses', name: 'Request Status' }} 
                list={isNonAdmin ? null : DictionaryList} 
                show={isNonAdmin ? null : DictionaryShow}
                edit={isNonAdmin ? null : DictionaryEdit} 
                create={isNonAdmin ? null : DictionaryCreate} 
            />,

            <Resource 
                name="transport_modes" 
                options={{ label: 'Transport Modes', name: 'Transport Mode' }} 
                list={isNonAdmin ? null : DictionaryList} 
                show={isNonAdmin ? null : DictionaryShow}
                edit={isNonAdmin ? null : DictionaryEdit} 
                create={isNonAdmin ? null : DictionaryCreate} 
            />,

            <Resource 
                name="users" 
                options={{ label: 'Users', name: 'User' }} 
                list={isNonAdmin ? null : UserList} 
                show={isNonAdmin ? null : UserShow} 
                edit={isNonAdmin ? null : UserEdit} 
                create={isNonAdmin ? null : UserCreate} 
            />,

            <Resource 
                name="user_groups"
                options={{ label: 'User Groups', name: 'User Group' }} 
                list={isNonAdmin ? null : DictionaryList} 
                show={isNonAdmin ? null : DictionaryShow}
                edit={isNonAdmin ? null : DictionaryEdit} 
                create={isNonAdmin ? null : DictionaryCreate} 
            />,

            <Resource 
                name="vehicle_types" 
                options={{ label: 'Vehicle Types', name: 'Vehicle Type' }} 
                list={isNonAdmin ? null : DictionaryList} 
                show={isNonAdmin ? null : DictionaryShow}
                edit={isNonAdmin ? null : DictionaryEdit} 
                create={isNonAdmin ? null : DictionaryCreate} 
            />,

            <Resource 
                name="vehicles" 
                options={{ label: 'Vehicles', name: 'Vehicle' }} 
                list={isNonAdmin ? null : VehicleList} 
                show={isNonAdmin ? null : VehicleShow} 
                edit={isNonAdmin ? null : VehicleEdit} 
                create={isNonAdmin ? null : VehicleCreate} 
            />,

            <Resource 
                name="agency_bookings" 
                options={{ label: 'Agency Bookings', name: 'Agency Booking' }} 
                list={AgencyBookingList} 
                show={AgencyBookingShow} 
                edit={AgencyBookingEdit} 
            />,
        ];


    return (
        loading || !authProvider.checkAuth() ? <div style={{paddingTop: '50vh'}}><Spinner /></div> : 
        <Admin loginPage={Login} layout={CrownLayout} dashboard={HomePage} theme={props.theme} authProvider={authProvider} customRoutes={appRoutes} dataProvider={dataProvider}>
            {resources}    
        </Admin>
    )
};

export default App;
