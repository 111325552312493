import React, { useEffect, useState } from 'react';
import { useDataProvider, Loading, Error } from 'react-admin';
import { Formik } from 'formik';
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import {
    Button,
    Grid,
    MenuItem,
    Paper,
    Typography,
    FormControl,
    FormHelperText,
    InputLabel,
    Snackbar,
    Select,
    Link
} from '@material-ui/core';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import SaveIcon from '@material-ui/icons/Save';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MuiAlert from '@material-ui/lab/Alert';

import { LoadPlanForm, Spinner, AgencyBooking, RequestTable } from '../../../components';
import { RequestStore, UserStore } from '../../../stores';
import Skeleton from '@material-ui/lab/Skeleton';
import { FormatDate, ModCodes, constructKeyValuePair } from '../../../utilities';
import authProvider from '../../../providers/authProvider';
import classes from './loadPlanView.module.css';

// Server expects these codes to deal with address updates

const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const DEFAULT_ERROR_MESSAGE = 'Request failed. Please try again';

const LoadPlanView = (props) => {
    const history = useHistory();
    const dataProvider = useDataProvider();

    const [loading] = useState(false);
    const [working, setWorking] = useState(false);
    const [error] = useState();
    const [] = useState(null);
    const [] = useState(null);
    const [] = useState([]);
    const [selectedLoadPlans] = useState([]);
    const [loadPlanLoading, setLoadPlanLoading] = useState(true);
    const [currentSortCol] = useState('interactId');
    const [currentSortDirection] = useState('asc');
    const [currentPage] = useState(1);
    const [currentPerPage] = useState(25);
    const [, setBranches] = useState([]);
    const [agencies] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [, setVehiclesLoading] = useState(true);
    const [containerTypes, setContainerTypes] = useState([]);
    const [containerTypesLoading, setContainerTypesLoading] = useState(true);
    const [drivers, setDrivers] = useState([]);
    const [, setDriversLoading] = useState(true);
    const [, setErrorMessage] = useState(null);
    const [responseMessage, setResponseMessage] = useState({
        error: false,
        message: ''
    });

    const [sorting, setSorting] = useState({ field: 'requestedUpliftDate', order: 'asc' });
    const [paging, setPaging] = useState({ page: 1, perPage: 25 });
    const[pagination, setPagination] = useState(false)

    const [loadPlan, setLoadPlan] = useState({
        id: '',
        modCode: ModCodes.NEW,
        departureDate: '',
        displayId: '',
        containerTypeId: '',
        containerNumber: '',
        containerCapacity: '',
        volumeAvailable: 0,
        origin: '',
        originId: '',
        destination: '',
        destinationId: '',
        notes: ''
    });
    const [loadRequests, setLoadRequests] = useState([]);
    const [journeyLegs, setJourneyLegs] = useState([]);

    const loadPlanValidationSchema = () => {
        return Yup.object().shape({
            containerTypeId: Yup.string()
                .required("Required"),
            departureDate: Yup.date()
                .required("Required"),
            sealNumber: Yup.number().integer('Must be an integer').nullable(),  
        });
    };

    const requestStore = new RequestStore();
    const userStore = new UserStore();

    useEffect(() => {
        getLoadPlan();
        getBranches();
        getVehicles();
        getDrivers();
        getContainerTypes();
    }, []);

    if (loading) return <Loading />;
    if (error) return <Error />;

    const getLoadPlan = () => {
        const id = props.match.params.id;
        requestStore.getLoadPlan(id)
            .then(response => {
                const lp = response;
                const loadPlan = {
                    id: lp.id,
                    modCode: (lp.id) ? ModCodes.MODIFIED : ModCodes.NEW,
                    departureDate: FormatDate.datePickerDate(lp.departureDate),
                    displayId: lp.displayId || '',
                    containerTypeId: lp.containerType?.id || '',
                    containerNumber: lp.containerNumber || '',
                    containerCapacity: lp.containerType.volume,
                    volumeAvailable: lp.volumeAvailable,
                    origin: lp.origin?.title || '',
                    originId: lp.origin?.id || '',
                    destination: lp.destination?.title || '',
                    destinationId: lp.destination?.id || '',
                    sealNumber: lp.sealNumber || '',
                    stowedBy: lp.stowedBy || '',
                    notes: lp.notes
                };
                setLoadPlan(loadPlan);
                setLoadRequests(lp.loadRequests);
                setJourneyLegs(lp.journeyLegs.map(l => {
                    return { ...l, error: '' };
                }));
                setLoadPlanLoading(false);
            })
            .catch(error => {
                console.log(error)
                setLoadPlanLoading(false);
                setResponseMessage({
                    error: true,
                    message: error.message || 'Cannot get load plan, please try again later'
                });
            });
    };

    const validateLoadPlanVolume = (loadRequests, containerTypeId) => {
        let totalVolume = 0;
        loadRequests.forEach(item => totalVolume += Number(item.volume || 0));
        const containerType = containerTypes.find(item => item.id === containerTypeId);
        const containerTypeVolume = containerType.volume;
        if (totalVolume > containerTypeVolume) {
            return { success: false,  totalVolume: totalVolume, containerTypeVolume: containerTypeVolume};
        } else {
            return { success: true };
        }
    };

    const handleSubmit = async (values) => {
        setWorking(true);
        const validateResult = validateLoadPlanVolume(loadRequests, values.containerTypeId);
        if (validateResult.success) {
            const loadPlanData = {
                containerNumber: values.containerNumber || '',
                containerType: {
                    id: values.containerTypeId
                },
                origin: {
                    id: values.originId
                },
                destination: {
                    id: values.destinationId
                },
                departureDate: values.departureDate,
                sealNumber: typeof values.sealNumber === 'number' ? values.sealNumber : null,
                stowedBy: (values.stowedBy.trim().length > 0 ? values.stowedBy.trim() : null),
                loadRequests
            };
    
            dataProvider.update('load_plans', { id: values.id, data: loadPlanData })
                .then(() => {
                    setWorking(false);
                    setResponseMessage({
                        error: false,
                        message: 'Load Plan saved successfully'
                    });
                })
                .catch(error => {
                    console.log(error);
                    setResponseMessage({
                        error: true,
                        message: error.message || 'Create request failed, please try again later'
                    });
                    setWorking(false);
                });
        } else {
            setResponseMessage({
                error: true,
                message: `Cannot edit contaner type. Total request volume is ${validateResult.totalVolume}m3. This is greater than the chosen container type capacity of ${validateResult.containerTypeVolume}m3.`
            });
            setWorking(false);
        }
    };

    const onRowClick = (id) => {
        history.push(`/requests/view/${id}`);
    };

    const onSortChange = (sortCol, sortDirection) => {
        const up = () => {
            return sortDirection === 'asc' ? -1 : 1;
        };
        const down = () => {
            return sortDirection === 'desc' ? -1 : 1;
        };
        const tempLoadRequests = [...loadRequests];
        tempLoadRequests.sort((a, b) => {
            switch (sortCol) {
                case 'interactId':
                case 'requestedUpliftDate':
                case 'requestedDeliveryDate':
                    return a.request[sortCol] < b.request[sortCol] ? up() : down();
                case 'originBranch.title':
                    return a.request.originBranch.title < b.request.originBranch.title ? up() : down();
                case 'destinationBranch.title':
                    return a.request.destinationBranch.title < b.request.destinationBranch.title ? up() : down();
                default:
                    return a.request.interactId < b.request.interactId ? up() : down();
            }
        });
        setLoadRequests(tempLoadRequests);
    };

    const selectHasError = () => {
        return false;
    };

    const getBranches = () => {
        //Branches drop down
        dataProvider.getList('branches', {
            pagination: { page: 1, perPage: false },
            filter: { isPickup: 'true' },
            sort: { field: 'title', order: 'ASC' }
        }).then(response => {
            const branches = constructKeyValuePair(response.data, 'id', 'title');
            setBranches(branches);
        }).catch(error => {
            console.log(error);
            setResponseMessage({
                error: true,
                message: error.message || 'Cannot get branches, please try again later'
            });
        });
    };

    const formatVehiclesList = (data) => {
        const result = [];
        data.map(item => {
            result.push({
                key: item.id,
                value: `${item.title} #${item.fleetNumber} ${item.vehicleType.title}`
            });
        });
        return result;
    };

    const getContainerTypes = () => {
        //container types for dropdown
        dataProvider.getList('container_types', {
            pagination: { page: 1, perPage: false },
            sort: { field: 'title', order: 'ASC' }
        }).then(response => {
            setContainerTypes(response.data);
            setContainerTypesLoading(false);
        }).catch(error => {
            console.log(error);
            setResponseMessage({
                error: true,
                message: error.message || 'Cannot get container types, please try again later'
            });
        });
    };

    const getVehicles = () => {
        // Vehicles drop down
        dataProvider.getList('vehicles', {
            pagination: { page: 1, perPage: false },
            sort: { field: 'title', order: 'ASC' }
        }).then(response => {
            setVehicles(formatVehiclesList(response.data));
            setVehiclesLoading(false);
        }).catch(error => {
            console.log(error);
            setVehiclesLoading(false);
            setResponseMessage({
                error: true,
                message: error.message || 'Cannot get vehicles, please try again later'
            });
        });
    };

    const formatDriversList = (data) => {
        const result = [];
        data.map(item => {
            result.push({
                key: item.id,
                value: `${item.firstName} ${item.lastName}` 
            });
        });
        return result;
    };

    const getDrivers = () => {
        // Drivers drop down
        return userStore.getDrivers()
        .then(response => {
            setDrivers(formatDriversList(response));
            setDriversLoading(false);
        })
        .catch(error => {
            console.log(error);
            setDriversLoading(false);
            setResponseMessage({
                error: true,
                message: error.message || 'Cannot get drivers, please try again later'
            });
        });
    };

    const getBranchLabel = (item) => {
        return item.origin.title;
    };

    const handleSelectChange = (journeyLegs, value, key) => {
        // Load plan without request can only have one journey leg
        const journeyLeg = journeyLegs[0];
        journeyLeg[key] = { id: value };
        journeyLeg.loadPlan = { id: loadPlan.id };
        dataProvider.update('journey_legs', { id: journeyLeg.id, data: journeyLeg })
        .then(() => {
            setWorking(false);
            setResponseMessage({
                error: false,
                message: 'Load Plan saved successfully'
            });
        })
        .catch(error => {
            console.log(error);
            setResponseMessage({
                error: true,
                message: error.message || 'Create request failed, please try again later'
            });
            setWorking(false);
        });
    };

    const buildLegs = (journeyLegs) => {
        const ret = [...journeyLegs].filter(l => l.updateStatus !== ModCodes.DELETED);
        if (journeyLegs.length) {
            const fakeLastLeg = JSON.parse(JSON.stringify(journeyLegs[journeyLegs.length - 1]));
            fakeLastLeg.origin = fakeLastLeg.destination;
            ret.push(fakeLastLeg); // double up initial for destination
        }
        return ret;
    };

    const handleSnackbarClose = () => {
        setResponseMessage({
            error: false,
            message: ''
        });
    };

    return (
        <div className={classes.pageRoot}>
            <Snackbar open={responseMessage.message != ''} autoHideDuration={5000} onClose={handleSnackbarClose}>
                <Alert severity={responseMessage.error ? 'error' : 'success'}>
                    {responseMessage.message}
                </Alert>
            </Snackbar>

            <Grid container className={classes.page_title_frame}>
                <Typography variant="h5" style={{ textTransform: 'capitalize' }}>
                    Edit Load Plan
                </Typography>
            </Grid>

            {loadPlanLoading ?
                <Skeleton variant="rect" animation="wave" height={400} style={{ marginTop: '0px' }} />
                :
                <Paper className={classes.contentWrap}>
                    <Formik
                        initialValues={loadPlan}
                        onSubmit={handleSubmit}
                        validationSchema={loadPlanValidationSchema}
                    >
                        {(props) => {
                            const {
                                handleSubmit,
                            } = props;
                            return (
                                <form noValidate
                                    autoComplete="off"
                                    style={{ width: '100%' }}
                                    onSubmit={handleSubmit}
                                >
                                    <div className={classes.content}>
                                        <Grid container spacing={2} direction="column">
                                            <Grid item xs={12} />

                                            <LoadPlanForm {...props} containerTypes={containerTypes} containerTypesLoading={containerTypesLoading} />

                                            <div className={classes.contentActions}>
                                                <Button
                                                    id="backBtn"
                                                    type="submit"
                                                    color="primary"
                                                    onClick={() => history.goBack()}
                                                >
                                                    Back
                                                </Button>

                                                <Button
                                                    id="submitBtn"
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={working ? <Spinner color="inherit" size={24} /> : <SaveIcon />}
                                                    disabled={authProvider.readOnly()}
                                                    style={{ marginLeft: '20px' }}
                                                >
                                                    Save
                                                </Button>
                                            </div>

                                            <Grid item xs={12} />

                                            <Grid item xs={12}>
                                                <Typography variant="h6" gutterBottom>
                                                    Requests
                                            </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <RequestTable
                                                    defaultSort={currentSortCol}
                                                    defaultSortDir={currentSortDirection}
                                                    defaultPage={currentPage}
                                                    defaultPerPage={currentPerPage}
                                                    rows={loadRequests.map(r => r.request)}
                                                    onRowClick={onRowClick}
                                                    onSortChange={onSortChange}
                                                    loading={loading}
                                                    includePagination={pagination}
                                                    sorting={sorting}
                                                    paging={paging}
                                                />
                                            </Grid>

                                            <Grid item xs={12} style={{ marginTop: '24px' }}>
                                                <Typography variant="h6" gutterBottom>
                                                    Journey Info
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                {buildLegs(journeyLegs).map((item, journeyLegsIndex) => {
                                                    const lastIndex = buildLegs(journeyLegs).length - 1;
                                                    return (
                                                        <Grid key={journeyLegsIndex} item container xs={12} sm={12}>
                                                            <Grid item xs={4} sm={4}>
                                                                <Typography variant="h6" gutterBottom style={{ paddingTop: '15px' }}>
                                                                    {getBranchLabel(item)}
                                                                </Typography>
                                                            </Grid>

                                                            {journeyLegsIndex !== lastIndex ? <Grid item container xs={12} sm={12} style={{ paddingTop: '20px' }}>
                                                                <Grid item xs={2} sm={1} className={classes.form_arrow_down_icon_frame}>
                                                                    <ArrowDownwardIcon />
                                                                </Grid>

                                                                <Grid item container xs={10} sm={11} spacing={2}>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <FormControl variant="filled" style={{ width: '100%' }} error={selectHasError(journeyLegsIndex)}>
                                                                            <InputLabel id="branch-select-label">
                                                                                Vehicle
                                                                            </InputLabel>
                                                                            <Select
                                                                                labelId="branch-select-label"
                                                                                id="branch-select"
                                                                                value={journeyLegs[journeyLegsIndex].vehicle.id}
                                                                                onChange={(event) => handleSelectChange(journeyLegs, event.target.value, 'vehicle')}
                                                                                onBlur={() => () => { }}
                                                                                disabled={loadRequests.length > 0}
                                                                            >
                                                                                {vehicles.map(vehicle => {
                                                                                    return <MenuItem key={vehicle.key} value={vehicle.key}>{vehicle.value}</MenuItem>
                                                                                })}
                                                                            </Select>
                                                                            <FormHelperText>{journeyLegs[journeyLegsIndex].vehicle.error}</FormHelperText>
                                                                        </FormControl>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={6}>
                                                                        <FormControl variant="filled" style={{ width: '100%' }}>
                                                                            <InputLabel id="branch-select-label">
                                                                                Driver
                                                                            </InputLabel>
                                                                            <Select
                                                                                labelId="branch-select-label"
                                                                                id="branch-select"
                                                                                value={journeyLegs[journeyLegsIndex].driver.id}
                                                                                onChange={(event) => handleSelectChange(journeyLegs, event.target.value, 'driver')}
                                                                                onBlur={() => () => { }}
                                                                                disabled={loadRequests.length > 0}
                                                                            >
                                                                                {drivers.map(driver => {
                                                                                    return <MenuItem key={driver.key} value={driver.key}>{driver.value}</MenuItem>
                                                                                })}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={12}>
                                                                        {item.agencyBooking?.id && <Link color="inherit" underline="none" href={`/#/agency_bookings/${item.agencyBooking.id}/show`} >
                                                                            <Button variant="contained" color="primary" endIcon={<ArrowRight />}>
                                                                                View Agency Booking
                                                                            </Button>
                                                                        </Link>}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                                :
                                                                <Grid item xs={12} sm={12} style={{ paddingBottom: '20px' }} />}
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>

                                            {loadPlan.notes && <Grid item xs={12} style={{ marginTop: '24px' }}>
                                                <Typography variant="h6" gutterBottom>
                                                    Notes
                                                </Typography>
                                                <p>{loadPlan.notes}</p>
                                            </Grid>}
                                        </Grid>
                                    </div>
                                </form>);
                        }}
                    </Formik>
                </Paper>}
        </div>
    )
};

export default LoadPlanView;