
import dayjs from 'dayjs';

export default {
    displayDate: (date) => {
        return dayjs(date).format('DD MMM YYYY');
    },

    displayDateTime: date => {
        return dayjs(date).format('DD MMM YYYY h:mm a');
    },

    datePickerDate: (date) => {
        return dayjs(date).format('YYYY-MM-DD');
    },

    intlDate: new Intl.DateTimeFormat('en-NZ', { year: 'numeric', month: 'short', day: 'numeric' }),

    materialDateTimeFormat: (date) => {
        if (date) {
            return date.slice(0, 16);
        } else {
            return null
        }
    },

    getMonday: (val) => {
        const d = val ? new Date(val) : new Date();
        const day = d.getDay();
        const diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        const result = new Date(d.setDate(diff));
        return `${result.getFullYear()}-${result.getMonth() + 1}-${result.getDate()}`;
    }
}