import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Formik, Field, Form } from 'formik';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import TextField from "@material-ui/core/TextField";
import { useDataProvider, Loading, Error } from 'react-admin';
import { useDropzone, Dropzone } from 'react-dropzone'
import {
    Button, IconButton, Paper, Container, InputLabel, Typography, Grid, CircularProgress,
    FormControl, FormHelperText, Select, Tab, Tabs, Box, Menu, MenuItem
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { KeyboardDateTimePicker } from "@material-ui/pickers";

import { MaterialTextField, MaterialSelect } from '../../components/MaterialUIWithFormik';
import { Spinner } from '../../components';
import classes from './agencyBooking.module.css';
import { FormatDate } from '../../utilities';
import { UploadStore } from '../../stores';

function a11yProps(index) {
    return {
        id: `request-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
};

const agencyBookingValidationSchema = (cutOffDate, arrivalDate) => {
    return Yup.object().shape({
        agency: Yup.string()
            .required('Required'),
        bookingNumber: Yup.string()
            .max(200, 'Booking number cannot exceed 200 characters'),
        bookingReference: Yup.string()
            .required('Required')
            .max(200, 'Booking reference cannot exceed 200 characters'),
        serviceOperator: Yup.string()
            .max(200, 'Service operator cannot exceed 200 characters'),
        serviceNumber: Yup.string()
            .max(200, 'Service number cannot exceed 200 characters'),
        notes: Yup.string()
            .max(1024, "Notes cannot exceed 1024 characters"),
        arrivalScheduledDateTime: Yup.mixed().test(
            'departureCutOffDateTime',
            'The Scheduled Arrival Date must come after the Departure CutOff Date',
            function (value) {
            if (cutOffDate && arrivalDate) { 
                if (typeof cutOffDate === 'string') {
                    cutOffDate = new Date(cutOffDate);
                }
                if (typeof arrivalDate === 'string') {
                    arrivalDate = new Date(arrivalDate);
                }
                if (cutOffDate > arrivalDate) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        }),
    });
};

const formatISODateString = (date) => {
    // Ignore the timezone offset
    if (date) {
        const newDate = new Date(date);
        return new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000).toISOString();
    } else {
        return null;
    }
};

const AgencyBooking = (props) => {
    const dataProvider = useDataProvider();

    const [updating, setUpdating] = useState(false);
    const [showingForm, setShowingForm] = useState(false);
    const [editMode, setEditMode] = useState(props.journeyLeg.agencyBooking?.id ? false : true);
    const [formValue, setFormValue] = useState({
        id: props.journeyLeg.agencyBooking?.id || '',
        agency: props.journeyLeg.agencyBooking?.agency?.id || '',
        bookingDate: props.journeyLeg.agencyBooking?.bookingDate || new Date(),
        bookingNumber: props.journeyLeg.agencyBooking?.bookingNumber || '',
        bookingReference: props.journeyLeg.agencyBooking?.bookingReference || '',
        serviceOperator: props.journeyLeg.agencyBooking?.serviceOperator || '',
        serviceNumber: props.journeyLeg.agencyBooking?.serviceNumber || '',
        departureCutOffDateTime: FormatDate.materialDateTimeFormat(formatISODateString(props.journeyLeg.agencyBooking?.departureCutOffDateTime)) || null,
        arrivalScheduledDateTime: FormatDate.materialDateTimeFormat(formatISODateString(props.journeyLeg.agencyBooking?.arrivalScheduledDateTime)) || null,
        notes: props.journeyLeg.agencyBooking?.notes || '',
        loadPlan: props.loadPlan,
        journeyLeg: { ...props.journeyLeg, loadPlan: props.loadPlan },
        origin: props.journeyLeg.origin,
        destination: props.journeyLeg.destination,
        vehicle: props.journeyLeg.agencyBooking?.vehicle || '',
        file: props.journeyLeg.agencyBooking?.file || ''
    });
    const [file, setFile] = useState(null);
    const [deletedFile, setDeletedFile] = useState(null);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [selectedAgencyBooking, setSelectedAgencyBooking] = useState(props.currentValue || '');
    const [cutOffDate, setCutOffDate] = useState(FormatDate.materialDateTimeFormat(formatISODateString(props.journeyLeg.agencyBooking?.departureCutOffDateTime)) || null);
    const [arrivalDate, setArrivalDate] = useState(FormatDate.materialDateTimeFormat(formatISODateString(props.journeyLeg.agencyBooking?.arrivalScheduledDateTime)) || null);
    const uploadStore = new UploadStore();

    useEffect(() => {
        // undefined value is making DatePicker act as 1970-01-01
        setFormValue({
            ...formValue,
            departureCutOffDateTime: FormatDate.materialDateTimeFormat(formValue.departureCutOffDateTime) || cutOffDate,
            arrivalScheduledDateTime: FormatDate.materialDateTimeFormat(formValue.arrivalScheduledDateTime) || arrivalDate
        });
        setUpdating(false);
    }, [editMode]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*,.pdf',
        onDrop: acceptedFiles => {
            setFile(acceptedFiles[0]);
            // Current file has been replaced
            setDeletedFile(formValue.file);
        }
    });

    const removeFile = () => {
        setFile(null);
    };

    const deleteFile = () => {
        setDeletedFile(formValue.file);
        setFormValue({ ...formValue, file: '' });
    };

    const updateFormValues = (data) => {
        setFormValue({
            id: data.id,
            agency: data.agency.id,
            bookingDate: data.bookingDate,
            bookingNumber: data.bookingNumber || '',
            bookingReference: data.bookingReference || '',
            serviceOperator: data.serviceOperator || '',
            serviceNumber: data.serviceNumber || '',
            departureCutOffDateTime: FormatDate.materialDateTimeFormat(formatISODateString(data.departureCutOffDateTime)) || (cutOffDate ? dayjs(new Date(cutOffDate)).format('YYYY-MM-DDTHH:mm') : null),
            arrivalScheduledDateTime: FormatDate.materialDateTimeFormat(formatISODateString(data.arrivalScheduledDateTime)) || (arrivalDate ? dayjs(new Date(arrivalDate)).format('YYYY-MM-DDTHH:mm') : null),
            notes: data.notes,
            loadPlan: data.loadPlan,
            journeyLeg: { ...props.journeyLeg, loadPlan: data.loadPlan }, // response from sever does not have journey, so get it from parent
            origin: data.origin,
            destination: data.destination,
            vehicle: data.vehicle || '',
            file: data.file
        });
        // Init file after updating
        setFile(null);
    };

    const submitForm = (values) => {
        if (values.id) {
            dataProvider.update('agency_bookings', { id: values.id, data: values })
                .then(({ data }) => {
                    updateFormValues(data);
                    setUpdating(false);
                })
                .catch(error => {
                    console.log(error);
                    props.setChildErrorMessage(error.message);
                    setUpdating(false);
                });
        } else {
            delete values.id;
            dataProvider.create('agency_bookings', { data: values })
                .then(({ data }) => {
                    updateFormValues(data);
                    setEditMode(false);
                    setUpdating(false);
                })
                .catch(error => {
                    console.log(error);
                    props.setChildErrorMessage(error.message);
                    setUpdating(false);
                });
        }
    };

    const updateFile = (values) => {
        if (file) {
            // upload file then get file path
            uploadStore.uploadAgencyBookingFile(file)
                .then(data => {
                    if (data.success) {
                        // add file path to agency form value 
                        values.file = data.result.key;
                        submitForm(values);
                    } else {
                        props.setChildErrorMessage(data.message || 'File upload failed, please try again later');
                        // upload failed, remove file
                        setFile(null);
                        // update other form fields
                        const newData = {...values, file: '', agency: values.agency.id, destination: { id: values.destination.id} , origin: { id: values.origin.id}};
                        setFormValue(newData);
                    }
                    setUpdating(false);
                })
                .catch(error => {
                    props.setChildErrorMessage(error.message || 'File upload failed, please try again later');
                     // upload failed, remove file
                    setFile(null);
                    // update other form fields
                    const newData = {...values, file: '', agency: values.agency.id, destination: { id: values.destination.id} , origin: { id: values.origin.id}};
                    setFormValue(newData);
                    setUpdating(false);
                });
        } else {
            submitForm(values);
        }
    };

    const handleSubmit = (values, actions) => {
        setUpdating(true);
        actions.setSubmitting(true);
        // update date
        values.departureCutOffDateTime = cutOffDate;
        values.arrivalScheduledDateTime = arrivalDate;
        // values.agency is an ID instead of an object
        values.agency = { id: values.agency };

        if (deletedFile) {
            // delete file from s3
            uploadStore.deleteAgencyBookingFile(values.id)
                .then(data => {
                    if (data.success) {
                        // update agency booking file field after s3 delete file
                        updateFile(values);
                    } else {
                        props.setChildErrorMessage(data.message);
                    }
                    setUpdating(false);
                })
                .catch(error => {
                    props.setChildErrorMessage(error.message);
                    setUpdating(false);
                });
        } else {
            updateFile(values);
        }

        actions.setSubmitting(false);
    };

    const getDropdownFieldName = (id, field) => {
        if (id) {
            const result = field.find(item => item.key === id);
            return result.value;
        } else {
            return '';
        }
    };

    const handleCancelForm = () => {
        setShowingForm(false);
        if (formValue.id) {
            // cancel will reset the edit mode
            setEditMode(false);
        }
    };

    const formatFileName = (file) => {
        // remove the file path from file name
        if (file.includes('/')) {
            const nameArray = file.split('/');
            return nameArray[1] || nameArray[0];
        } else {
            return file;
        }
    };

    const toggleEditMode = () => {
        setUpdating(true);
        setEditMode(!editMode);
    };

    const handleTabIndexChange = (event, newIndex) => {
        setCurrentTabIndex(newIndex);
    };

    const submitSelectChange = () => {
        setUpdating(true);
        // update agency booking with new load plan id then save it
        const agencyBookingData = props.allAgencyBookings.find(item => item.id === selectedAgencyBooking);
        if (agencyBookingData) {
            agencyBookingData.loadPlan = props.loadPlan;
            agencyBookingData.journeyLeg = { ...props.journeyLeg, loadPlan: props.loadPlan };
            dataProvider.update('agency_bookings', { id: selectedAgencyBooking, data: agencyBookingData })
                .then(({ data }) => {
                    updateFormValues(data);
                    setCurrentTabIndex(0);
                    // update date value in edit mode
                    setCutOffDate(FormatDate.materialDateTimeFormat(formatISODateString(data.departureCutOffDateTime)) || null);
                    setArrivalDate(FormatDate.materialDateTimeFormat(formatISODateString(data.arrivalScheduledDateTime)) || null);
                    setEditMode(false);
                    setUpdating(false);
                })
                .catch(error => {
                    console.log(error);
                    props.setChildErrorMessage(error.message);
                    setUpdating(false);
                });
        }
    };

    return (
        <div className={classes.form_frame}>
            {!showingForm && <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ margin: '10px 0 15px 0' }}
                onClick={() => { setShowingForm(true) }}
                endIcon={<ArrowDropDownIcon />}
            >
                {formValue.id ? 'View' : 'Add'} Agency Booking
            </Button>}

            {showingForm && <Paper variant="outlined" square className={classes.agency_booking_box}>
                {updating ? <Grid item xs={12} sm={12}>
                        <Spinner />
                    </Grid>
                    :
                    <div>
                        <Tabs
                            value={currentTabIndex}
                            onChange={handleTabIndexChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            style={{ width: '100%' }}
                        >
                            <Tab label={!formValue.id ? "Add a new agency booking" : "Edit agency booking"} {...a11yProps(0)} />
                            {editMode && !formValue.id && <Tab label="Select from existing agency booings" {...a11yProps(1)} />}
                        </Tabs>

                        <TabPanel value={currentTabIndex} index={0}>
                            <Grid container xs={12} sm={12} className={classes.agency_booking_box_title_frame}>
                                <p className={classes.form_title}>Agency Booking</p>
                                {formValue.id && <IconButton
                                    onClick={toggleEditMode}>
                                    <EditIcon />
                                </IconButton >}
                            </Grid>

                            <Formik
                                initialValues={formValue}
                                enableReinitialize={true}
                                validationSchema={agencyBookingValidationSchema(cutOffDate, arrivalDate)}
                                onSubmit={handleSubmit}
                                render={({ isSubmitting, errors, touched, values, handleBlur, handleChange, setFieldValue, setFieldTouched }) => (
                                    <Form>
                                        <div className={classes.form_content}>
                                            <Grid container xs={12} sm={12}>
                                                <Grid item xs={12} sm={6} className={classes.form_field}>
                                                    {editMode ? <FormControl className={classes.form_select_field} variant="filled" error={errors.agency && touched.agency} required>
                                                        <InputLabel id="agency">Agency</InputLabel>
                                                        <Select
                                                            id="agency"
                                                            value={values.agency}
                                                            onChange={handleChange('agency')}
                                                            onBlur={handleBlur('agency')}>
                                                            {props.agencies.map(item => {
                                                                return <MenuItem
                                                                    value={item.key}
                                                                    key={item.key}
                                                                >{item.value}</MenuItem>
                                                            })}
                                                        </Select>
                                                        <FormHelperText>{errors.agency}</FormHelperText>
                                                    </FormControl>
                                                        :
                                                        <Field
                                                            label="Agency"
                                                            component={MaterialTextField}
                                                            value={getDropdownFieldName(formValue.agency, props.agencies)}
                                                            type="text"
                                                            mode={editMode ? "edit" : 'view'}
                                                            helperText={errors.password}
                                                        />}
                                                </Grid>

                                                <Grid item xs={12} sm={6} className={classes.form_field}>
                                                    <Field
                                                        label="Vehicle"
                                                        component={MaterialTextField}
                                                        name="vehicle"
                                                        type="text"
                                                        mode={editMode ? "edit" : 'view'}
                                                        helperText={errors.vehicle}
                                                        error={errors.vehicle && touched.vehicle}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6} className={classes.form_field}>
                                                    <Field
                                                        label="Booking Number"
                                                        component={MaterialTextField}
                                                        name="bookingNumber"
                                                        type="text"
                                                        mode={editMode ? "edit" : 'view'}
                                                        helperText={errors.bookingNumber}
                                                        error={errors.bookingNumber && touched.bookingNumber}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6} className={classes.form_field}>
                                                    <Field
                                                        label="Booking Reference"
                                                        component={MaterialTextField}
                                                        name="bookingReference"
                                                        type="text"
                                                        mode={editMode ? "edit" : 'view'}
                                                        helperText={errors.bookingReference}
                                                        error={errors.bookingReference && touched.bookingReference}
                                                        required
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6} className={classes.form_field}>
                                                    <Field
                                                        label="Service Operator"
                                                        component={MaterialTextField}
                                                        name="serviceOperator"
                                                        type="text"
                                                        mode={editMode ? "edit" : 'view'}
                                                        helperText={errors.serviceOperator}
                                                        error={errors.serviceOperator && touched.serviceOperator}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6} className={classes.form_field}>
                                                    <Field
                                                        label="Service Number"
                                                        component={MaterialTextField}
                                                        name="serviceNumber"
                                                        type="text"
                                                        mode={editMode ? "edit" : 'view'}
                                                        helperText={errors.serviceNumber}
                                                        error={errors.serviceNumber && touched.serviceNumber}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6} className={classes.form_field}>
                                                    {editMode ? <KeyboardDateTimePicker
                                                        autoOk
                                                        className={classes.date_time_picker}
                                                        inputVariant="filled"
                                                        label="Departure CutOff"
                                                        value={cutOffDate}
                                                        onChange={setCutOffDate}
                                                        // onError={console.log}
                                                        placeholder="ex: DD/MM/YY hh:mm AM"
                                                        format="DD/MM/YY hh:mm A"
                                                        ampm={true}
                                                        invalidDateMessage="Invalid Date"
                                                        cancelLabel="Cancel"     
                                                    />
                                                    :
                                                    <Field
                                                        label="Departure CutOff"
                                                        component={MaterialTextField}
                                                        name="departureCutOffDateTime"
                                                        type="datetime-local"
                                                        mode="view"
                                                        helperText={errors.departureCutOffDateTime}
                                                        error={errors.departureCutOffDateTime && touched.departureCutOffDateTime}
                                                        inputLabelProps={{ shrink: true }}
                                                    />}
                                                </Grid>

                                                <Grid item xs={12} sm={6} className={classes.form_field}>
                                                    {editMode ? <KeyboardDateTimePicker
                                                        autoOk
                                                        className={classes.date_time_picker}
                                                        inputVariant="filled"
                                                        label="Scheduled Arrival"
                                                        value={arrivalDate}
                                                        onChange={setArrivalDate}
                                                        // onError={console.log}
                                                        placeholder="ex: DD/MM/YY hh:mm AM"
                                                        format="DD/MM/YY hh:mm A"
                                                        ampm={true}
                                                        invalidDateMessage="Invalid Date"
                                                        minDate={cutOffDate}
                                                        minDateMessage="The Scheduled Arrival Date must come after the Departure CutOff Date"
                                                        cancelLabel="Cancel"     
                                                        disabled={!cutOffDate}
                                                    />
                                                    :
                                                    <Field
                                                        label="Scheduled Arrival"
                                                        component={MaterialTextField}
                                                        name="arrivalScheduledDateTime"
                                                        type="datetime-local"
                                                        mode="view"
                                                        helperText={errors.arrivalScheduledDateTime}
                                                        error={errors.arrivalScheduledDateTime && touched.arrivalScheduledDateTime}
                                                        inputLabelProps={{ shrink: true }}
                                                    />}
                                                </Grid>

                                                <Grid item xs={12} sm={12} className={classes.form_field}>
                                                    <Field
                                                        label="Notes"
                                                        component={MaterialTextField}
                                                        name="notes"
                                                        type="text"
                                                        mode={editMode ? "edit" : 'view'}
                                                        helperText={errors.notes}
                                                        error={errors.notes && touched.notes}
                                                        multiline
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={12} className={classes.form_field}>
                                                    <section>
                                                        {editMode && <div {...getRootProps({ className: 'dropzone' })} className={classes.drop_zone_frame}>
                                                            <input {...getInputProps()} />
                                                            <p>Drag 'n' drop file here, or click to select file</p>
                                                        </div>}
                                                        {file ? <aside className={classes.drop_zone_files_frame}>
                                                            <InputLabel>File:</InputLabel>
                                                            <div>
                                                                {file.path} &nbsp;
                                                        <IconButton aria-label="delete" onClick={removeFile}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </div>
                                                        </aside>
                                                            :
                                                            <aside className={classes.drop_zone_files_frame}>
                                                                {formValue.file && <div><InputLabel>File:</InputLabel>
                                                                    {formatFileName(formValue.file)} &nbsp;
                                                        {editMode && <IconButton aria-label="delete" onClick={deleteFile}>
                                                                        <DeleteIcon />
                                                                    </IconButton>}
                                                                </div>}
                                                            </aside>
                                                        }
                                                    </section>
                                                </Grid>
                                            </Grid>

                                            <div className={classes.from_actions}>
                                                <Button
                                                    variant={editMode ? "outlined" : "contained"}
                                                    color="primary"
                                                    onClick={handleCancelForm}
                                                >
                                                    Cancel
                                            </Button>

                                                {editMode && <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={isSubmitting ? <Spinner color="#fff" size="18px" /> : <SaveIcon />}
                                                >
                                                    Save
                                            </Button>}
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            />
                        </TabPanel>

                        <TabPanel value={currentTabIndex} index={1}>
                        {props.availableAgencyBookings.length > 0 ?<div className={classes.select_form_content} >
                                <FormControl className={classes.form_select_field} variant="filled" required>
                                    <InputLabel id="booking-select-label">
                                        Agency Bookings
                                    </InputLabel>
                                    <Select
                                        IconComponent={props.agencyBookingsLoading ?
                                            props => (<CircularProgress size={20} />) :
                                            void 0}
                                        labelId="booking-select-label"
                                        id="booking-select"
                                        value={selectedAgencyBooking}
                                        onChange={(event) => setSelectedAgencyBooking(event.target.value)}
                                    >
                                        {props.availableAgencyBookings.map(item => {
                                            return <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>

                                <div className={classes.from_actions}>
                                    <Button
                                        variant={editMode ? "outlined" : "contained"}
                                        color="primary"
                                        onClick={handleCancelForm}
                                    >
                                        Cancel
                                    </Button>
                                    {editMode && <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<SaveIcon />}
                                        onClick={submitSelectChange}
                                        disabled={!selectedAgencyBooking}
                                    >
                                        Save
                                    </Button>}
                                </div>
                            </div>
                            :
                            <div className={classes.form_content}>
                                <p style={{ paddingTop: '20px' }}>These requests has no agency booking configured</p>
                            </div>}
                        </TabPanel>
                    </div>}
            </Paper>}
        </div>
    );
};

export default AgencyBooking;
