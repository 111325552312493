import React from 'react';
import {
    List, Datagrid, TextField, Show, SimpleShowLayout, Create, Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, ReferenceField
, BooleanInput, BooleanField, required } from 'react-admin';

import { AddressInput } from './addresses/addresses';
import { TextNullableField, ShowNullableField } from '../../components';
import { Validation } from '../../utilities';
import { BranchContactInput } from './branchContactInput/BranchContactInput';
import { Typography } from '@material-ui/core';
import classes from '../reactAdmin.module.css';

export const BranchList = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>{props.options.label}</Typography>
        <List {...props}>
            <Datagrid rowClick="show">
                <TextField source="title" />
                <ReferenceField label="Branch Type" source="branchType.id" reference="branch_types">
                    <TextField source="title" />
                </ReferenceField>
                <ReferenceField label="City" source="address.id" reference="addresses">
                    <TextField source="city" />
                </ReferenceField>
                <TextNullableField source="contact_1Name" />
                <TextNullableField source="contact_1Email" />
                <TextNullableField source="contact_1Phone" />
                <TextNullableField source="contact_2Name" />
                <TextNullableField source="contact_2Email" />
                <TextNullableField source="contact_2Phone" />
            </Datagrid>
        </List>
    </div>
);

export const BranchShow = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>{props.options.name}</Typography>
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="title" />
                <ReferenceField label="Branch Type" source="branchType.id" reference="branch_types">
                    <TextField source="title" />
                </ReferenceField>
                <ReferenceField label="City" source="address.id" reference="addresses">
                    <TextField source="city" />
                </ReferenceField>
                <ShowNullableField source="contact_1Name" label="Contact 1 Name" />
                <ShowNullableField source="contact_1Email" label="Contact 1 Email" />
                <ShowNullableField source="contact_1Phone" label="Contact 1 Phone" />
                <ShowNullableField source="contact_2Name" label="Contact 2 Name" />
                <ShowNullableField source="contact_2Email" label="Contact 2 Email" />
                <ShowNullableField source="contact_2Phone" label="Contact 2 Phone" />
                <BooleanField label="Pickup / Drop off" source="isPickup" />
            </SimpleShowLayout>
        </Show>
    </div>
);

const validateBranch = (values) => {
    const errors = {};

    if (values.contact_1Email && !Validation.email(values.contact_1Email)) {
        errors.contact_1Email = ['Invalid Email'];
    }

    if (values.contact_2Email && !Validation.email(values.contact_2Email)) {
        errors.contact_2Email = ['Invalid Email'];
    }

    if (values.contact_1Phone && !Validation.phoneNumber(values.contact_1Phone)) {
        errors.contact_1Phone = ['Invalid Phone number'];
    }

    if (values.contact_2Phone && !Validation.phoneNumber(values.contact_2Phone)) {
        errors.contact_2Phone = ['Invalid Phone number'];
    }

    return errors;
};

export const BranchEdit = (props) => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>Edit {props.options.name}</Typography>
        <Edit {...props}>
            <SimpleForm validate={validateBranch}>
                <TextInput source="title" validate={required()} />
                <ReferenceInput label="Branch Type" source="branchType.id" reference="branch_types">
                    <SelectInput optionText="title" />
                </ReferenceInput>
                <BranchContactInput contactNumber="1" name="contact_1Name" email="contact_1Email" phone="contact_1Phone" />
                <BranchContactInput contactNumber="2" name="contact_2Name" email="contact_2Email" phone="contact_2Phone" />
                <TextInput source="orderNS" label="North South Index" type="number" validate={required()} />
                <BooleanInput label="Pickup / Drop off" source="isPickup" />
                <AddressInput />
            </SimpleForm>
        </Edit>
    </div>
);

export const BranchCreate = (props) => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_add_header}>Create {props.options.name}</Typography>
        <Create {...props}>
            <SimpleForm validate={validateBranch}>
                <TextInput source="title" validate={required()} />
                <ReferenceInput label="Branch Type" source="branchType.id" reference="branch_types">
                    <SelectInput optionText="title" />
                </ReferenceInput>
                <BranchContactInput contactNumber="1" name="contact_1Name" email="contact_1Email" phone="contact_1Phone" />
                <BranchContactInput contactNumber="2" name="contact_2Name" email="contact_2Email" phone="contact_2Phone" />
                <TextInput source="orderNS" label="North South Index" type="number" validate={required()} />
                <BooleanInput label="Pickup / Drop off" source="isPickup" />
                <AddressInput />
            </SimpleForm>
        </Create>
    </div>
);