import React, { useEffect, useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, Loading, Error } from 'react-admin';
import { Formik, Field, FieldArray, getIn } from 'formik';
import { useHistory } from "react-router-dom";
import { useIsMountedRef } from '../../hooks';

import {
    Button,
    CircularProgress,
    MuiSelect,
    CardActions, Card, CardContent,
    Container, Grid,
    FormControl, FormHelperText,
    Input, InputAdornment, InputLabel,
    LinearProgress, MenuItem, Paper,
    Select, TextField,
    Typography
} from '@material-ui/core';

import { PageWrapper } from '../../components';
import classes from './customerDetailsForm.module.css';

const CustomerDetailsForm = (props) => {
    const history = useHistory();
    const dataProvider = useDataProvider();
    const isMountedRef = useIsMountedRef();

    const [customerTypes, setCustomerTypes] = useState([]);
    const [customerTypesLoading, setCustomerTypesLoading] = useState(true);
    const [requestFromOrganisation, setRequestFromOrganisation] = useState(true);

    useEffect(() => {
        //customer_types for dropdown
        dataProvider.getList('customer_types', {
            pagination: { page: 1, perPage: false },
            sort: { field: 'title', order: 'ASC' }
        }).then(response => {
            if (isMountedRef.current) {
                setCustomerTypes(response.data);
                setCustomerTypesLoading(false);
                disableOrganisation(response.data);
            }
        }).catch(error => {
            console.log(`Error getting customer_types`);
            console.log(error);
        });
    }, []);

    const disableOrganisation = (data) => {
        if (props.values.customerTypeId) {
            const type = data.find(value => value.id === props.values.customerTypeId);
            if (type.title !== "Corporate") {
                setRequestFromOrganisation(false);
            }
        }
    };

    const handleCustomerTypeChange = (event, values) => {
        const type = customerTypes.find(value => value.id === event.target.value);
        if (type.title === "Corporate") {
            // Enable customer org name
            setRequestFromOrganisation(true);
        } else {
            setRequestFromOrganisation(false);
            props.setFieldValue('customerOrganisation', '', true);
        }

        props.setFieldValue('customerTypeId', event.target.value, true);
        props.setFieldTouched('customerTypeId', true, false);
    };

    const {
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        setFieldValue,
        setFieldTouched
    } = props;
    return (
        <Grid item container xs={12} sm={9}>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Customer Details
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid item container xs={12} spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField id="customerPersonName"
                            error={errors.customerPersonName && touched.customerPersonName}
                            helperText={errors.customerPersonName}
                            className={classes.inputItem}
                            value={values.customerPersonName}
                            label="Person Name"
                            variant="filled"
                            helperText={errors.customerPersonName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!props.canEdit}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} />

                    <Grid item xs={12} md={6}>
                        <FormControl variant="filled" className={classes.inputItem} error={errors.customerTypeId && touched.customerTypeId} required>
                            <InputLabel id="customerTypeLabel">Type</InputLabel>
                            <Select
                                IconComponent={customerTypesLoading ?
                                    props => (<CircularProgress size={20} />) :
                                    void 0}
                                disabled={customerTypesLoading || !props.canEdit}
                                labelId="customerTypeLabel"
                                id="customerType"
                                value={values.customerTypeId}
                                onChange={event => handleCustomerTypeChange(event, values)}
                                onBlur={handleBlur('customerTypeId')}>
                                {customerTypes.map(ct => {
                                    return <MenuItem
                                        value={ct.id}
                                        key={ct.id}
                                    >{ct.title}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText>{errors.customerTypeId}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField id="customerOrganisation"
                            error={errors.customerOrganisation && touched.customerOrganisation}
                            helperText={errors.customerOrganisation}
                            className={classes.inputItem}
                            value={values.customerOrganisation}
                            label="Corporation"
                            variant="filled"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!requestFromOrganisation || !props.canEdit}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default CustomerDetailsForm;