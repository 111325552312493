import * as React from "react";
import { AppBar, UserMenu, MenuItemLink } from 'react-admin';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import authProvider from '../../../providers/authProvider';
import classes from './crownAppBar.module.css';
import logo from '../../../assets/images/crownlogodarktransp.png';


const HeaderUserMenu = (props) => (
    <UserMenu {...props}>
        <div className={classes.profile_frame}>{authProvider.userName()}</div>
    </UserMenu>
);

const CrownAppBar = (props) => {
    return <AppBar {...props} userMenu={<HeaderUserMenu />}>
        <div className={classes.headerWrapper}>
            <img src={logo} className={classes.headerLogo} alt="logo" />
        </div>
        <span className={classes.spacer} />
    </AppBar>
}

export default CrownAppBar;