import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import {
    Snackbar, Divider
} from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ModCodes } from '../../../utilities';

import { RequestStore, LoadPlanStore } from '../../../stores';
import { LoadPlanSummary, Spinner } from '../../../components';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import SaveIcon from '@material-ui/icons/Save';
import MuiAlert from '@material-ui/lab/Alert';
import {
    FormControl,
    Button,
    Grid,
    IconButton, FormHelperText,
    InputLabel,
    MenuItem, Paper,
    Select} from '@material-ui/core';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { constructKeyValuePair } from '../../../utilities';
import Skeleton from '@material-ui/lab/Skeleton';

import classes from './addJourneys.module.css';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddJourneys = (props) => {
    const { request, onNext, onBack } = props;
    const requestStore = new RequestStore();
    const loadPlanStore = new LoadPlanStore();
    const dataProvider = useDataProvider();

    const [loadPlans, setLoadPlans] = useState([]);
    const [loadPlansLoading, setLoadPlansLoading] = useState(true);
    const [expandedLoadPlans, setExpandedLoadPlans] = useState([])
    const [branches, setBranches] = useState([]);
    const [working, setWorking] = useState(false);
    const [deletedLegs, setDeletedLegs] = useState({});
    const [reponseMessage, setResponseMessage] = useState({
        severity: "",
        message: ""
    });

    useEffect(() => {
        getLoadPlansByRequest();
        getBranches();
    }, []);

    const parseLoadPlan = (lp) => {
        return {
            id: lp.id,
            departureDate: lp.departureDate,
            containerType: lp.containerType,
            containerNumber: lp.containerNumber,
            origin: lp.origin,
            destination: lp.destination,
            bookingReference: lp.bookingReference,
            journeyLegs: lp.journeyLegs.map(l => {
                return { ...l, error: ''};
            }),
            loadRequests: lp.loadRequests,
            displayId: lp.displayId,
            hasError: false
        };
    };

    const getLoadPlansByRequest = () => {
        requestStore.getByRequestId(request.id)
            .then(response => {
                const loadPlans = response.map(lp => {
                    return parseLoadPlan(lp);
                });
                setExpandedLoadPlans([ true, ...Array(loadPlans.length - 1).fill(false)]);
                setLoadPlans(loadPlans);
                setLoadPlansLoading(false);
            })
            .catch(error => console.log(error));
    };

    const getBranchLabel = (index, length) => {
        if (index === 0) return 'Departure Branch';
        if (index === length - 1) return 'Destination Branch';
        return 'Branch';
    };

    const getBranches = () => {
        //Branches drop down
        dataProvider.getList('branches', {
            pagination: { page: 1, perPage: false },
            filter: { isPickup: 'true' },
            sort: { field: 'title', order: 'ASC' }
        }).then(response => {
            const branches = constructKeyValuePair(response.data, 'id', 'title');
            setBranches(branches);
        }).catch(error => {
            console.log(error);
        });
    };

    const addDestination = (loadPlanIndex, journeyLegsIndex) => {
        const templLoadPlans = [...loadPlans];
        const plan = templLoadPlans[loadPlanIndex];
        const nextLeg = plan.journeyLegs[(journeyLegsIndex < plan.journeyLegs.length ? journeyLegsIndex : journeyLegsIndex - 1)];
        templLoadPlans[loadPlanIndex].journeyLegs.splice(journeyLegsIndex, 0, {
            origin: {
                id: ''
            },
            destination: {
                id: nextLeg.destination.id
            },
            error: ''
        });
        setLoadPlans(templLoadPlans);
    };

    const removeDestination = (loadPlanIndex, journeyLegsIndex) => {
        const templLoadPlans = [...loadPlans];
        const plan = templLoadPlans[loadPlanIndex];
        const leg = plan.journeyLegs[journeyLegsIndex];
        const prevLeg = plan.journeyLegs[journeyLegsIndex - 1];
        prevLeg.destination.id = leg.destination.id;
        if (leg.id) {
            leg.updateStatus = ModCodes.DELETED;
            const tempDeletedLegs = { ...deletedLegs };
            if (!tempDeletedLegs[`plan${loadPlanIndex}`]) {
                tempDeletedLegs[`plan${loadPlanIndex}`] = [];
            }
            tempDeletedLegs[`plan${loadPlanIndex}`].push(leg);
            setDeletedLegs(tempDeletedLegs);
        }
        plan.journeyLegs.splice(journeyLegsIndex, 1);
        setLoadPlans(templLoadPlans);
    };

    const handleBranchChange = (loadPlanIndex, journeyLegsIndex, value) => {
        const templLoadPlans = [...loadPlans];
        const errorMessage = validateSingleChoice(value);
        const plan = templLoadPlans[loadPlanIndex];
        if (plan.journeyLegs.length > journeyLegsIndex) {
            const leg = plan.journeyLegs[journeyLegsIndex];
            leg.origin.id = value;
            if (journeyLegsIndex === 0) {
                plan.origin.id = value;
                plan.origin.title = branches.find(b => b.key === value).value;
            }
            leg.error = errorMessage;
        } else {
            // final destination
            const leg = plan.journeyLegs[plan.journeyLegs.length - 1];
            leg.destination.id = value;
            plan.destination.id = value;
            plan.destination.title = branches.find(b => b.key === value).value;
            leg.error = errorMessage;
        }
        setLoadPlans(templLoadPlans);
    };

    const handleBranchBlur = (loadPlanIndex, journeyLegsIndex, value) => {
        handleBranchChange(loadPlanIndex, journeyLegsIndex, value);
    };

    const handleExpansionChange = (loadPlanIndex) => {
        const cloneExpandedloadPlans = [...expandedLoadPlans];
        cloneExpandedloadPlans[loadPlanIndex] = !expandedLoadPlans[loadPlanIndex];
        setExpandedLoadPlans(cloneExpandedloadPlans);
    };

    const loadPlanHasError = (loadPlanIndex) => {
        const loadPlan = loadPlans[loadPlanIndex];
        for (let i = 0; i < loadPlan.journeyLegs.length; i++ ) {
            if (selectHasError(loadPlanIndex, i)) {
                return true;
            }
        }
        return false;
    };

    const selectHasError = (loadPlanIndex, journeyLegsIndex) => {
        // If error Message
        if (loadPlanIndex >= loadPlans.length) {
            return false;
        }
        if (journeyLegsIndex >= loadPlans[loadPlanIndex].journeyLegs.length) {
            return false;
        }
        const loadPlan = loadPlans[loadPlanIndex].journeyLegs[journeyLegsIndex];
        return loadPlan.error ? loadPlan.error.length > 0 : false;
    };

    const validateSingleChoice = (value) => {
        if (value === '') {
            return 'Must select branch';
        }
        return '';
    };

    const buildLegs = (journeyLegs) => {
        const ret = [...journeyLegs].filter(l => l.updateStatus !== ModCodes.DELETED);
        if (journeyLegs.length) {
            const fakeLastLeg = JSON.parse(JSON.stringify(journeyLegs[journeyLegs.length - 1]));
            fakeLastLeg.origin = fakeLastLeg.destination;
            ret.push(fakeLastLeg); // double up initial for destination
        }
        return ret;
    };

    const connectLoadPlans = () => {
        const tempLoadPlans = [ ...loadPlans ];
        for (let p = 0; p < tempLoadPlans.length; p++) {
            const lp = tempLoadPlans[p];
            // remove unset branches
            lp.journeyLegs = lp.journeyLegs.filter(l => l.origin.id !== '');

            for (let i = 0; i < lp.journeyLegs.length; i++) {
                const leg = lp.journeyLegs[i];
                leg.legOrder = i + 1;
                if (!leg.updateStatus) {
                    leg.updateStatus = ModCodes.MODIFIED;
                }
                if (i < lp.journeyLegs.length - 1) {
                    leg.destination.id = lp.journeyLegs[i + 1].origin.id;
                } else {
                    leg.destination.id = lp.destination.id;
                }
            }
            // append any deletes
            if (deletedLegs[`plan${p}`]) {
                lp.journeyLegs.push(...deletedLegs[`plan${p}`]);
            }
        }
        setLoadPlans(tempLoadPlans);
    };

    const validateLoadPlans = () => {
        const errors = [];
        for (let p = 0; p < loadPlans.length; p++) {
            const lp = loadPlans[p];
            for (let i = 0; i < lp.journeyLegs.length; i++) {
                const leg = lp.journeyLegs[i];
                // check no leg has the same origin and destination
                if (leg.origin.id === leg.destination.id) {
                    errors.push(`Legs cannot have the same origin and destination`);
                }
            }
        }
        return errors;
    };

    const saveJourneyLegs = async (goToNext) => {
        setWorking(true);
        // connect first
        connectLoadPlans();

        const errors = validateLoadPlans();
        if (errors.length) {
            setWorking(false);
            setResponseMessage({
                severity: "error",
                message: errors.join(', ')
            });
            return;
        }

        const promises = loadPlans.map(async lp => {
            const step = 2;
            delete lp.hasError;
            // Format journey leg data
            lp.journeyLegs.map(jl => {
                delete jl.error;
            });
            
            return loadPlanStore.editLoadPlan(lp, step)
                .then(response => {
                    // update current status
                    const parsed = parseLoadPlan(response);
                    const temp = [...loadPlans];
                    const existing = temp.find(p => p.id === parsed.id);
                    temp.splice(temp.indexOf(existing), 1, parsed);
                    setLoadPlans(temp);
                    return {
                        success: true,
                        result: ''
                    };
                })
                .catch(error => {
                    console.log(error);
                    return {
                        success: false,
                        result: error.message || `Update container type ${lp.containerType.title} failed, please try again`
                    };
                });
        });

        const receivedData = await Promise.all(promises);
        if (receivedData.length === loadPlans.length) {
            const error = receivedData.find(item => item.success === false);
            if (error) {
                setWorking(false);
                setResponseMessage({
                    severity: "error",
                    message: error.result
                });
            } else {
                // check all journey at here
                loadPlanStore.validateJourneyLegsByRequestId(request.id)
                .then(response => {
                    // validate load plans volume with request
                    if (response.success) {
                        if (goToNext) {
                            onNext();
                        } else {
                            // stay and show success msg
                            setResponseMessage({
                                severity: "success",
                                message: `Journeys have been updated successfully`
                            });
                        }
                    } else {
                        setResponseMessage({
                            severity: "error",
                            message: response.message || "Validate load plans failed, please try again"
                        });
                    }
                    setWorking(false);
                }).catch(error => {
                    setResponseMessage({
                        severity: "error",
                        message: "Validate load plans failed, please try again"
                    });
                    setWorking(false);
                });
            }
        }
    };

    const handleSnackbarClose = () => {
        setResponseMessage({
            severity: "",
            message: ""
        });
    };

    if (loadPlansLoading || working) return <Skeleton animation="wave" />;
    
    return (
        <Grid item xs={12} sm={12}>
            <Snackbar open={reponseMessage.message} autoHideDuration={5000} onClose={handleSnackbarClose}>
                <Alert severity={reponseMessage.severity}>
                    {reponseMessage.message}
                </Alert>
            </Snackbar>
            
            {loadPlans.map((loadPlan, loadPlanIndex) => {
                return (
                    <ExpansionPanel key={loadPlan.id} expanded={expandedLoadPlans[loadPlanIndex]} onChange={() => handleExpansionChange(loadPlanIndex)}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <LoadPlanSummary loadPlan={loadPlan} error={loadPlanHasError(loadPlanIndex)}/>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid item xs={12} sm={12}>
                                {buildLegs(loadPlan.journeyLegs).map((item, journeyLegsIndex) => {
                                    const legs = buildLegs(loadPlan.journeyLegs);
                                    const lastIndex = legs.length - 1;
                                    return (
                                        <Grid key={journeyLegsIndex} item container xs={12} sm={12} style={{ paddingTop: journeyLegsIndex === 0 ? '20px' : '0' }}>
                                            <Paper className={classes.form_field_paper_frame} elevation={3}>
                                                <Grid item xs={12} sm={12}>
                                                    <FormControl variant="filled" className={classes.branchSelect} error={selectHasError(loadPlanIndex, journeyLegsIndex)}>
                                                        <InputLabel id="branch-select-label">
                                                            {getBranchLabel(journeyLegsIndex, legs.length)}
                                                        </InputLabel>
                                                        <Select
                                                            labelId="branch-select-label"
                                                            id="branch-select"
                                                            value={legs[journeyLegsIndex].origin.id}
                                                            onChange={(event) => handleBranchChange(loadPlanIndex, journeyLegsIndex, event.target.value)}
                                                            onBlur={(event) => handleBranchBlur(loadPlanIndex, journeyLegsIndex, event.target.value)}
                                                        >
                                                            {branches.map(branch => {
                                                                return <MenuItem key={branch.key} value={branch.key}>{branch.value}</MenuItem>
                                                            })}
                                                        </Select>
                                                        <FormHelperText>{legs[journeyLegsIndex].error}</FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                {journeyLegsIndex > 0 && journeyLegsIndex < lastIndex && <Grid item xs={1} sm={1} className={classes.form_field_remove_button}>
                                                    <IconButton aria-label="delete" onClick={() => removeDestination(loadPlanIndex, journeyLegsIndex)}>
                                                        <HighlightOff />
                                                    </IconButton>
                                                </Grid>}
                                            </Paper>

                                            {journeyLegsIndex < lastIndex ? <Grid item xs={6} sm={6}>
                                                <IconButton
                                                    aria-label="add"
                                                    variant="contained"
                                                    className={classes.form_field_add_button}
                                                    onClick={() => addDestination(loadPlanIndex, journeyLegsIndex + 1)}
                                                >
                                                    <AddCircleOutline />
                                                </IconButton>
                                            </Grid>
                                                :
                                                <Grid style={{ marginBottom: '20px' }} />}
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </ExpansionPanelDetails>
                        <Divider />
                        <ExpansionPanelActions>
                            <Button size="small" onClick={() => handleExpansionChange(loadPlanIndex)}>Close</Button>
                        </ExpansionPanelActions>
                    </ExpansionPanel>
                )
            })
            
        }
            <div className={classes.contentActions}>
                <Button
                    id="backBtn"
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<ArrowLeft />}
                    onClick={onBack}
                >
                    Back
                </Button>

                <Button
                    id="saveBtn"
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={() => saveJourneyLegs(false)}
                >
                    Save
                </Button>

                <Button
                    id="nextBtn"
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowRight />}
                    onClick={() => saveJourneyLegs(true)}
                >
                    Next
                </Button>
            </div>
        </Grid>
    )
}

export default AddJourneys;