import React from 'react';

import map from 'lodash/map';
import {
  FormControl, TextField, Select, Switch, Checkbox, MenuItem, InputLabel, CircularProgress
} from '@material-ui/core';

const CreateMaterialField = Component => ({
  field,
  form,
  hasFeedback,
  label,
  selectOptions,
  submitCount,
  type,
  renderRight,
  required,
  loading,
  inputLabelProps,
  mode = 'edit',
  ...props
}) => {
  const touched = form.touched[field.name];
  const submitted = submitCount > 0;
  const hasError = form.errors[field.name];
  const touchedError = hasError && touched;

  const onChange = (event) => {
      if (event.target?.value) {
        form.setFieldValue(field.name, event.target.value);
      } else {
        form.setFieldValue(field.name, event.value);
      }
  }
  const onBlur = () => form.setFieldTouched(field.name, true);

  return (
      <FormControl 
        style={{width: '100%', paddingBottom: '20px'}}
        required={required}
        // label={initLabel}
        // colon={false}
        // hasFeedback={
        //   (hasFeedback && submitted) || (hasFeedback && touched) ? true : false
        // }
        // help={submittedError || touchedError ? hasError : false}
        // validateStatus={submittedError || touchedError ? 'error' : 'success'}
      >
        { type === 'select' && <InputLabel style={{paddingLeft: '15px'}}>{label}</InputLabel> }
        <Component
          {...field}
          {...props}
          label={label}
          type={type}
          required={required}
          variant={mode === 'edit' ? "filled" : "standard"}
          InputProps={{
            readOnly: mode === 'view' ? true : false,
            disableUnderline: mode === 'view' ? true :false
          }}
          IconComponent={loading ?
            () => (<CircularProgress size={20} style={{padding: '10px'}} />) :
            void 0}
          disabled={loading}
          onBlur={onBlur}
          onChange={onChange}
          error={touchedError}
          helperText={hasError}
          InputLabelProps={inputLabelProps}
        >
          {selectOptions &&
            map(selectOptions, item => <MenuItem value={item.key} key={item.key}>{item.value}</MenuItem>)}
        </Component>
      </FormControl>
  );
};

export const MaterialSelect = CreateMaterialField(Select);
// export const MaterialDatePicker = CreateMaterialField(DatePicker);
export const MaterialTextField = CreateMaterialField(TextField);
// export const MaterialTimePicker = CreateMaterialField(TimePicker);
// export const MaterialRate = CreateMaterialField(Rate);
export const MaterialSwitch = CreateMaterialField(Switch);
// export const MaterialRange = CreateMaterialField(RangePicker);
export const MaterialCheckbox = CreateMaterialField(Checkbox);
