import React, { useState, useRef, useEffect, forwardRef } from 'react';
import Paper from '@material-ui/core/Paper';
import { ReferenceField, TextInput, ReferenceInput, SelectInput, required } from 'react-admin';
import Popover from '@material-ui/core/Popover';
import { List, ListItem, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import axios from 'axios';

import classes from './Address.module.css';

export const AddressInput = () => {

    const [addressSuggestions, setAddressSuggestions] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [addressLine1Input, setAddressLine1Input] = useState('');

    // useEffect(() => {
    //     if (selectedAddress) {
    //         const addressLines = selectedAddress.split(',');
    //         console.log(addressLines);
    //         addressLine2Input.current.value = addressLines[1].trim();
    //     }
    // }, [selectedAddress, addressLine2Input]);

    let el = null;

    const getAddress = (event) => {
        const token = 'eyJhbGciOiJIUzI1NiIsImtpZCI6IlRFU1QiLCJwaS5hdG0iOiIxIn0.eyJzY29wZSI6W10sImNsaWVudF9pZCI6IjMyNWYwMTczMjg5ZTQzNDQ5MzQ2N2MzMTA2NDFjMDA5IiwiZXhwIjoxNTkyODU5MDU2fQ.UyifUPpITVCBp392IxRXgKtIwTUZU-dUZt22S67Zd00';

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json'
            },
        };

        const input = event.target.value.trim();
        if (input.length === 0) {
            setAddressSuggestions([]);
            return;
        }

        const addressFragment = event.target.value.replace(/\s/g, "+");
        const url = `https://api.nzpost.co.nz/addresschecker/1.0/suggest?q=${addressFragment}&max=8`;
        console.log(url);
        console.log(addressFragment);
        axios.get(
            url,
            config
        ).then(response => {
            if (response.data?.success) {
                if (response.data.addresses.length > 0) {
                    setAddressSuggestions(response.data.addresses);
                }
            }
        }).catch(error => console.log(error));
    }

    const handleAddressClick = (address) => {
        setSelectedAddress(address.FullAddress);
    }

    //const handleClose = () => {
    //     setAnchorEl(null);
    //};

    const popoverOpen = Boolean(anchorEl)
    const popoverId = popoverOpen ? 'address-popover' : undefined;

    const addresses = addressSuggestions.map(address => (
        <ListItem key={address.DPID} button onClick={() => handleAddressClick(address)}>
            {address.FullAddress}
        </ListItem>));

    return (
        <div>
            <Typography variant="h5" className={classes.react_admin_page_header}>Edit Address</Typography>
            <Paper className={classes.AddressWrap}>
                <div className={classes.AddressTitle}><strong>Address</strong></div>
                {/*<div>
                <Autocomplete
                    id="address-search"
                    freesolo
                    options={addressSuggestions.map((address) => address.FullAddress)}
                    renderInput={(params) => (
                        <TextInput {...params}
                            autoComplete="off"
                            label="Address Line 1"
                            className={classes.AddressInput}
                            source="address.addressLine_1"
                            onChange={getAddress}
                            //onBlur={handleClose}
                        />
                    )}
                />
                    </div>*/}
                {/* <TextInput
                    autoComplete="off"
                    label="Address Line 1"
                    className={classes.AddressInput}
                    source="address.addressLine_1"
                    onChange={getAddress}
                    onBlur={handleClose}
                />
                <Popover
                    id={popoverId}
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    disableAutoFocus={true}
                    disableEnforceFocus={true}
                >
                    <List>
                        {addresses}
                    </List>
                </Popover> */}
                {/*</div>*/}
                <div>
                    <TextInput
                        label="Address Line 1"
                        className={classes.AddressInput}
                        source="address.addressLine_1"
                    />
                </div>
                <div>
                    <TextInput
                        label="Address Line 2"
                        className={classes.AddressInput}
                        source="address.addressLine_2"
                    />
                </div>
                <div>
                    <TextInput
                        label="City"
                        className={classes.AddressInput}
                        source="address.city"
                        validate={required()}
                    />
                </div>
                <div>
                    <TextInput
                        label="Postcode"
                        className={classes.AddressInput}
                        source="address.postcode"
                    />
                </div>
                <div>
                    <ReferenceInput label="Address Type" source="address.addressType.id" reference="address_types" className={classes.AddressInput}>
                        <SelectInput optionText="title" validate={required()} />
                    </ReferenceInput>
                </div>
            </Paper>
        </div>
    );
}