
export default {
    phoneNumber: (value) => {
        const phoneRegex = RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/);
        return phoneRegex.test(value);
    },

    email: (value) => {
        const emailRegex = RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
        return emailRegex.test(value);
    },
}