import React, { Component } from 'react';
import './titleBar.css';

class TitleBar extends Component {

  render() {
    return (
      <div className={`titlebar flexbox ${this.props.marginAbove ? "margin-top" : ""}`}>
        <h2>{this.props.title}</h2>
        <div className="flexgrow"><hr/></div>
        <div className="titlebar-children">
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default TitleBar;