import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Notifications from '@material-ui/icons/Notifications';
import CheckIcon from '@material-ui/icons/Check';
import { Table, Badge, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography,
    Paper, Checkbox, FormControlLabel, Switch, Button } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { RequestActionsMenu } from '../../components';
import authProvider from '../../providers/authProvider';
import { FormatDate } from '../../utilities';


const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

const headCells = [
    { id: 'interactId', numeric: false, disablePadding: true, label: 'Interact ID' },
    { id: 'customerOrganisation', numeric: false, disablePadding: false, label: 'Customer' },
    { id: 'estimatedLoadVolume', numeric: false, disablePadding: false, label: 'Volume' },
    { id: 'originBranch.title', numeric: false, disablePadding: false, label: 'Origin' },
    { id: 'destinationBranch.title', numeric: false, disablePadding: false, label: 'Destination' },
    { id: 'loadRequests', numeric: false, disablePadding: false, label: 'Load Plan', type: 'length-boolean' },
    { id: 'requestedUpliftDate', numeric: false, disablePadding: false, label: 'Uplift Date', type: 'date' },
    { id: 'requestedDeliveryDate', numeric: false, disablePadding: false, label: 'Delivery Date', type: 'date' },
    { id: 'requestTimeframe', numeric: false, disablePadding: false, label: 'Timeframe' },
    { id: 'agencyBookings', numeric: false, disablePadding: false, label: 'Agency Booking', type: 'length-boolean' },
    { id: 'alerts', numeric: false, disablePadding: false, label: 'Alerts' },
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, enableCheckBox } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    {enableCheckBox && <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />}
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.tableHeadCell}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell className={classes.tableHeader}>Action</TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    enableCheckBox: PropTypes.string
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    button: {
        marginRight: '16px',
        padding: '6px 24px'
    }
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, statusCode, requestIds, onStatusChange, rows } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                    <div className={classes.title} />  
                )}

                {statusCode && <RequestActionsMenu
                    disabled={numSelected <= 0 || statusCode === 'Others'}
                    statusCode={statusCode}
                    requestIds={requestIds}
                    rows={rows}
                    onStatusChange={onStatusChange} // reload all requests after status change
                    setErrorMessage={props.setErrorMessage}
                />}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    statusCode: PropTypes.string.isRequired,
    requestIds: PropTypes.arrayOf(PropTypes.string),
    rows: PropTypes.arrayOf(PropTypes.object),
    onStatusChange: PropTypes.func.isRequired,
    setErrorMessage: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableHeadCell: {
        padding: 8,
    },
    clickable_table_row: {
        cursor: 'pointer'
    }
}));

export default function RequestTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState(props.sorting.order);
    const [orderBy, setOrderBy] = React.useState(props.sorting.field);

    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(props.paging.page-1);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.paging.perPage);
    const [includePagination, setIncludePagination] = React.useState(props.includePagination != null ? props.includePagination : true)
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order.toLowerCase() === 'asc';
        const sortOrder = isAsc ? 'desc' : 'asc';
        setOrder(sortOrder);
        setOrderBy(property);
        props.onSortChange(property, sortOrder);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = props.rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };


    const handleChangePage = (event, pageIndex) => {
        setPage(pageIndex)
        props.onChangePage(pageIndex +1 ,rowsPerPage);
        
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(page);
    };

    const handleChangeDense = (event) => {
        props.onDenseChange(event.target.checked);
    };

    const onView = (event, id) => {
        props.onRowClick(id);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const emptyRows = false; // rowsPerPage - Math.min(rowsPerPage, props.rows.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            {props.loading ? <Skeleton animation="wave" variant="rect" height={200} width={'100%'} /> : <Paper className={classes.paper}>
                {props.status && <EnhancedTableToolbar 
                    numSelected={selected.length} 
                    statusCode={props.status} 
                    requestIds={selected} 
                    rows={props.rows}
                    onStatusChange={props.onStatusChange}
                    setErrorMessage={props.setErrorMessage}
                />}
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={props.dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={props.rows.length}
                            enableCheckBox={props.status}
                        />

                        <TableBody>
                            {stableSort(props.rows, getComparator(order, orderBy))
                                
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    const activeAlerts = row.requestAlerts ? row.requestAlerts.filter(ra => ra.alert.resolvedDate == null) : [];

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.id)} // click for select
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}
                                            className={classes.clickable_table_row}
                                        >
                                            <TableCell padding="checkbox">
                                                {props.status && <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />}
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                {row.interactId}
                                            </TableCell>
                                            <TableCell align="left">{row.customerOrganisation ? `${row.customerOrganisation} - ${row.customerPersonName}` : row.customerPersonName}</TableCell>
                                            <TableCell align="left">{row.estimatedLoadVolume}</TableCell>
                                            <TableCell align="left">{row.originBranch.title}</TableCell>
                                            <TableCell align="left">{row.destinationBranch.title}</TableCell>
                                            <TableCell align="left">{row.loadRequests.length > 0 ? <CheckIcon/> : null}</TableCell>
                                            <TableCell align="left">{FormatDate.intlDate.format(Date.parse(row.requestedUpliftDate))}</TableCell>
                                            <TableCell align="left">{row.requestedDeliveryDate ? FormatDate.intlDate.format(Date.parse(row.requestedDeliveryDate)) : null}</TableCell>
                                            <TableCell align="left">{row.requestTimeframe?.title ? row.requestTimeframe.title : null}</TableCell>
                                            <TableCell align="left">{row.agencyBookings.length > 0 ? <CheckIcon/> : null}</TableCell>
                                            <TableCell align="left">
                                                {activeAlerts.length ? <Badge badgeContent={activeAlerts.length} color="primary">
                                                    <Notifications />
                                                </Badge>: null}
                                            </TableCell>
                                            <TableCell>
                                                <Button onClick={ev => onView(ev, row.id) } color="secondary" variant="contained">View</Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (props.dense ? 33 : 53) * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {includePagination &&
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={props.recordCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                }
            </Paper>}
            <FormControlLabel
                control={<Switch checked={props.dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </div>
    );
}