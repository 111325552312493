import React from 'react';

import { Typography, Grid, Divider, Paper } from '@material-ui/core';


import { RequestSummaryHeaderMenu } from '../../components';
import { FormatDate } from '../../utilities';
import authProvider from '../../providers/authProvider';
import classes from './requestSummaryHeader.module.css';

export default function RequestSummaryHeader(props) {

    const request = props.request;

    return (
        <Grid container direction="column">
            <Grid container item className={classes.page_title_frame}>
                <Typography variant="h5" style={{ textTransform: 'capitalize' }}>
                    {request.interactId}: {request.customerPersonName}
                </Typography>
            </Grid>

            &nbsp;

            <Grid container item direction="row">
                <Grid container item>
                    <Paper elevation={3} className={classes.paper_important_item_frame}>Volume: {request.estimatedLoadVolume}</Paper>

                    <Paper elevation={3} className={classes.paper_item_frame}>Origin: {request.originBranch.title}</Paper>

                    <Paper elevation={3} className={classes.paper_item_frame}>Destination: {request.destinationBranch.title}</Paper>

                    <Paper elevation={3} className={classes.paper_item_frame}>Pickup: {FormatDate.displayDate(request.requestedUpliftDate.substring(0, 10))}</Paper>

                    {request.requestedDeliveryDate && <Paper elevation={3} className={classes.paper_item_frame}>Delivery: {FormatDate.displayDate(request.requestedDeliveryDate.substring(0, 10))}</Paper>}

                    {request.requestTimeframe?.title && <Paper elevation={3} className={classes.paper_item_frame}>Timeframe: {request.requestTimeframe.title}</Paper>}
                </Grid>
            </Grid>

            <Divider style={{ marginTop: '36px' }} />
        </Grid>
    )
}