import React from 'react';
import { Route } from 'react-router-dom';
import { HomePage, AboutPage, RequestPage, RequestCreate, LoadPlan, LoadPlansList, LoadPlanView, SetPassword, ForgotPassword,
    RequestEdit, RequestView, ReportsPage, AddAgencyBooking, SchedulePage } from './pages';

export default [
    <Route exact path="/" component={HomePage} />,
    <Route exact path="/home" component={HomePage} />,
    <Route exact path="/about" component={AboutPage} />,
    <Route exact path="/loadplan/:id/:pageIndex" component={LoadPlan} />,
    <Route exact path="/requests" component={RequestPage} />,
    <Route exact path="/reports" component={ReportsPage} />,
    <Route exact path="/loadplans" component={LoadPlansList} />,
    <Route exact path="/loadplan/:id" component={LoadPlanView} />,
    <Route exact path="/requests/create" component={RequestCreate} />,
    <Route exact path="/requests/edit/:id" component={RequestEdit} />,
    <Route exact path="/requests/view/:id" component={RequestView} />,
    <Route exact path="/request/:id/add/agencybooking" component={AddAgencyBooking} />,
    <Route exact path="/setpassword/:token" component={SetPassword} noLayout />,
    <Route exact path="/forgotpassword" component={ForgotPassword} noLayout />,
    <Route exact path="/schedule/:type/:id/:date" component={SchedulePage} />
];