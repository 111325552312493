import React, { useEffect, useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, Loading, Error } from 'react-admin';
import { Formik, Field, FieldArray, getIn } from 'formik';
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import MaskedInput from 'react-text-mask';
import {
    Button,
    Snackbar,
    Container, Grid,
    LinearProgress, MenuItem, Paper,
    InputAdornment, TextField,
    Typography, Divider
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import CheckIcon from '@material-ui/icons/Check';
import MoveToInbox from '@material-ui/icons/MoveToInbox';
import { RequestSummaryHeaderMenu, Spinner } from '../../../components';
import { FormatDate } from '../../../utilities';
import { RequestStore } from '../../../stores';
import authProvider from '../../../providers/authProvider';

import classes from './requestView.module.css';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const RequestView = (props) => {
    const history = useHistory();
    const dataProvider = useDataProvider();

    const [loading, setLoading] = useState(false);
    const [working, setWorking] = useState(false);
    const [error, setError] = useState();
    const [responseMessage, setResponseMessage] = useState({
        error: false,
        message: ''
    });
    const [request, setRequest] = useState({
        id: '',
        interactId: '',
        customerOrganisation: '',
        customerPersonName: '',
        customerType: '',
        estimatedLoadVolume: '',
        requestedUpliftDate: '',
        requestedDeliveryDate: '',
        requestTimeframe: '',
        originBranch: { title: '' },
        destinationBranch: { title: '' },
        upliftDateConstraints: '',
        freightAllowance: '',
        assignedUser: '',
        linehaulType: '',
        addresses: [],
        status: null,
        alerts: []
    });

    const requestStore = new RequestStore();

    useEffect(() => {
        loadRequest();
    }, []);

    const loadRequest = () => {
        setLoading(true);
        // Load Request_Details
        const id = props.match.params.id;
        dataProvider.getOne('requests', { id })
            .then(response => {
                const data = response.data;
                setRequest({
                    id: data.id,
                    interactId: data.interactId,
                    customerOrganisation: data.customerOrganisation,
                    customerPersonName: data.customerPersonName,
                    customerType: data.customerType.title,
                    estimatedLoadVolume: data.estimatedLoadVolume,
                    freightAllowance: data.freightAllowance,
                    requestedUpliftDate: data.requestedUpliftDate.substring(0, 10),
                    requestedDeliveryDate: data.requestedDeliveryDate ? data.requestedDeliveryDate.substring(0, 10) : '',
                    requestTimeframe: data.requestTimeframe.title,
                    originBranch: data.originBranch,
                    destinationBranch: data.destinationBranch,
                    upliftDateConstraints: data.upliftDateConstraints,
                    assignedUser: data.assignedUser.displayName,
                    linehaulType: data.linehaulType.title,
                    addresses: data.requestAddresses,
                    status: data.requestStatus,
                    alerts: data.requestAlerts
                });
                setLoading(false);
            }).catch(error => {
                setError(error.message || 'Get request failed. Please try again');
                setLoading(false);
            });
    };

    if (loading) return <Loading />;
    if (error) return <Error />;

    let disableLoadPlan = false;
    if (request.status?.code && ((request.status?.code.toLowerCase() === 'draft') || 
        (request.status?.code.toLowerCase() === 'submitted') || 
        (request.status?.code.toLowerCase() === 'cancelled') || 
        authProvider.readOnly())) 
    {
        disableLoadPlan = true;
    }

    let disableEdit = true;
    if (request.status?.code) {
        if (
            authProvider.highLevelUser() 
            || (
                !['cancelled', 'archived', 'completed', 'others'].includes(request.status?.code.toLowerCase())
                && (!authProvider.readOnly() || authProvider.isBranchOps(request.originBranch?.id))
            )) {
            disableEdit = false;
        }
    }

    const inputProps = {
        readOnly: true,
        disableUnderline: true
    };

    const inputLabelProps = {
        shrink: true,
    };

    const acknowledgeAlert = alertId => {
        setWorking(true);
        requestStore.acknowledgeAlert(alertId)
            .then(response => {
                if (response.success) {
                    loadRequest();
                }
                setWorking(false);
            })
            .catch(error => {
                console.log(error)
                setWorking(false);
            });
    };

    const setErrorMessage = (error) => {
        setResponseMessage({
            error: true,
            message: error || 'Updated request failed. Please try again'
        });
    };

    const handleSnackbarClose = () => {
        setResponseMessage({
            error: false,
            message: ''
        });
    };

    const buildAlertRow = requestAlert => {
        const acknowledgementNeeded = requestAlert.alert.alertType.acknowledgementNeeded;
        const resolved = requestAlert.alert.resolvedDate != null;
        const resolvedBy = requestAlert.alert.resolvedBy;
        const grid = <Grid item xs={12} sm={12} lg={acknowledgementNeeded ? 9 : 12} className={classes.content_table_row}>
            {requestAlert.alert.alertType.title}: {requestAlert.alert.message}            
        </Grid>;
        const acknowledgementGrid = <Grid item xs={12} sm={12} lg={3} className={classes.content_table_row} style={{textAlign: 'right'}}>
            {resolved ?
                <div>Resolved by {resolvedBy.firstName} {resolvedBy.lastName}, {FormatDate.displayDateTime(requestAlert.alert.resolvedDate)}</div> :
                <Button
                    id={`acknowledge${requestAlert.id}`}
                    variant="outlined"
                    color="primary"
                    onClick={() => acknowledgeAlert(requestAlert.alert.id)}
                    startIcon={working ? <Spinner color="inherit" size={24} /> : <CheckIcon />}
                >
                    Acknowledge
                </Button>
            }
        </Grid>;

        return <>
            {grid}
            {acknowledgementNeeded ? acknowledgementGrid : null}
            </>;
    };

    return (
        <div className={classes.pageRoot}>
            {responseMessage.message && <Snackbar open={responseMessage.message} autoHideDuration={5000} onClose={handleSnackbarClose}>
                <Alert severity={responseMessage.error ? 'error' : 'success'}>
                    {responseMessage.message}
                </Alert>
            </Snackbar>}

            <Grid container className={classes.page_title_frame}>
                <div className={classes.page_title_frame}>
                    <Button
                        id="editBtn"
                        color="primary"
                        variant="contained"
                        onClick={() => history.goBack()}
                        startIcon={<ArrowLeft />}
                    >
                        Back
                    </Button>

                    <Typography variant="h5" style={{ textTransform: 'capitalize', marginLeft: '15px' }}>
                        {request.interactId}: {request.customerPersonName}
                    </Typography>
                </div>

                <div className={classes.page_title_button_group_frame}>
                    <Button
                        id="loadPlanBtn"
                        variant="outlined"
                        color="primary"
                        onClick={() => history.push(`/loadplan/${props.match.params.id}/0`)}
                        startIcon={<MoveToInbox />}
                        disabled={disableLoadPlan}
                    >
                        Load Plan
                    </Button>

                    <Button
                        id="editBtn"
                        variant="contained"
                        className={classes.contained_button}
                        onClick={() => history.push(`/requests/edit/${props.match.params.id}`)}
                        startIcon={<EditIcon />}
                        disabled={disableEdit}
                    >
                        Edit
                    </Button>

                    <RequestSummaryHeaderMenu
                        disabled={authProvider.readOnly()}
                        requestId={request.id}
                        onStatusChange={() => loadRequest()}
                        setErrorMessage={setErrorMessage}
                    />
                </div>
            </Grid>

            {request.alerts.filter(ra => ra.alert.resolvedDate == null).length ?
                (<Paper className={classes.contentWrap}>
                    <div className={classes.content}>
                        <Grid container spacing={2}>
                            <Grid item xs={2} lg={2} sm={2}>
                                <Typography variant="subtitle1">
                                    Alerts
                                </Typography>
                            </Grid>
                            <Grid item xs={10} lg={10} sm={10}>
                                <Grid container spacing={2}>
                                    {request.alerts.filter(ra => ra.alert.resolvedDate == null).map(ra => {
                                        return buildAlertRow(ra);
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>) : null
            }

            <Paper className={classes.contentWrap}>
                <div className={classes.content}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <Typography variant="subtitle1">
                                Request Details
                            </Typography>
                        </Grid>

                        <Grid item xs={10}>
                            <Grid item xs={12} sm={6} lg={4} className={classes.content_table_row}>
                                <TextField id="interactId"
                                    label="Interact ID"
                                    value={request.interactId}
                                    InputProps={inputProps}
                                    InputLabelProps={inputLabelProps}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={4} className={classes.content_table_row}>
                                <TextField id="customerPersonName"
                                    value={request.customerPersonName}
                                    label="Person Name"
                                    InputProps={inputProps}
                                    InputLabelProps={inputLabelProps}
                                />
                            </Grid>

                            <Grid item container xs={12} className={classes.content_table_row}>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <TextField id="customerType"
                                        value={request.customerType}
                                        label="Customer Type"
                                        InputProps={inputProps}
                                        InputLabelProps={inputLabelProps}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} lg={4}>
                                    <TextField id="customerOrganisation"
                                        value={request.customerOrganisation}
                                        label="Corporation Name"
                                        InputProps={inputProps}
                                        InputLabelProps={inputLabelProps}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item container xs={12} className={classes.content_table_row}>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <TextField id="estimatedLoadVolume"
                                        value={`${request.estimatedLoadVolume} m³`}
                                        label="Estimated Load Volume"
                                        InputProps={inputProps}
                                        InputLabelProps={inputLabelProps}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} lg={4}>
                                    <TextField id="linehaulType"
                                        value={request.linehaulType}
                                        label="Linehaul Type"
                                        InputProps={inputProps}
                                        InputLabelProps={inputLabelProps}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item container xs={12} className={classes.content_table_row}>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <TextField id="requestedUpliftDate"
                                        value={FormatDate.displayDate(request.requestedUpliftDate)}
                                        label="Requested Uplift Date"
                                        InputProps={inputProps}
                                        InputLabelProps={inputLabelProps}
                                    />
                                </Grid>

                                {request.requestedDeliveryDate && <Grid item xs={12} sm={6} lg={4}>
                                    <TextField id="requestedDeliveryDate"
                                        value={FormatDate.displayDate(request.requestedDeliveryDate)}
                                        label="Requested Delivery Date"
                                        InputProps={inputProps}
                                        InputLabelProps={inputLabelProps}
                                    />
                                </Grid>}

                                {request.requestTimeframe && <Grid item xs={12} sm={6} lg={4}>
                                    <TextField id="requestTimeframe"
                                        value={request.requestTimeframe}
                                        label="Requested Timeframe"
                                        InputProps={inputProps}
                                        InputLabelProps={inputLabelProps}
                                    />
                                </Grid>}
                            </Grid>

                            <Grid item container xs={12} className={classes.content_table_row}>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <TextField id="originBranch"
                                        value={request.originBranch.title}
                                        label="Origin Branch"
                                        InputProps={inputProps}
                                        InputLabelProps={inputLabelProps}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} lg={4}>
                                    <TextField id="destinationBranch"
                                        value={request.destinationBranch.title}
                                        label="Destination Branch"
                                        InputProps={inputProps}
                                        InputLabelProps={inputLabelProps}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item container xs={12} className={classes.content_table_row}>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <TextField id="assignedUser"
                                        value={request.assignedUser}
                                        label="Assigned User"
                                        InputProps={inputProps}
                                        InputLabelProps={inputLabelProps}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} lg={4}>
                                    <TextField id="freightAllowance"
                                        value={request.freightAllowance}
                                        label="Freight Allowance"
                                        InputProps={{
                                            ...inputProps,
                                            disableUnderline: true,
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                        InputLabelProps={inputLabelProps}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {request.addresses.map((item, index) => (
                        <Grid key={index} container spacing={2}>
                            <Grid item xs={12}>
                                <Divider style={{ margin: '10px 0' }} />
                            </Grid>

                            <Grid item xs={2}>
                                <Typography variant="subtitle1">
                                    Address #{index + 1}
                                </Typography>
                            </Grid>

                            <Grid item xs={10}>
                                <Grid item xs={12} sm={6} lg={4} className={classes.content_table_row}>
                                    <TextField id="addressLine_1"
                                        value={item.address.addressLine_1}
                                        label="Address Line 1"
                                        InputProps={inputProps}
                                        InputLabelProps={inputLabelProps}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} lg={4} className={classes.content_table_row}>
                                    <TextField id="addressLine_2"
                                        value={item.address.addressLine_2}
                                        label="Address Line 2"
                                        InputProps={inputProps}
                                        InputLabelProps={inputLabelProps}
                                    />
                                </Grid>

                                <Grid item container xs={12} className={classes.content_table_row}>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <TextField id="city"
                                            value={item.address.city}
                                            label="City"
                                            InputProps={inputProps}
                                            InputLabelProps={inputLabelProps}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} lg={4}>
                                        <TextField id="postcode"
                                            value={item.address.postcode}
                                            label="Postcode"
                                            InputProps={inputProps}
                                            InputLabelProps={inputLabelProps}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={6} lg={4} className={classes.content_table_row}>
                                    <TextField id="addressUseId"
                                        value={item.addressUse.title}
                                        label="Address Use"
                                        InputProps={inputProps}
                                        InputLabelProps={inputLabelProps}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </div>
            </Paper>
        </div >
    )
}

export default RequestView;