import React, { Fragment, Component } from 'react';
import * as Yup from 'yup';
import { Button } from '@material-ui/core';
import { FormStepper } from '../../components';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import classes from './formTab.module.css';

class PercentageCalculator extends React.Component {
    render() {
        const { formchild, pageStack, lastPage } = this.props;
        const original = formchild ? formchild.length : 0;
        let value = 0;

        if (original !== pageStack) {
            value = Math.floor((pageStack * 100) / original);
        } else if (original === pageStack) {
            value = 100;
        } else {
            value = 0;
        }

        return (
            <Fragment>
                {lastPage ? (
                    //   <Progress percent={100} showInfo={false} /> 
                    null
                ) : (
                        // <Progress percent={value} showInfo={false} />
                        null
                    )}
            </Fragment>
        );
    }
}

class FormTab extends Component {
    static Page = ({ children }) => children;

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            values: props.initialValues,
            isSubmitting: props.isSubmitting,
            activeStep: 0
        };
    }
    
    formValidation = (values, page) => {
        let result = true;
        if (page === 1) {
            const emptyValue = values.journeyLegs.filter(item => !item);
            if (emptyValue.length > 0) {
                result = false
            }
        }

        if (page === 2) {
            const emptyValue = values.vehicles.filter(item => !item);
            if (emptyValue.length > 0) {
                result = false
            }
        }

        return result
    }

    next = (values, page) => {
        if (this.formValidation(values, page)) {
            if (this.props.preNexthandler(this.state.activeStep)) {
                this.setState(state => ({
                    page: Math.min(state.page + 1, this.props.children.length - 1),
                    values,
                    activeStep: this.state.activeStep + 1
                }));
            }
        }
    };

    previous = () =>
        this.setState(state => ({
            page: Math.max(state.page - 1, 0),
            activeStep: this.state.activeStep - 1
        }));

    validate = values => {
        const activePage = React.Children.toArray(this.props.children)[
            this.state.page
        ];
        return activePage.props.validate ? activePage.props.validate(values) : {};
    };

    handleSubmit = (values, bag) => {
        const { children, onSubmit } = this.props;
        const { page } = this.state;
        const isLastPage = page === React.Children.count(children) - 1;

        if (isLastPage) {
            return onSubmit(values, bag);
        } else {
            bag.setTouched({});
            bag.setSubmitting(false);
            this.next(values);
        }
    };

    render() {

        const { children } = this.props;
        const { page, isSubmitting } = this.state;
        const activePage = React.Children.toArray(children)[page];
        const isLastPage = page === React.Children.count(children) - 1;

        return (
            <div>
                <FormStepper activeStep={this.state.activeStep} />
                <Fragment>
                    <PercentageCalculator
                        formchild={this.props.children}
                        pageStack={page}
                        lastPage={isLastPage}
                    />
                    <div className={classes.form_tab_content}>{activePage}</div>
                    <div className={classes.from_tab_actions}>
                        {page > 0 && (
                            <Button
                                variant="contained"
                                color="primary"
                                type="button"
                                className="back-step-btn"
                                onClick={this.previous}
                                startIcon={<ArrowLeft />}
                            >
                                {/* <IoIosArrowBack /> Back */}
                                            Back
                            </Button>
                        )}

                        {!isLastPage && (
                            <Button
                                variant="contained"
                                color="primary"
                                className="next-step-btn"
                                type="submit"
                                endIcon={<ArrowRight />}
                            >
                                Next
                            </Button>
                        )}
                        {isLastPage && (
                            <Button
                                variant="contained"
                                color="primary"
                                className="submit-btn"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? '<Spinner />' : null} Submit
                            </Button>
                        )}
                    </div>
                </Fragment>
            </div>
        );
    }
}
export default FormTab;
