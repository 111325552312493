const constructKeyValuePair = (data, keyProp, valueProp) => {
    return data.map(item => { 
        return {
            key: item[keyProp],
            value: item[valueProp]
        }
    });
}

export default constructKeyValuePair;
