import Api from './api';

export default class LoadPlanStore {

    async addLoadPlan(data) {
        return new Promise(async (resolve, reject) => {
            Api.post(`/load_plans`, data).then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
        });
    }

    async consolidateRequests(data) {
        return new Promise(async (resolve, reject) => {
            Api.post(`/load_plans/multiple_requests`, data).then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
        });
    }

    async editLoadPlan(data, step = 0) {
        return new Promise(async (resolve, reject) => {
            Api.put(`/load_plans/${data.id}?step=${step}`, data).then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
        });
    }

    async validateByRequestId(id) {
        return new Promise(async (resolve, reject) => {
            Api.get(`/load_plans/validate/request/${id}`).then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
        });
    }

    async searchByDisplayId(id) {
        return new Promise(async (resolve, reject) => {
            Api.get(`/load_plans/displayid/${id}`).then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
        });
    }

    async validateJourneyLegsByRequestId(id) {
        return new Promise(async (resolve, reject) => {
            Api.get(`/load_plans/validate_journeylegs/request/${id}`).then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
        });
    }

    async getPublishStatus(weekStart) {
        return new Promise(async (resolve, reject) => {
            Api.get(`/load_plans/publish_status?date=${weekStart}`).then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
        });
    }

    async getByRequestId(id) {
        return new Promise(async (resolve, reject) => {
            Api.get(`/load_plans/request/${id}`).then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
        });
    }
}