import React, { useEffect, useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, Loading, Error } from 'react-admin';
import { Formik, Field, FieldArray, getIn } from 'formik';
import { useHistory } from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { getAddressSuggestions } from '../../stores/nzPostStore';
import { useIsMountedRef } from '../../hooks';


import {
    Button,
    CircularProgress,
    MuiSelect,
    CardActions, Card, CardContent,
    Container, Grid,
    FormControl, FormHelperText,
    Input, InputAdornment, InputLabel,
    LinearProgress, Paper,
    Select, TextField,
    Typography
} from '@material-ui/core';

import { PageWrapper } from '../../components';
import classes from './addressesDetailsForm.module.css';
import { Autocomplete } from '@material-ui/lab';

const UPLIFT_STORE_CODE = 'UPSTO';
const DELIVERY_STORE_CODE = 'DELSTO';

const AddressesDetailsForm = (props) => {
    const dataProvider = useDataProvider();
    const isMountedRef = useIsMountedRef();

    const [addressUses, setAddressUses] = useState([]);
    const [addressUsesLoading, setAddressUsesLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [addressSuggestions, setAddressSuggestions] = useState([]);

    useEffect(() => {
        // Address_Uses
        dataProvider.getList('address_uses', {
            pagination: { page: 1, perPage: false },
            sort: { field: 'display_order', order: 'ASC' }
        }).then(response => {
            if (isMountedRef.current) {
                setAddressUses(response.data);
                setAddressUsesLoading(false);
            }
        }).catch(error => {
            console.log(`Error getting address_uses`);
            console.log(error);
        });
    }, []);

    const {
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        setFieldValue,
        setFieldTouched
    } = props;

    //When text is typed in address box
    const onAddressInputChange = (event, enteredText, index) => {
        getAddressSuggestions(enteredText, (data) => {
            const addressOptions = data.addresses.map(address => address.FullAddress);
            setAddressSuggestions(addressOptions);
        });
        setFieldValue(`addresses.${index}.addressLine_1`, enteredText);
    };

    //When item is selected
    const onAddressSelectChange = (event, selectedOption, index) => {
        const addressParts = selectedOption.split(',');
        const cityPostCode = addressParts[addressParts.length - 1];
        const cityPostCodeParts = cityPostCode.split(' ');
        const postcode = cityPostCodeParts.pop();
        const city = cityPostCodeParts.join(' ').trim();

        setFieldValue(`addresses.${index}.addressLine_1`, addressParts[0].trim());
        if (addressParts.length >= 3) {
            setFieldValue(`addresses.${index}.addressLine_2`, addressParts[1].trim());
        }
        setFieldValue(`addresses.${index}.city`, city);
        setFieldValue(`addresses.${index}.postcode`, postcode);
    };

    const updateAddress = (address, index) => {
        setFieldValue(`addresses.${index}.addressLine_1`, address.addressLine_1);
        setFieldValue(`addresses.${index}.addressLine_2`, address.addressLine_2);
        setFieldValue(`addresses.${index}.city`, address.city);
        setFieldValue(`addresses.${index}.postcode`, address.postcode);
    };

    const formatAddress = (address) => {
        // React admin validation do not accept null as string
        // Only following fields are optional
        return {
            ...address,
            addressLine_1: address.addressLine_1 || '',
            addressLine_2: address.addressLine_1 || '',
            postcode: address.addressLine_1 || '',
        };
    };

    const onAddressUseChange = (event, index, values) => {
        const addressUse = addressUses.find(item => item.id === event.target.value);
        if (addressUse) {
            // check address use type
            if (addressUse.code === UPLIFT_STORE_CODE) {
                // add origin branch as address
                const address = props.branches.find(item => item.id === values.originBranchId);
                if (address) {
                    updateAddress(formatAddress(address.address), index);
                }
            } else if (addressUse.code === DELIVERY_STORE_CODE) {
                // add destination branch as address
                const address = props.branches.find(item => item.id === values.destinationBranchId);
                if (address) {
                    updateAddress(formatAddress(address.address), index);
                }
            }
        }
        // Update address use
        setFieldValue(`addresses.${index}.addressUseId`, event.target.value);
    };

    return (
        <FieldArray name="addresses" render={({ form, ...arrayHelpers }) => (
            <Fragment>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Addresses
                    </Typography>
                </Grid>
                {values.addresses.map((address, index) => {
                    const cityError = getIn(form.errors, `addresses.${index}.city`);
                    const cityTouch = getIn(form.touched, `addresses.${index}.city`);
                    const postcodeError = getIn(form.errors, `addresses.${index}.postcode`);
                    const postcodeTouch = getIn(form.touched, `addresses.${index}.postcode`);
                    const addressUseIdError = getIn(form.errors, `addresses.${index}.addressUseId`);
                    const addressUseIdTouch = getIn(form.touched, `addresses.${index}.addressUseId`);
                    return (
                        <Grid key={index} item container xs={12} sm={9} spacing={2}>
                            <Grid item xs={12}>
                                <Typography color="textSecondary" gutterBottom>
                                    Address #{index + 1}
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl variant="filled" className={classes.inputItem} error={addressUseIdError && addressUseIdTouch} required>
                                    <InputLabel id={`addressUsesLabel_${index}`}>Address Use</InputLabel>
                                    <Select
                                        IconComponent={addressUsesLoading ?
                                            props => (<CircularProgress size={20} />) :
                                            void 0}
                                        disabled={addressUsesLoading || !props.canEdit}
                                        labelId={`addressUsesLabel_${index}`}
                                        id={`addressUses_${index}`}
                                        value={values.addresses[index].addressUseId}
                                        // onChange={handleChange(`addresses.${index}.addressUseId`)}
                                        onChange={event => onAddressUseChange(event, index, values)}
                                        onBlur={handleBlur(`addresses.${index}.addressUseId`)}>
                                        {addressUses.map(au => {
                                            return <MenuItem
                                                value={au.id}
                                                key={au.id}
                                            >{au.title}</MenuItem>
                                        })}
                                    </Select>
                                    <FormHelperText>{addressUseIdError}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} />

                            <Grid item xs={12}>
                                {/* <TextField id={`addressLine_1_${index}`}
                                            className={classes.inputItem}
                                            value={values.addresses[index].addressLine_1}
                                            label="Address Line 1"
                                            name={`addresses.${index}.addressLine_1`}
                                            onChange={(event) => handleAddressChange(event)}

                                            //handleChange(`addresses.${index}.addressLine_1`)}
                                            //handleAddressChange(event, index)}
                                            onBlur={handleBlur(`addresses.${index}.addressLine_1`)}
                                        /> */}
                                <Autocomplete
                                    freeSolo
                                    id="address-select"
                                    disableClearable
                                    disabled={!props.canEdit}
                                    value={values.addresses[index].addressLine_1}
                                    onInputChange={(event, newInputValue) => onAddressInputChange(event, newInputValue, index)}
                                    //onChange={(event) => setFieldValue(`addresses.${index}.addressLine_1`, event.target.value)}
                                    //onBlur={handleBlur(`addresses.${index}.addressLine_1`)}
                                    onChange={(event, newInputValue) => onAddressSelectChange(event, newInputValue, index)}
                                    options={addressSuggestions}
                                    //getOptionLabel={option => option.FullAddress || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="filled"
                                            className={classes.inputItem}
                                            label="Address Line 1"
                                        />)}
                                />
                            </Grid>

                            <Grid item xs={12} />

                            <Grid item xs={12}>
                                <TextField id={`addressLine_2_${index}`}
                                    className={classes.inputItem}
                                    value={values.addresses[index].addressLine_2}
                                    label="Address Line 2"
                                    variant="filled"
                                    onChange={handleChange(`addresses.${index}.addressLine_2`)}
                                    onBlur={handleBlur(`addresses.${index}.addressLine_2`)}
                                    disabled={!props.canEdit}
                                />
                            </Grid>

                            <Grid item xs={12} />

                            <Grid item xs={6}>
                                <TextField id={`city_${index}`}
                                    className={classes.inputItem}
                                    value={values.addresses[index].city}
                                    label="City"
                                    variant="filled"
                                    name={`addresses.${index}.city`}
                                    onChange={(event) => handleChange(event)}
                                    onBlur={handleBlur(`addresses.${index}.city`)}
                                    error={cityError && cityTouch}
                                    helperText={cityError}
                                    disabled={!props.canEdit}
                                    required
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField id={`postcode_${index}`}
                                    className={classes.inputItem}
                                    value={values.addresses[index].postcode}
                                    label="Postcode"
                                    variant="filled"
                                    onChange={handleChange(`addresses.${index}.postcode`)}
                                    onBlur={handleBlur(`addresses.${index}.postcode`)}
                                    error={postcodeError && postcodeTouch}
                                    helperText={postcodeError}
                                    disabled={!props.canEdit}
                                />
                            </Grid>

                            <Grid item xs={12} />

                            {index > 0 && props.canEdit && <Grid xs={12} disableSpacing className={classes.address_remove_button_frame}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                        arrayHelpers.remove(index);
                                        if (props.removeAddress) {
                                            // Just for edit request
                                            props.removeAddress(values.addresses[index]);
                                        }
                                    }}
                                    startIcon={<RemoveIcon />}
                                >
                                    Remove
                                </Button>
                            </Grid>}
                        </Grid>)
                }
                )}
                {props.canEdit && <Grid item xs={3} sm={3}>
                    <Button size="small"
                        onClick={() => arrayHelpers.push({
                            addressLine_1: '',
                            addressLine_2: '',
                            city: '',
                            postcode: '',
                            addressUseId: ''
                        })}
                        width="30%"
                        color="primary"
                        startIcon={<AddIcon />
                        }
                    >
                        Add Address
                        </Button>
                </Grid>}
            </Fragment>
        )
        } />
    )
};

export default AddressesDetailsForm;