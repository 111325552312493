import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Authenticated } from "react-admin";
import {
  Container,
  Grid,
  ListSubheader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  TextField,
  Button,
  FormControl, InputLabel, Select, MenuItem,
} from "@material-ui/core";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PeopleIcon from "@material-ui/icons/People";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";

import packageJson from "../../../package.json";
import {
  FinancialReportForm,
  ReportForm,
} from "../../components";
import { RequestStore } from "../../stores";
import classes from "./reports.module.css";

const environment = process.env.REACT_APP_ENV;

const currentDate = new Date();
const firstDateOfMonth = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth(),
  2
)
  .toISOString()
  .slice(0, 10);
const lastDateOfMonth = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth() + 1,
  1
)
  .toISOString()
  .slice(0, 10);

class ReportsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReport: null,
      formValues:{
        startDate: firstDateOfMonth,
        endDate: lastDateOfMonth,
        type:'',
      },
      reportTitle:'',
    };

    this.requestStore = new RequestStore();
  }

  handleClick = (event, item) => {
    event.preventDefault();
    const indexArray = { ...this.state.expandList };
    for (const [key, value] of Object.entries(indexArray)) {
      if (key === item) {
        indexArray[key] = !value;
      } else {
        indexArray[key] = false;
      }
    }
    this.setState({
      expandList: indexArray,
    });
  };

  generateReport = (values, fileName) => {
    const { selectedReport } = this.state;
    if (selectedReport === "requestSummary") {
      this.requestStore.downloadRequestSummaryReport(values, fileName);
    } else if (selectedReport === "requestLoadPlan") {
      this.requestStore.downloadRequestLoadPlanReport(values, fileName);
    }
  };

  render() {
    return (
      <Authenticated location={this.props.location}>
        <div>
          <Container maxWidth="lg" className="container">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={18}>
                <h1>Reports</h1>
              </Grid>
            </Grid>
          </Container>

          <Container maxWidth="lg" className="container">
            <FormControl className={classes.form_control}>
            {this.state.selectedReport === null ? (
              <InputLabel shrink={false} focused={false} id="report-select-label">
                 Select a Report</InputLabel>
        ) : <InputLabel/>}
              <Select
                labelId="report-select-label"
                id="report-select"
                value={this.state.selectedReport}
                onChange={(event) => {
                  const selectedOption = event.target.value;
                  let formValues = null;
                  let reportTitle = null;
            
                  if (selectedOption === "requestSummary") {
                    reportTitle = 'Requests Summary';
                  } else if (selectedOption === "requestLoadPlan") {
                    reportTitle = 'Requests and Load Plan Details';
                  }
            
                  this.setState({ selectedReport: selectedOption, formValues, reportTitle: reportTitle });
                }}
              >
                <MenuItem value="requestSummary">Requests Summary</MenuItem>
                <MenuItem value="requestLoadPlan">Requests and Load Plan Details</MenuItem>
              </Select>
              <ReportForm
                      formValues={this.state.formValues}
                      onSubmit={this.generateReport}
                      title = {this.state.reportTitle}
                    />
            </FormControl>
          </Container>
        </div>
      </Authenticated>
    );
  }
}

export default withRouter(ReportsPage);