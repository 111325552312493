import axios from 'axios';
import Cookies from 'js-cookie';
import decodeJwt from 'jwt-decode';

const canWriteList = ["Admin", "Linehaul"];

export default {
    
    // called when the user attempts to log in
    login: ({ username, password }, relay) => {
        return new Promise(async (resolve, reject) => {
            axios.post(`${process.env.REACT_APP_API_HOST}/auth/login`, { username, password }, { withCredentials: true } ).then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    reject(response.statusText);
                } else {
                    localStorage.setItem('userToken', response.data);
                    response.redirectTo = relay;
                    resolve(response);
                }
            })
            .catch(error => {
                reject((error.response) ? error.response.data : (error.request) ? error.request : error.message);
            });
            
        })
    },

    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('userToken');
        return Promise.resolve();
    },

    // called when the user clicks on the profile button
    userName: () => {
        const payload = localStorage.getItem('userToken');
        if (payload) {
            const decoded = decodeJwt(payload);
            return decoded.user?.displayName || '';
        } else {
            return '';
        }
    },

    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('userToken');
            return Promise.reject(null);
        }
        return Promise.resolve();
    },

    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('userToken') ? Promise.resolve() : Promise.reject(null);
    },

    // check current user have permission to write or read only
    readOnly: () => {
        let result = true;
        const payload = localStorage.getItem('userToken');
        if (payload) {
            const decoded = decodeJwt(payload);
            const canWrite = decoded.user.userGroups.filter(item => canWriteList.includes(item));
            if (canWrite.length > 0) {
                result = false;
            }
        }

        return result;
    },


    highLevelUser: () => {
        let result = false;
        const payload = localStorage.getItem('userToken');
        if (payload) {
            const decoded = decodeJwt(payload);
            const highLevel = decoded.user.userGroups.filter(item => canWriteList.includes(item));
            if (highLevel.length > 0) {
                result = true;
            }
        }

        return result;
    },

    isBranchOps: branch => {
        const payload = localStorage.getItem('userToken');
        if (!payload) return false;
        const decoded = decodeJwt(payload);
        if (!decoded) return false;
        const roles = decoded.user.userGroups;
        const branchOps = roles.includes('Branch');
        if (branchOps) {
            if (branch) {
                return decoded.user.branches.includes(branch);
            } else {
                return true;
            }
        }
        return false;
    },

    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        try {
            const payload = localStorage.getItem('userToken');
            if (!payload) return Promise.resolve({ roles: [], admin: false, readOnly: true });
            const decoded = decodeJwt(payload);
            if (!decoded) return Promise.reject();
            const roles = decoded.user.userGroups;
            return Promise.resolve({ roles, admin: roles.some(p => canWriteList.includes(p)), readOnly: !roles.some(p => canWriteList.includes(p)) });
        }
        catch (error) {
            console.log(error);
            Promise.reject();
        }
    },

    setPassword: (token, pass) => {
        const postConfig = { withCredentials: false };
        const apiHostUrl = process.env.REACT_APP_API_HOST;

        return new Promise(async (resolve, reject) => {
            axios.post(`${apiHostUrl}/auth/password/setnew/${token}`,
                pass,
                postConfig
            ).then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    reject(response.statusText);
                } else {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                // Error
                if (error.response) {
                    /*
                        * The request was made and the server responded with a
                        * status code that falls out of the range of 2xx
                        */
                    reject(error.response.data);
                } else if (error.request) {
                    /*
                        * The request was made but no response was received, `error.request`
                        * is an instance of XMLHttpRequest in the browser and an instance
                        * of http.ClientRequest in Node.js
                        */
                    reject(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    reject(error.message);
                }
            });
        })
    },

    forgotPassword: (email) => {
        const apiHostUrl = process.env.REACT_APP_API_HOST;

        return new Promise(async (resolve, reject) => {
            axios.get(`${apiHostUrl}/auth/password/forgot/${email}`)
                .then((response) => {
                    if (response.status < 200 || response.status >= 300) {
                        reject(response);
                    } else {
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                    // Error
                    if (error.response) {
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        reject(error.response.data);
                    } else if (error.request) {
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        reject(error.request);
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        reject(error.message);
                    }
                });
        })
    },
};