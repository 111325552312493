import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useDataProvider, Loading, Error } from 'react-admin';
import { useHistory } from "react-router-dom";
import { useDropzone, Dropzone } from 'react-dropzone'
import MuiAlert from '@material-ui/lab/Alert';
import {
    Button, IconButton, Snackbar, Container, InputLabel, Typography, Grid,
    FormControl, FormHelperText, Select, Menu, MenuItem, CircularProgress, TextField
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { KeyboardDateTimePicker } from "@material-ui/pickers";

import { MaterialTextField, MaterialSelect } from '../../components/MaterialUIWithFormik';
import { FormatDate, ModCodes, constructKeyValuePair } from '../../utilities';
import { Spinner } from '../../components';
import classes from './addAgencyBooking.module.css';
import { UploadStore } from '../../stores';

const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const agencyBookingValidationSchema = (cutOffDate, arrivalDate) => {
    return Yup.object().shape({
        agency: Yup.string()
            .required('Required'),
        origin: Yup.string()
            .required('Required'),
        destination: Yup.string()
            .required('Required'),
        vehicle: Yup.string()
            .max(200, 'Vehicle cannot exceed 200 characters'),
        bookingNumber: Yup.string()
            .max(200, 'Booking number cannot exceed 200 characters'),
        bookingReference: Yup.string()
            .required('Required')
            .max(200, 'Booking reference cannot exceed 200 characters'),
        serviceOperator: Yup.string()
            .max(200, 'Service operator cannot exceed 200 characters'),
        serviceNumber: Yup.string()
            .max(200, 'Service number cannot exceed 200 characters'),
        notes: Yup.string()
            .max(1024, "Notes cannot exceed 1024 characters"),
        arrivalScheduledDateTime: Yup.mixed().test(
            'departureCutOffDateTime',
            'The Scheduled Arrival Date must come after the Departure CutOff Date',
            function (value) {
            if (cutOffDate && arrivalDate && cutOffDate > arrivalDate) { 
                return false;
            } else {
                return true;
            }
        }),
    });
};

const AddAgencyBooking = (props) => {
    const dataProvider = useDataProvider();
    const history = useHistory();

    const [updating, setUpdating] = useState(false);
    const [formValue, setFormValue] = useState({
        agency: '',
        bookingDate: new Date(),
        bookingNumber: '',
        bookingReference: '',
        serviceOperator: '',
        serviceNumber: '',
        departureCutOffDateTime: null,
        arrivalScheduledDateTime: null,
        notes: '',
        request: null,
        origin: '',
        destination: '',
        vehicle: '',
        file: ''
    });
    const [file, setFile] = useState(null);
    const [deletedFile, setDeletedFile] = useState(null);
    const [request, setRequest] = useState(null);
    const [agencies, setAgencies] = useState([]);
    const [branches, setBranches] = useState([]);
    const [branchesLoading, setBranchesLoading] = useState(true);
    const [agenciesLoading, setAgenciesLoading] = useState(true);
    const [responseMessage, setResponseMessage] = useState({
        severity: 'info',
        message: ''
    });
    const [cutOffDate, setCutOffDate] = useState(null);
    const [arrivalDate, setArrivalDate] = useState(null);
    const uploadStore = new UploadStore();

    useEffect(() => {
        getRequest();
        getAgencies();
        getBranches();
    }, []);

    const getRequest = () => {
        const id = props.match.params.id;
        dataProvider.getOne('requests', { id: id })
        .then(response => {
            console.log('response.data:', response.data, formValue)
            setRequest(response.data);
            // Init form value: origin and destination
            const data = { ...formValue };
            data.request = { id: response.data.id };
            data.origin = response.data.originBranch.id;
            data.destination = response.data.destinationBranch.id;
            setFormValue(data);
        }).catch(() => {
            setResponseMessage({
                severity: "error",
                message: 'Error: Unable to get request'
            });
        })
    };

    const getAgencies = () => {
        // Agencies drop down
        dataProvider.getList('agencies', {
            pagination: { page: 1, perPage: false },
            sort: { field: 'name', order: 'ASC' }
        }).then(response => {
            setAgencies(constructKeyValuePair(response.data, 'id', 'name'));
            setAgenciesLoading(false);
        }).catch(error => {
            console.log(error);
            setResponseMessage({
                severity: "error",
                message: 'Error: Unable to get agencies'
            });
            setAgenciesLoading(false);
        });
    };

    const getBranches = () => {
        //Branches drop down
        dataProvider.getList('branches', {
            pagination: { page: 1, perPage: false },
            filter: { isPickup: 'true' },
            sort: { field: 'title', order: 'ASC' }
        }).then(response => {
            const branches = constructKeyValuePair(response.data, 'id', 'title');
            setBranches(branches);
            setBranchesLoading(false);
        }).catch(error => {
            console.log(error);
            setResponseMessage({
                severity: "error",
                message: 'Error: Unable to get branches'
            });
            setBranchesLoading(false);
        });
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*,.pdf',
        onDrop: acceptedFiles => {
            setFile(acceptedFiles[0]);
            // Current file has been replaced
            setDeletedFile(formValue.file);
        }
    });

    const removeFile = () => {
        setFile(null);
    };

    const deleteFile = () => {
        setDeletedFile(formValue.file);
        setFormValue({ ...formValue, file: '' });
    };

    const submitForm = (values) => {
        dataProvider.create('agency_bookings', { data: values })
            .then(({ data }) => {
                // Nav back to requests page
                history.goBack();
                setUpdating(false);
            })
            .catch(error => {
                console.log(error);
                setResponseMessage({
                    severity: "error",
                    message: error.message
                });
                setUpdating(false);
            });
    };

    const handleSubmit = (values, actions) => {
        setUpdating(true);
        actions.setSubmitting(true);
        // update date
        values.departureCutOffDateTime = cutOffDate;
        values.arrivalScheduledDateTime = arrivalDate;
        // values.agency is an ID instead of an object
        values.agency = { id: values.agency };
        values.origin = { id: values.origin };
        values.destination = { id: values.destination };

        if (deletedFile) {
            // delete file from s3
            uploadStore.deleteAgencyBookingFile(values.id)
            .then(data => {
                if (data.success) {
                    // delete file
                } else {
                    setResponseMessage({
                        severity: "error",
                        message: data.message
                    });
                }
                setUpdating(false);
            })
            .catch(error => {
                setResponseMessage({
                    severity: "error",
                    message: error.message
                });
                setUpdating(false);
            });
        };

        if (file) {
            // upload file then get file path
            uploadStore.uploadAgencyBookingFile(file)
            .then(data => {
                if (data.success) {
                    // add file path to agency form value 
                    values.file = data.result.key;
                    submitForm(values);
                } else {
                    // upload failed, remove file
                    setFile(null);
                    setResponseMessage({
                        severity: "error",
                        message: data.message || 'File upload failed, please try again later'
                    });
                    // update other form fields
                    const newData = {...values, file: '', agency: values.agency.id, destination: values.destination.id, origin: values.origin.id};
                    setFormValue(newData);
                }
                setUpdating(false);
            })
            .catch(error => {
                // upload failed, remove file
                setFile(null);
                setResponseMessage({
                    severity: "error",
                    message: error.message || 'File upload failed, please try again later'
                });
                // update other form fields
                const newData = {...values, file: '', agency: values.agency.id, destination: values.destination.id, origin: values.origin.id};
                setFormValue(newData);
                setUpdating(false);
            });
        } else {
            submitForm(values);
        }
        actions.setSubmitting(false);
    };

    const formatFileName = (file) => {
        // remove the file path from file name
        if (file.includes('/')) {
            const nameArray = file.split('/');
            return nameArray[1] || nameArray[0];
        } else {
            return file;
        }
    };

    const clearResponseMessage = () => {
        setResponseMessage({
            severity: responseMessage.severity,
            message: ''
        });
    };

    const handleSnackbarClose = () => {
        clearResponseMessage();
    };

    return (
        <div className={classes.form_frame}>
            <Snackbar open={responseMessage.message != ''} autoHideDuration={5000} onClose={handleSnackbarClose}>
                <Alert severity={responseMessage.severity}>
                    {responseMessage.message}
                </Alert>
            </Snackbar>

            <div variant="outlined" square className={classes.agency_booking_box}>
                <Grid container xs={12} sm={12} className={classes.agency_booking_box_title_frame}>
                    <Typography variant="h5">
                        Add Agency Booking {request?.interactId ?`For ${request.interactId}` : ''}
                    </Typography>
                </Grid>

                {updating ? <Grid item xs={12} sm={12}>
                    <Spinner />
                </Grid>
                    :
                    <Formik
                        initialValues={formValue}
                        enableReinitialize={true}
                        validationSchema={agencyBookingValidationSchema(cutOffDate, arrivalDate)}
                        onSubmit={handleSubmit}
                        render={({ isSubmitting, errors, touched, values, handleBlur, handleChange, setFieldValue, setFieldTouched, setFieldError }) => (
                            <Form>
                                <div className={classes.form_content}>
                                    <Grid container xs={12} sm={12}>
                                        <Grid item xs={12} sm={6} className={classes.form_field}>
                                            <FormControl className={classes.form_select_field} variant="filled" error={errors.agency && touched.agency} required>
                                                    <InputLabel id="agency">Agency</InputLabel>
                                                    <Select
                                                        IconComponent={agenciesLoading ?
                                                            props => (<CircularProgress size={20} />) :
                                                            void 0}
                                                        id="agency"
                                                        value={values.agency}
                                                        onChange={handleChange('agency')}
                                                        onBlur={handleBlur('agency')}>
                                                        {agencies.map(item => {
                                                            return <MenuItem
                                                                value={item.key}
                                                                key={item.key}
                                                            >{item.value}</MenuItem>
                                                        })}
                                                    </Select>
                                                    <FormHelperText>{errors.agency}</FormHelperText>
                                                </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6} className={classes.form_field}>
                                            <Field
                                                label="Vehicle"
                                                component={MaterialTextField}
                                                name="vehicle"
                                                type="text"
                                                mode={"edit"}
                                                helperText={errors.vehicle}
                                                error={errors.vehicle && touched.vehicle}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} className={classes.form_field}>
                                            <FormControl variant="filled" className={classes.form_select_field} error={errors.origin && touched.origin} required>
                                                <InputLabel id="originBranchLabel">Origin Branch</InputLabel>
                                                <Select
                                                    IconComponent={branchesLoading ?
                                                        props => (<CircularProgress size={20} />) :
                                                        void 0}
                                                    disabled={branchesLoading}
                                                    labelId="originBranchLabel"
                                                    id="origin"
                                                    value={values.origin}
                                                    onChange={handleChange('origin')}
                                                    onBlur={handleBlur('origin')}
                                                >
                                                    {branches.map(item => {
                                                            return <MenuItem
                                                            value={item.key}
                                                            key={item.key}
                                                        >{item.value}</MenuItem>
                                                    })}
                                                </Select>
                                                <FormHelperText>{errors.origin}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6} className={classes.form_field}>
                                            <FormControl variant="filled" className={classes.form_select_field} error={errors.destination && touched.destination} required>
                                                <InputLabel id="destinationBranchLabel">Destination Branch</InputLabel>
                                                <Select
                                                    IconComponent={branchesLoading ?
                                                        props => (<CircularProgress size={20} />) :
                                                        void 0}
                                                    disabled={branchesLoading}
                                                    labelId="destinationBranchLabel"
                                                    id="destination"
                                                    value={values.destination}
                                                    onChange={handleChange('destination')}
                                                    onBlur={handleBlur('destination')}
                                                >
                                                    {branches.map(item => {
                                                        return <MenuItem
                                                            value={item.key}
                                                            key={item.key}
                                                            >{item.value}</MenuItem>
                                                    })}
                                                </Select>
                                                <FormHelperText>{errors.destination}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6} className={classes.form_field}>
                                            <Field
                                                label="Booking Number"
                                                component={MaterialTextField}
                                                name="bookingNumber"
                                                type="text"
                                                mode={"edit"}
                                                helperText={errors.bookingNumber}
                                                error={errors.bookingNumber && touched.bookingNumber}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} className={classes.form_field}>
                                            <Field
                                                label="Booking Reference"
                                                component={MaterialTextField}
                                                name="bookingReference"
                                                type="text"
                                                mode={ "edit"}
                                                helperText={errors.bookingReference}
                                                error={errors.bookingReference && touched.bookingReference}
                                                required
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} className={classes.form_field}>
                                            <Field
                                                label="Service Operator"
                                                component={MaterialTextField}
                                                name="serviceOperator"
                                                type="text"
                                                mode={ "edit"}
                                                helperText={errors.serviceOperator}
                                                error={errors.serviceOperator && touched.serviceOperator}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} className={classes.form_field}>
                                            <Field
                                                label="Service Number"
                                                component={MaterialTextField}
                                                name="serviceNumber"
                                                type="text"
                                                mode={"edit"}
                                                helperText={errors.serviceNumber}
                                                error={errors.serviceNumber && touched.serviceNumber}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} className={classes.form_field}>
                                            <KeyboardDateTimePicker
                                                autoOk
                                                className={classes.date_time_picker}
                                                inputVariant="filled"
                                                label="Departure CutOff"
                                                value={cutOffDate}
                                                onChange={setCutOffDate}
                                                // onError={console.log}
                                                placeholder="ex: DD/MM/YY hh:mm AM"
                                                format="DD/MM/YY hh:mm A"
                                                ampm={true}
                                                invalidDateMessage="Invalid Date"
                                                cancelLabel="Cancel"     
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} className={classes.form_field}>
                                            <KeyboardDateTimePicker
                                                autoOk
                                                className={classes.date_time_picker}
                                                inputVariant="filled"
                                                label="Scheduled Arrival"
                                                value={arrivalDate}
                                                onChange={setArrivalDate}
                                                // onError={console.log}
                                                placeholder="ex: DD/MM/YY hh:mm AM"
                                                format="DD/MM/YY hh:mm A"
                                                ampm={true}
                                                invalidDateMessage="Invalid Date"
                                                minDate={cutOffDate}
                                                minDateMessage="The Scheduled Arrival Date must come after the Departure CutOff Date"
                                                cancelLabel="Cancel"     
                                                disabled={!cutOffDate}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} className={classes.form_field}>
                                            <Field
                                                label="Notes"
                                                component={MaterialTextField}
                                                name="notes"
                                                type="text"
                                                mode={"edit"}
                                                helperText={errors.notes}
                                                error={errors.notes && touched.notes}
                                                multiline
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} className={classes.form_field}>
                                            <section>
                                                <div {...getRootProps({ className: 'dropzone' })} className={classes.drop_zone_frame}>
                                                    <input {...getInputProps()} />
                                                    <p>Drag 'n' drop file here, or click to select file</p>
                                                </div>
                                                {file ? <aside className={classes.drop_zone_files_frame}>
                                                    <InputLabel>File:</InputLabel>
                                                    <div>
                                                        {file.path} &nbsp;
                                                        <IconButton aria-label="delete" onClick={removeFile}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                </aside>
                                                :
                                                <aside className={classes.drop_zone_files_frame}>
                                                    {formValue.file && <div><InputLabel>File:</InputLabel>
                                                        {formatFileName(formValue.file)} &nbsp;
                                                            <IconButton aria-label="delete" onClick={deleteFile}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                    </div>}
                                                </aside>
                                                }
                                            </section>
                                        </Grid>
                                    </Grid>

                                    <div className={classes.from_actions}>
                                        <Button
                                            variant={"outlined"}
                                            color="primary"
                                            onClick={() => history.goBack()}
                                        >
                                            Cancel
                                        </Button>

                                       <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            startIcon={isSubmitting ? <Spinner color="#fff" size="18px" /> : <SaveIcon />}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    />}
            </div>
        </div>
    );
};

export default AddAgencyBooking;
