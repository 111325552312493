import React, { Fragment } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TextField from '@material-ui/core/TextField';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import classes from './draggableRequestGrid.module.css';

const DraggableRequestGrid = (props) => {

    const { isSelected, loadRequestId, loadRequests, onReorder, onVolumeChange } = props;

    const grid = 8;

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            loadRequests,
            result.source.index,
            result.destination.index
        );

        onReorder && onReorder(loadRequestId, items);
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        //padding: grid * 2,
        padding: grid,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? "lightyellow" : "white",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    return (
        <Fragment>
            <Paper variant="outlined" square style={{ marginTop: '20px' }}>
                <Grid container direction="row" spacing={1} className={classes.paper_header}>
                    <Grid item xs={1}>
                    </Grid>

                    <Grid item xs={1}>
                        <p className={classes.columnHeaderText}>
                            Order
                        </p>
                    </Grid>

                    <Grid item xs={2}>
                        <p className={classes.columnHeaderText}>
                            Interact ID
                        </p>
                    </Grid>

                    <Grid item xs={2}>
                        <p className={classes.columnHeaderText}>
                            Customer
                        </p>
                    </Grid>

                    <Grid item xs={2}>
                        <p className={classes.columnHeaderText}>
                            Volume
                        </p>
                    </Grid>

                    <Grid item xs={2}>
                        <p className={classes.columnHeaderText}>
                            Status
                        </p>
                    </Grid>
                </Grid>
            </Paper>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable key={loadRequestId} droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {loadRequests.map((item, index) => (
                                <Paper key={item.id} variant="outlined" square>
                                    <Draggable
                                        isDragDisabled={!isSelected}
                                        draggableId={`${item.id}`}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <Grid container
                                                    direction="row"
                                                    spacing={1}
                                                    alignItems="center"
                                                //justify="center"
                                                >
                                                    <Grid item xs={1}>
                                                        {isSelected && <DragIndicatorIcon />}
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        {item.loadOrder}
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        {item.request.interactId}
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        {item.request.customerOrganisation ? `${item.request.customerOrganisation} - ${item.request.customerPersonName}` :  item.request.customerPersonName}
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        {item.volumeEditable ? (
                                                            <div className={classes.textContainer}>
                                                                <TextField id="outlined-basic"
                                                                    variant="filled"
                                                                    type="number"
                                                                    value={item.volume}
                                                                    onChange={(event) => onVolumeChange(loadRequestId, item.id, parseFloat(event.target.value))}
                                                                    size="small"
                                                                    margin="dense"
                                                                />
                                                            </div>)
                                                            : item.volume}
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        {item.request.requestStatus.title}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )}
                                    </Draggable>
                                </Paper>)
                            )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Fragment>
    );
}

export default DraggableRequestGrid;