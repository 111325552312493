import React from 'react';
import {
    Edit,
    SimpleForm,
    Create,
    ReferenceInput,
    List,
    NumberField,
    Show,
    SimpleShowLayout,
    Datagrid,
    ReferenceField,
    TextField,
    TextInput,
    required} from 'react-admin';
import { Typography } from '@material-ui/core';
import { ViewButton, TextNullableField, ShowNullableField } from '../../components';
import classes from '../reactAdmin.module.css';

export const ContainerTypeList = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>{props.options.label}</Typography>
        <List {...props}>
            <Datagrid rowClick="show">
                <TextField source="title" />
                <TextField source="code" />
                <TextNullableField source="description" />
                <NumberField source="volume" /> 
                <TextField source="displayOrder" />  
                <ViewButton />             
            </Datagrid>
        </List>
    </div>
);

export const ContainerTypeShow = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>{props.options.name}</Typography>
        <Show {...props}>
            <SimpleShowLayout>
            <TextField source="title" />
                <TextField source="code" />
                <ShowNullableField source="description" label="Description" />
                <NumberField source="volume" />     
                <NumberField source="displayOrder" />
            </SimpleShowLayout>
        </Show>
    </div>
);

export const ContainerTypeCreate = (props) => {
    return (
        <div>
        <Typography variant="h5" className={classes.react_admin_page_add_header}>Create {props.options.name}</Typography>
        <Create {...props}>
            <SimpleForm>
                <TextInput source="title" validate={required()} />
                <TextInput source="code" validate={required()} />
                <TextInput multiline source="description" />
                <TextInput source="volume" type="number" validate={required()} />
                <TextInput source="displayOrder" type="number" validate={required()} />
            </SimpleForm>
        </Create>
    </div>
    )
};

export const ContainerTypeEdit = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>Edit {props.options.name}</Typography>
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="title" validate={required()} />
                <TextInput source="code" validate={required()} />
                <TextInput multiline source="description" />
                <TextInput source="volume" type="number" validate={required()} />
                <TextInput source="displayOrder" type="number" validate={required()} />
            </SimpleForm>
        </Edit>
    </div>
);