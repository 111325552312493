import React, { Fragment, Component } from 'react';
import { Formik, Field, Form } from 'formik';
import { MaterialTextField } from '../../components/MaterialUIWithFormik';
import { Chip, Link, Snackbar } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { Spinner } from '../../components';
import * as Yup from 'yup';
import MuiAlert from '@material-ui/lab/Alert';
import {
  Button, IconButton, TextField, Container, Card, InputLabel, FormControl, Typography, ListItemIcon, Grid
} from '@material-ui/core';
import classes from './forgotPassword.module.css';
import authProvider from '../../providers/authProvider';

const DEFAULT_ERROR_MESSAGE = 'The request failed. Please try again';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class ForgotPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formValue: {
        email: '',
      },
      updateSuccess: false,
      errorMessage: ''
    };
  }

  handleSubmit = (value, actions) => {
    actions.setSubmitting(true);
    authProvider.forgotPassword(value.email)
      .then(response => {
        if (response.success) {
          this.setState({
            updateSuccess: true
          });
        } else {
          // show error message
          this.setState({
            errorMessage: DEFAULT_ERROR_MESSAGE
          });
        }
      })
      .catch(error => {
        // show error message
        this.setState({
          errorMessage: error.message || DEFAULT_ERROR_MESSAGE,
        });
      })
    actions.setSubmitting(false);
  }

  handleSnackbarClose = () => {
    this.setState({
      errorMessage: '',
    });
  }

  render() {

    let openSnackbar = false;
    if (this.state.errorMessage) {
      openSnackbar = true;
    }

    return (
      <div className={classes.form_frame}>
        <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={this.handleSnackbarClose}>
          <Alert severity="error">
            {this.state.errorMessage}
          </Alert>
        </Snackbar>
        <h3>Welcome To Crown</h3>
        <div>
          {!this.state.updateSuccess && <div>
            <h3>Forgot your password?</h3>
            <p>Enter your email address and we'll send you password reset instructions.</p>
          </div>}
          {this.state.updateSuccess ? <div className={classes.form_title_content}><Chip
            label="We've sent your instructions."
            color="primary"
            deleteIcon={<DoneIcon />}
            className={classes.success_message_chip}
          />
            <p>You should receive an email shortly. If you don't, try resending the instructions, and checking your junk email folder.</p>
            <p>Already set your password?
          <Link href="/" style={{ color: '#0090E9', paddingTop: '15px' }}>&nbsp;Log in</Link>
            </p>
          </div>
            :
            <Formik
              initialValues={this.state.formValue}
              onSubmit={this.handleSubmit}
              render={({ isSubmitting, errors, touched }) => (
                <Form>
                  <div className={classes.form_content}>
                    <Grid item xs={12} sm={12}>
                      <Grid item xs={12} sm={12}>
                        <Field
                          label="Email"
                          component={MaterialTextField}
                          name="email"
                          type="email"
                          helperText={errors.email}
                          error={errors.email && touched.email}
                        />
                      </Grid>
                    </Grid>
                    <div className={classes.from_actions}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        {isSubmitting ? <Spinner /> : null} Submit
                  </Button>
                    </div>
                  </div>
                </Form>
              )}
            />}
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
