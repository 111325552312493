import React, { useEffect, useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, Loading, Error } from 'react-admin';
import { Formik, Field, FieldArray, getIn } from 'formik';
import { useHistory } from "react-router-dom";
import { useIsMountedRef } from '../../hooks';

import {
    Button,
    CircularProgress,
    MuiSelect,
    CardActions, Card, CardContent,
    Container, Grid,
    FormControl, FormHelperText,
    Input, InputAdornment, InputLabel,
    LinearProgress, MenuItem, Paper,
    Select, TextField,
    Typography
} from '@material-ui/core';
import authProvider from '../../providers/authProvider';
import { FormatDate, ModCodes } from '../../utilities';
import { PageWrapper } from '../../components';
import classes from './loadPlanForm.module.css';

const LoadPlanForm = (props) => {
    const history = useHistory();
    const dataProvider = useDataProvider();
    const isMountedRef = useIsMountedRef();

    const [branches, setBranches] = useState([]);
    const [branchesLoading, setBranchesLoading] = useState(true);

    useEffect(() => {
        // branches dropdown
        dataProvider.getList('branches', {
            pagination: { page: 1, perPage: false },
            filter: { isPickup: 'true' },
            sort: { field: 'title', order: 'ASC' }
        }).then(response => {
            if (isMountedRef.current) {
                setBranches(response.data);
                setBranchesLoading(false);
            }
        }).catch(error => {
            console.log(`Error getting branches`);
            console.log(error);
        });
    }, []);

    const isHighLevelUser = () => {
        return authProvider.highLevelUser();
    };

    const canEdit = () => {
        const originBranchId = props.values.originId;
        if (authProvider.highLevelUser() || authProvider.isBranchOps(originBranchId)) {
            return true;
        } else {
            return false;
        }
    };

    const today = new Date();
    const startDate = FormatDate.datePickerDate(today);

    const {
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
    } = props;
    return (
        <Grid item container xs={12} sm={9}>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Plan Details
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid item container xs={12} spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            className={classes.inputItem}
                            id="displayId"
                            value={values.displayId}
                            label="Display ID"
                            type="text"
                            variant="filled"
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            className={classes.inputItem}
                            error={errors.departureDate && touched.departureDate}
                            id="departureDate"
                            value={values.departureDate}
                            label="Departure Date"
                            type="date"
                            variant="filled"
                            disabled={!isHighLevelUser()}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // InputProps={{ inputProps: { min: startDate } }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={errors.departureDate}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl variant="filled" className={classes.inputItem} error={errors.containerTypeId && touched.containerTypeId} required>
                            <InputLabel id="containerTypeLabel">Container Type</InputLabel>
                            <Select
                                IconComponent={props.containerTypesLoading ?
                                    props => (<CircularProgress size={20} />) :
                                    void 0}
                                disabled={props.containerTypesLoading}
                                labelId="containerTypeLabel"
                                id="containerType"
                                disabled={!isHighLevelUser()}
                                value={props.containerTypesLoading ? '' : values.containerTypeId}
                                onChange={handleChange('containerTypeId')}
                                onBlur={handleBlur('containerTypeId')}>
                                {props.containerTypes.map(ct => {
                                    return <MenuItem
                                        selected={values.containerTypeId === ct.id}
                                        value={ct.id}
                                        key={ct.id}
                                    >{ct.title}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText>{errors.containerTypeId}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField id="containerNumber"
                            className={classes.inputItem}
                            value={values.containerNumber}
                            disabled={!isHighLevelUser()}
                            label="Container Number"
                            type="text"
                            variant="filled"
                            error={errors.containerNumber && touched.containerNumber}
                            helperText={errors.containerNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl variant="filled" className={classes.inputItem} error={errors.originId && touched.originId} required>
                            <InputLabel id="originBranchLabel">Origin Branch</InputLabel>
                            <Select
                                IconComponent={branchesLoading ?
                                    props => (<CircularProgress size={20} />) :
                                    void 0}
                                disabled={true || branchesLoading}
                                labelId="originBranchLabel"
                                id="originBranch"
                                value={branchesLoading ? '' : values.originId}
                                onChange={handleChange('originId')}
                                onBlur={handleBlur('originId')}
                            >
                                {branches.map(b => {
                                    return <MenuItem
                                        selected={values.originId === b.id}
                                        value={b.id}
                                        key={b.id}
                                    >{b.title}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText>{errors.originId}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl variant="filled" className={classes.inputItem} error={errors.destinationId && touched.destinationId} required>
                            <InputLabel id="destinationBranchLabel">Destination Branch</InputLabel>
                            <Select
                                IconComponent={branchesLoading ?
                                    props => (<CircularProgress size={20} />) :
                                    void 0}
                                disabled={true || branchesLoading}
                                labelId="destinationBranchLabel"
                                id="destinationBranch"
                                value={branchesLoading ? '' : values.destinationId}
                                onChange={handleChange('destinationId')}
                                onBlur={handleBlur('destinationId')}
                            >
                                {branches.map(b => {
                                    return <MenuItem
                                        selected={values.destinationId === b.id}
                                        value={b.id}
                                        key={b.id}
                                    >{b.title}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText>{errors.destinationId}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField id="sealNumber"
                            className={classes.inputItem}
                            value={values.sealNumber}
                            disabled={!canEdit()}
                            label="Seal Number"
                            type="number"
                            variant="filled"
                            error={errors.sealNumber && touched.sealNumber}
                            helperText={errors.sealNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField id="stowedBy"
                            className={classes.inputItem}
                            value={values.stowedBy}
                            disabled={!canEdit()}
                            label="Stowed By"
                            variant="filled"
                            error={errors.stowedBy && touched.stowedBy}
                            helperText={errors.stowedBy}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField id="volumeAvailable"
                            className={classes.inputItem}
                            value={values.volumeAvailable}
                            label="Volume Available"
                            type="number"
                            variant="filled"
                            disabled={true}
                            error={errors.volumeAvailable && touched.volumeAvailable}
                            helperText={errors.volumeAvailable}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default LoadPlanForm;