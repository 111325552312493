import React, { Component } from 'react';
import { Link } from '@material-ui/core';
import './chipBar.css';

class ChipBar extends Component {

  render() {
    return (
      <div className='chipbar flexbox'>
        { !this.props.hideReference ?
        <Link color="inherit" style={{textDecoration: "none"}}>
            {this.props.linkText}
        </Link>
        : null}
        { !this.props.hideReference ?
        <span className="linksep">•</span>
        : null}
        <div className="chipbar-children">
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default ChipBar;