import React from 'react';
import Paper from '@material-ui/core/Paper';
import {
    TextInput
} from 'react-admin';

import classes from './BranchContactInput.module.css';

export const BranchContactInput = ({ contactNumber, name, email, phone }) => {
    return (
        <Paper className={classes.ContactWrap}>
            <div className={classes.ContactTitle}><strong>Contact {contactNumber}</strong></div>
            <div>
                <TextInput className={classes.ContactInput} source={name} />
                <TextInput className={classes.ContactInput} source={email} />
                <TextInput className={classes.ContactInput} source={phone} />
            </div>
        </Paper>
    );
}
