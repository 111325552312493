import React from 'react';
import { TextField } from '@material-ui/core';

import { FormatReactAdminTextField } from '../../utilities';
import './showNullableField.css';

const inputProps = {
  readOnly: true,
  disableUnderline: true
};

const inputLabelProps = {
  shrink: true,
};

const ShowNullableField = ({ record = {}, source, label }) => {
  const value = FormatReactAdminTextField.formatValue(record, source);
  return (
    value ? <div className="field_frame"><TextField id={source}
      value={value}
      label={label}
      InputProps={inputProps}
      InputLabelProps={inputLabelProps}
    /></div>
    : 
    <p className="label">{label}</p>
  );
};

export default ShowNullableField;