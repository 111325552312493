import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { MaterialTextField } from '../../components/MaterialUIWithFormik';
import { userLogin } from 'react-admin';
import { MuiThemeProvider, Card, Link, Grid, Button, Snackbar, Typography } from '@material-ui/core';
import * as Yup from 'yup';
import MuiAlert from '@material-ui/lab/Alert';
import { Spinner } from '../../components';
import authProvider from '../../providers/authProvider';
import classes from './login.module.css';
import crown from '../../assets/images/crown-transparent.png';

const DEFAULT_ERROR_MESSAGE = 'There was an error with your E-Mail/Password combination. Please try again.';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const loginValidationSchema = () => {
    return Yup.object().shape({
        username: Yup.string()
            .required('Required'),
        password: Yup.string()
            .min(4, 'Password has to be longer than 4 characters!')
            .required('Required'),
    });
};

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formValue: {
                username: '',
                password: '',
            },
            errorMessage: ''
        };
    }

    handleSubmit = (values, actions) => {
        actions.setSubmitting(true);
        authProvider.login(values, this.props.location.state?.nextPathname).then(response => { 
            this.props.history.push(response.redirectTo||'/home');
            window.location.reload();        
        })
        .catch(error => {
            // show error message
            this.setState({
                errorMessage: error.message || DEFAULT_ERROR_MESSAGE
            });
        }).finally(() => {
            actions.setSubmitting(false);
        })  
    }

    handleSnackbarClose = () => {
        this.setState({
            errorMessage: '',
        });
    }

    render() {

        let openSnackbar = false;
        if (this.state.errorMessage) {
            openSnackbar = true;
        }

        return (
            <div className={classes.login_page_root}>
                <MuiThemeProvider theme={this.props.theme}>
                    <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={this.handleSnackbarClose}>
                        <Alert severity="error">
                            {this.state.errorMessage}
                        </Alert>
                    </Snackbar>

                    <div className={classes.login_page_frame}>
                        <img src={crown} className={classes.login_logo} alt="logo" />

                        <Typography variant="h4">
                            Welcome Back
                        </Typography>

                        <Card className={classes.login_card_frame}>
                            <Formik
                                initialValues={this.state.formValue}
                                validationSchema={loginValidationSchema}
                                onSubmit={this.handleSubmit}
                                render={({ isSubmitting, errors, touched }) => (
                                    <Form>
                                        <div className={classes.form_content}>
                                            <Grid item xs={12} sm={12}>
                                                <Grid item xs={12} sm={12}>
                                                    <Field
                                                        label="Email"
                                                        component={MaterialTextField}
                                                        name="username"
                                                        type="email"
                                                        helperText={errors.email}
                                                        error={errors.email && touched.email}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Field
                                                        label="Password"
                                                        component={MaterialTextField}
                                                        name="password"
                                                        type="password"
                                                        helperText={errors.password}
                                                        error={errors.password && touched.password}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <div className={classes.from_actions}>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                >
                                                    {isSubmitting && <Spinner color="#fff" size="18px" />} &nbsp; Login
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            />
                        </Card>

                        <Link color="inherit" href="/#/forgotpassword">Forgot your password?</Link>
                    </div>
                </MuiThemeProvider>
            </div>
        );
    }
};

export default connect(undefined, { userLogin })(Login);