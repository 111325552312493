import React from 'react';
import {
    List, Datagrid, TextField, NumberInput, EmailField, NumberField, Show,
    SimpleShowLayout, Create, Edit, SimpleForm, TextInput, SelectArrayInput,
    ReferenceArrayInput, ReferenceArrayField, SingleFieldList, ChipField
} from 'react-admin';
import { required } from 'react-admin';
import { Typography } from '@material-ui/core';
import classes from '../../../crud/reactAdmin.module.css';

export const UserList = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>{props.options.label}</Typography>
        <List {...props} >
            <Datagrid rowClick="show">
                <TextField source="displayName" />
                <EmailField source="email" />
                <TextField source="jobTitle" />
                <ReferenceArrayField label="User Groups" reference="user_groups" source="userGroups">
                    <SingleFieldList>
                        <ChipField source="code" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ReferenceArrayField label="Branches" reference="branches" source="branches">
                    <SingleFieldList>
                        <ChipField source="title" />
                    </SingleFieldList>
                </ReferenceArrayField>
            </Datagrid>
        </List>
    </div>
);

export const UserShow = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>{props.options.name}</Typography>
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="displayName" />
                <NumberField source="workPhone" />
                <NumberField source="mobilePhone" />
                <EmailField source="email" />
                <TextField source="jobTitle" label="Job Title" />
                <ReferenceArrayField label="User Groups" reference="user_groups" source="userGroups">
                    <SingleFieldList>
                        <ChipField source="code" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ReferenceArrayField label="Branches" reference="branches" source="branches">
                    <SingleFieldList>
                        <ChipField source="title" />
                    </SingleFieldList>
                </ReferenceArrayField>
            </SimpleShowLayout>
        </Show>
    </div>
);

export const UserCreate = (props) => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_add_header}>Create {props.options.name}</Typography>
        <Create {...props}>
            <SimpleForm>
                <TextInput source="firstName" validate={required()} />
                <TextInput source="lastName" validate={required()} />
                <NumberInput source="workPhone" />
                <NumberInput source="mobilePhone" />
                <TextInput source="email" type="email" validate={required()} />
                <TextInput source="jobTitle" validate={required()} />
                <ReferenceArrayInput label="User Groups" source="userGroups" reference="user_groups" validate={required()} >
                    <SelectArrayInput optionText="code" optionValue="id" />
                </ReferenceArrayInput>
                <ReferenceArrayInput 
                    label="Branches" 
                    source="branches" 
                    reference="branches" 
                    validate={required()} 
                    sort={{field: 'title', order: 'ASC'}}
                    perPage={50}
                >
                    <SelectArrayInput optionText="title" optionValue="id" />
                </ReferenceArrayInput>
            </SimpleForm>
        </Create>
    </div>
);

export const UserEdit = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>Edit {props.options.name}</Typography>
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="firstName" validate={required()} />
                <TextInput source="lastName" validate={required()} />
                <NumberInput source="workPhone" />
                <NumberInput source="mobilePhone" />
                <TextInput source="email" type="email" validate={required()} />
                <TextInput source="jobTitle" validate={required()} />
                <ReferenceArrayInput label="User Groups" source="userGroups" reference="user_groups" key="id" validate={required()} >
                    <SelectArrayInput optionText="code" optionValue="id" />
                </ReferenceArrayInput>
                <ReferenceArrayInput 
                    label="Branches" 
                    source="branches" 
                    reference="branches" 
                    validate={required()} 
                    sort={{field: 'title', order: 'ASC'}}
                    perPage={50}
                >
                    <SelectArrayInput optionText="title" optionValue="id" />
                </ReferenceArrayInput>
            </SimpleForm>
        </Edit>
    </div>
);