import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import classes from './spinner.module.css';
import { checkPropTypes } from 'prop-types';

export default function Spinner(props) {

  const color = props.color || 'primary';
  const size  = props.size || 36;

  return (
    <div className={classes.spinner_in_page_center}>
      <CircularProgress 
        color={color} 
        size={size} />
    </div>
  );
}
