import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { RequestTable } from '../../index';
import { useIsMountedRef } from '../../../hooks';
import { useDataProvider, showNotification } from 'react-admin';
import { Skeleton } from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import { RequestStore } from '../../../stores';

const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const RequestGrid = ({ status, onRequestStatusChange, dense, onDenseChange, interactId, reload, onReload, onResponseMessage }) => {
    const isMountedRef = useIsMountedRef();
    const dataProvider = useDataProvider();
    const [requests, setRequests] = useState([]);
    const [requestStatuses, setRequestStatuses] = useState([]);
    const [recordCount, setRecordCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [sorting, setSorting] = useState({ field: 'requestedUpliftDate', order: 'asc' });
    const [paging, setPaging] = useState({ page: 1, perPage: 25 });
    const [responseMessage, setResponseMessage] = useState({ severity: 'info', message: '' });
    const history = useHistory();
    const requestStore = new RequestStore();

    function getRequests (page, perPage, sortCol, sortDirection) {
        showNotification('Error: Unable to load requests', 'warning');
        setLoading(true);


        const sort = { field: sortCol || sorting.field, order: sortDirection || sorting.order };
        const pagination = { page: page || paging.page, perPage: perPage || paging.perPage };

        dataProvider.getList('requests', { pagination, sort, filter: { status, interact_id: interactId } })
            .then(({ data, total }) => {
                if (isMountedRef.current) {
                    setRequests(data);
                    setSorting(sort);
                    setPaging(pagination); 
                    setRecordCount(total);
                    setLoading(false);
                    onRequestStatusChange();
                    onReload();
                }
            })
            
            .catch(() => {
                setResponseMessage({
                    severity: "error",
                    message: 'Error: Unable to load requests'
                });
                setLoading(false);
            })
    };
    


    const getStatuses = () => {
        dataProvider.getList('request_statuses', {
            pagination: { page: 1, perPage: false },
            sort: { field: 'title', order: 'ASC' }
        }).then(response => {
            setRequestStatuses(response.data);

        }).catch(error => {
            console.log(error);
            setResponseMessage({
                error: true,
                message: error.message || 'Error getting request_statuses'
            });
        });
    };

    useEffect(() => {
        getRequests();
        getStatuses();
    }, []);

    useEffect(() => {
        if (reload) {
            getRequests();
        }
    }, [reload]);

    const onRowClick = (id) => {
        history.push(`/requests/view/${id}`);
    };

    const clearResponseMessage = () => {
        setResponseMessage({
            severity: responseMessage.severity,
            message: ''
        });
    };

    const handleSnackbarClose = () => {
        clearResponseMessage();
    };

    const onSortChange = (sortCol, sortDirection) => getRequests(1, paging.perPage, sortCol, sortDirection);

    const onProcessNow = requestId => {
        const status = requestStatuses.find(s => s.code === 'Processing');
        const data = {
            id: requestId,
            newStatusId: status.id
        };
        requestStore.editRequestStatus(requestId, data)
            .then(response => {
                if (isMountedRef.current) {
                    if (response.success) {
                        history.push(`/loadplan/${requestId}/0/`);
                    }
                }
            }).catch(error => {
                console.log(error);
            });
    };

    const onStatusChange = (response) => {
        if(onResponseMessage && response && response.message) {
            onResponseMessage({
                severity: response.severity||'info',
                message: response.message
            });
        }
        getRequests();
    }

    const setErrorMessage = (message) => {
        setResponseMessage({
            severity: "error",
            message: message
        });
    };

    if (loading) {
        return <Skeleton variant="rect" animation="wave" height={300} />;
    }

    return (
        <>
            <Snackbar open={responseMessage.message != ''} autoHideDuration={5000} onClose={handleSnackbarClose}>
                <Alert severity={responseMessage.severity}>
                    {responseMessage.message}
                </Alert>
            </Snackbar>
            <RequestTable
                // defaultSort={currentSortCol}
                // defaultSortDir={currentSortDirection}
                rows={requests}
                recordCount={recordCount}
                onRowClick={onRowClick}
                onSortChange={onSortChange}
                loading={loading}
                status={status}
                sorting={sorting}
                paging={paging}
                onStatusChange={onStatusChange}
                onProcessNow={onProcessNow}
                dense={dense}
                onDenseChange={onDenseChange}
                onChangePage={getRequests}
                setErrorMessage={setErrorMessage}
            />
        </>
    )
}

export default RequestGrid;