import React, { useEffect, useState, Fragment, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { useDataProvider, Authenticated } from 'react-admin';
import { Skeleton } from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';
import { Container, Grid, Table, TableBody, TableCell, TableHead, TableRow, Button, Link, Snackbar } from '@material-ui/core';

import './schedule.css';
import { RequestStore, UploadStore } from '../../stores';
import pdfIcon from '../../assets/images/file-pdf.png';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SchedulePage = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [branches, setBranches] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [responseMessage, setResponseMessage] = useState({
    severity: '',
    message: ''
  });

  const dataProvider = useDataProvider();
  const requestStore = new RequestStore();
  const uploadStore = new UploadStore();
  
  useEffect(() => {
    getData();
    loadData('branches');
    loadData('vehicles');
  }, []);

  const loadData = (list) => {
    dataProvider.getList(list, {
        pagination: { page: 1, perPage: false },
        filter: { isPickup: 'true' },
        sort: { field: 'title', order: 'ASC' }
    }).then(response => {
          if (list === 'branches') {
            setBranches(response.data);
          } else if (list === 'vehicles') {
            setVehicles(response.data);
          }
        
    }).catch(error => {
      setResponseMessage({
        severity: "error",
        message: error.message || 'Get load plan failed, please try again'
      });
    });
  };

  const getData = () => {
    const params = props.match.params;
    requestStore.getSchedule(params.type, params.id, params.date).then(response => {
      const data = response.result;
      setData(data);
      setLoading(false);
    });
  };

  const buildFilter = (params) => {
    const type = params.type;
    const id = params.id;
    const filter = {
        branchId: 'none',
        driverId: 'none',
        vehicleId: 'none',
    };
    if (type === 'branch') {
        filter.branchId = id;
        filter.driverId = filter.vehicleId = 'none';
    } else if (type === 'vehicle') {
        filter.vehicleId = id;
        filter.driverId = filter.branchId = 'none';
    }

    if (filter.branchId !== 'none') {
        filter.branchName = branches.find(b => b.id === filter.branchId).title;
    }
    if (filter.vehicleId !== 'none') {
        filter.vehicleName = vehicles.find(b => b.id === filter.vehicleId).title;
    }

    return filter;
  };

  const handleSnackbarClose = () => {
    setResponseMessage({
        severity: "",
        message: ""
    });
  };

  const onSubmit = async () => {
    const params = props.match.params;
    const filter = buildFilter(params);
    await requestStore.downloadLoadPlansByWeek(params.date, filter).catch(error => {
        setResponseMessage({
          severity: "error",
          message: error.message || 'Download schedule failed, please try again'
        });
    });
  };

  const onDownload = async (id) => {
    // get file name
    dataProvider.getOne('agency_bookings', { id: id })
    .then(response => {
      // Get file name then download file
        uploadStore.downloadAgencyBookingFile(id, response.data.file)
        .catch(error => {
          setResponseMessage({
            severity: "error",
            message: error.message || 'Download agency booking file failed, please try again'
          });
        });
    }).catch(error => {
      setResponseMessage({
        severity: "error",
        message: error.message || 'Get agency booking failed, please try again'
      });
    });
  };

  const renderReport = () => {
    const branch = data[0];
    if (branch) {
      const loadPlans = branch.data.loadPlans;
      const messages = branch.data.messages || null;
      return <div>
          <Grid container style={{paddingBottom: '10px'}}>
              <Grid item xs={12} sm={12}>
                <div><p style={{fontSize: '24px'}}>{branch.definition.content[0].text}</p></div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div><span style={{color: branch.definition.styles.tableTitle.color}}>{branch.definition.subTitles.type}: </span>{branch.definition.subTitles.value}</div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div><span style={{color: branch.definition.styles.tableTitle.color}}>Week: </span>{branch.definition.subTitles.week}</div>
              </Grid>
              <Grid item xs={12} sm={3}>
              </Grid>
              <Grid item xs={12} sm={1}>
                  <Button
                      id="pdfBtn"
                      type="submit"
                      onClick={onSubmit}
                  >
                      <img src={pdfIcon} alt="Download as PDF" />
                  </Button>
              </Grid>
          </Grid>

          {messages && messages.length > 0 && renderMessages(messages, branch.definition)}

          {loadPlans ? loadPlans.map(lp => {
            return renderLoadPlan(lp, branch.definition);
          })
          :
          <div style={{textTransform: 'capitalize'}}>No {branch.definition.subTitles.type} Schedule Found</div>}
        </div>
    }
  };

  const renderMessages = (messages, definition) => {
    return (
      <div style={{paddingBottom: '20px'}}>
        <span style={{color: `${definition.styles.changesSection.color}`, fontWeight: `${definition.styles.changesLabel.bold ? 'bold' : 'normal'}`}}>Changes have been made since the load plan was published:</span>
        <ul>{messages.map(message => {
          return <li style={{color: `${definition.styles.changesSection.color}`, margin: `${definition.styles.changesSection.margin.join('px ')}`}}>
            {message}
            </li>
        })}</ul>
      </div>
    )
  };

  const renderLoadPlan = (loadPlan, definition) => {
    const header = (props.match.params.type === 'branch' ? definition.tableHeaders.branchLoadPlanHeaders : definition.tableHeaders.vehicleLoadPlanHeaders);
    return (
      <div key={loadPlan.id}>
        <Table className='schedule_table' size='small' style={{border: `2px solid ${definition.styles.tableFooter.fillColor}`}}>
          <TableHead>
            <TableRow style={{backgroundColor: definition.styles.tableHeader.fillColor}}>
            {header.map(item => {
              return <TableCell key={item.text} align="left" style={{color: definition.styles.tableHeader.color}}>{item.text}</TableCell>
            })}
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow key={loadPlan.id} style={{ margin: `${definition.styles.paramSection.margin.join('px ')}`, border: `2px solid ${definition.styles.tableFooter.fillColor}` }}>
                <TableCell align="left" >{loadPlan.id}</TableCell>
                <TableCell align="left" >{loadPlan.departureDate}</TableCell>
                {props.match.params.type === 'branch' && <TableCell align="left" >{loadPlan.vehicle || 'Branch Vehicle'}</TableCell>}
                <TableCell align="left" >{loadPlan.driver || 'Branch Driver'}</TableCell>
                <TableCell align="left" >{loadPlan.containerNumber}</TableCell>
                <TableCell align="left" >{loadPlan.origin}</TableCell>
                <TableCell align="left" >{loadPlan.destinations}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {renderRequests(loadPlan.requests, definition)}
        {renderAgencyBookings(loadPlan.agencyBookings, definition)}
        {loadPlan.notes && renderNotes(loadPlan.notes, definition)}
      </div>
    )
  };

  const renderRequests = (requests, definition) => {
    return requests.map(request => {
      return (
        <div key={request.interactId}>
          <Table className='schedule_table' size='small' style={{border: `2px solid ${definition.styles.tableFooter.fillColor}`}}>
            <TableHead>
              <TableRow>
              {definition.tableHeaders.requestHeaders.map(item => {
                return <TableCell key={item.text} align="left" style={{color: definition.styles.tableSubHeader.color, fontWeight: definition.styles.tableSubHeader.bold ? 'bold' : 'normal'}}>{item.text}</TableCell>
              })}
              </TableRow>
            </TableHead>
  
            <TableBody>
              <TableRow key={request.interactId} style={{ margin: `${definition.styles.paramSection.margin.join('px ')}`, border: `2px solid ${definition.styles.tableFooter.fillColor}` }}>
                  <TableCell align="left" >{request.interactId}</TableCell>
                  <TableCell align="left" >{request.customer}</TableCell>
                  <TableCell align="left" >{request.volume}</TableCell>
                  <TableCell align="left" >{request.origin}</TableCell>
                  <TableCell align="left" >{request.destination}</TableCell>
                  <TableCell align="left" >{request.requestedUpliftDate}</TableCell>
                  <TableCell align="left" >{request.requestTimeframe || request.requestedDeliveryDate}</TableCell>
                  <TableCell align="left" >{request.notes}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )
    })
  };

  const renderAgencyBookings = (bookings, definition) => {
    return (<div>
      {bookings && bookings.length > 0 && <div style={{ color: definition.styles.agencylabel.color, marginBottom: '10px' }}>Bookings:</div>}
      {bookings.map(booking => {
      return (
        <div key={booking.interactId}>
          <Table className='schedule_table' size='small' style={{border: `2px solid ${definition.styles.tableFooter.fillColor}`}}>
            <TableHead>
              <TableRow>
              {definition.tableHeaders.agencyBookingHeaders.map(item => {
                return <TableCell key={item.text} align="left" style={{color: definition.styles.tableSubHeader.color, fontWeight: definition.styles.tableSubHeader.bold ? 'bold' : 'normal'}}>{item.text}</TableCell>
              })}
              </TableRow>
            </TableHead>
  
            <TableBody>
              <TableRow key={booking.interactId} style={{ margin: `${definition.styles.paramSection.margin.join('px ')}`, border: `2px solid ${definition.styles.tableFooter.fillColor}` }}>
                  <TableCell align="left" >{booking.bookingNumber}</TableCell>
                  <TableCell align="left" >{booking.vehicle || 'Branch Vehicle'}</TableCell>
                  <TableCell align="left" >{booking.origin}</TableCell>
                  <TableCell align="left" >{booking.destination}</TableCell>
                  <TableCell align="left" >{booking.departureCutoff}</TableCell>
                  <TableCell align="left" >{booking.scheduledArrival}</TableCell>
                  {booking.file ? <TableCell align="left" ><Link onClick={() => onDownload(booking.file)} style={{ textDecoration: 'underline '}}>Download</Link></TableCell> : <TableCell align="left" >-</TableCell>}
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )
    })}
    </div>)
  };

  const renderNotes = (notes, definition) => {
    return (<div>
      <div style={{ color: definition.styles.agencylabel.color, marginBottom: '10px' }}>Notes:</div>
      <p>{notes}</p>
    </div>)
  };

  return (
    <Authenticated location={props.location}>
        <Container maxWidth="lg" className="container">
          <Snackbar open={responseMessage.message != ''} autoHideDuration={5000} onClose={handleSnackbarClose}>
            <Alert severity={responseMessage.severity}>
                {responseMessage.message}
            </Alert>
          </Snackbar>
          <p style={{ textAlign: 'left', fontSize: '11px', color: 'grey' }}></p>
          {loading ? <div>
            <p style={{fontSize: '24px'}}>Schedule Report</p>
            <Skeleton variant="rect" animation="wave" height={300} />
          </div> : renderReport()}
        </Container>
    </Authenticated>
  );
};

export default SchedulePage;