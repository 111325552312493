import React, { useState, useEffect } from 'react';
import {
    Button, Chip, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Dialog,
    DialogActions, DialogContent, DialogTitle
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import { useDataProvider, Loading, Error } from 'react-admin';

import { Spinner } from '../../components';
import Api from '../../stores/api';
import { GuidGenerator } from '../../utilities';
import classes from './addContainerControl.module.css';
import { useIsMountedRef } from '../../hooks'

const AddContainerControl = (props) => {
    const { onAddClick, defaultOriginId, defaultDestinationId, openDialog, showToggleButton = true, closeDialog, setErrorMessage, branches } = props;
    const isMountedRef = useIsMountedRef();
    const dataProvider = useDataProvider();
    const [open, setOpen] = useState(openDialog);
    const [working, setWorking] = useState(false);
    const [containerType, setContainerType] = useState('');
    const [containerTypeList, setContainerTypeList] = useState([]);
    const [containerTypelistLoading, setContainerTypeListLoading] = useState(true);
    const [branchList, setBranchList] = useState(branches || []);
    const [originBranch, setOriginBranch] = useState('');
    const [defaultOriginBranch, setDefaultOriginBranch] = useState(null);
    const [destinationBranch, setDestinationBranch] = useState('');
    const [defaultDestinationBranch, setDefaultDestinationBranch] = useState(null);
    const [departureDate, setDepartureDate] = useState('');
    const [errorContainerType, setErrorContainerType] = useState(null);
    const [errorDepartureDate, setErrorDepartureDate] = useState(null);
    const [errorOriginBranch, setErrorOriginBranch] = useState(null);
    const [errorDestinationBranch, setErrorDestinationBranch] = useState(null);

    const getContainerTypes = () => {
        dataProvider.getList('container_types', {
            pagination: { page: 1, perPage: false },
            sort: { field: 'display_order', order: 'ASC' }
        }).then(response => {
            if (isMountedRef.current) {
                setContainerTypeList(response.data);
                setContainerTypeListLoading(false);
            }
        }).catch(error => {
            setErrorMessage(`Error getting container types`);
            console.log(error);
        });
    };

    const getBranches = () => {
        dataProvider.getList('branches', {
            pagination: { page: 1, perPage: false },
            filter: { isPickup: 'true' },
            sort: { field: 'display_order', order: 'ASC' }
        }).then(response => {
            if (isMountedRef.current) {
                setBranchList(response.data);
                updateOriginBranch(response.data);
                updateDestinationBranch(response.data);
            }
        }).catch(error => {
            setErrorMessage(`Error getting branches`);
            console.log(error);
        });
    };

    useEffect(() => {
        getContainerTypes();
        if (!branches) {
            getBranches();
        }
    }, []);

    useEffect(() => {
        setOpen(openDialog);
    }, [openDialog]);

    useEffect(() => {
        if (branchList.length > 0) {
            updateOriginBranch(branchList);
        }
    }, [defaultOriginId]);

    useEffect(() => {
        if (branchList.length > 0) {
            updateDestinationBranch(branchList);
        }
    }, [defaultDestinationId]);

    const updateOriginBranch = (list) => {
        const defaultOrigin = list.find(b => b.id === defaultOriginId);
        if (defaultOrigin) {
            setOriginBranch(defaultOrigin);
            setDefaultOriginBranch(defaultOrigin);
        }
    };

    const updateDestinationBranch = (list) => {
        const defaultDestination = list.find(b => b.id === defaultDestinationId);
        if (defaultDestination) {
            setDestinationBranch(defaultDestination);
            setDefaultDestinationBranch(defaultDestination);
        }
    };
    
    const handleClickOpen = () => {
        resetForm();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        if (closeDialog) {
            closeDialog();
        }
    };

    const resetForm = () => {
        (defaultOriginBranch) ? setOriginBranch(defaultOriginBranch) : setOriginBranch('');
        (defaultDestinationBranch) ? setDestinationBranch(defaultDestinationBranch) : setDestinationBranch('');
        setDepartureDate('');
        setContainerType('');
    };

    const handleContainerTypeChange = (type) => {
        setContainerType(type);
    };

    const handleDepartureDateChange = (departureDateValue) => {
        setDepartureDate(departureDateValue);
    };

    const handleOriginBranchChange = (id) => {
        const branch = branchList.find(b => b.id === id);
        setOriginBranch(branch);
    };

    const handleDestinationBranchChange = (id) => {
        const branch = branchList.find(b => b.id === id);
        setDestinationBranch(branch);
    };

    const createLoadPlanOption = () => {
        setWorking(true);

        if (validateLoadPlan()) {
            const loadPlanOption = {
                id: GuidGenerator.uuidv4(),
                containerType: containerType,
                departureDate: departureDate,
                destination: destinationBranch,
                loadRequests: [],
                origin: originBranch
            }

            onAddClick(loadPlanOption);
            // Close dialog
            setWorking(false);
            setOpen(false);
            if (closeDialog) {
                closeDialog();
            }
        } else {
            setWorking(false);
        }
    };

    const validateLoadPlan = () => {
        let valid = true;
        // The form should show all errors once, so validate all fields separately
        if (!validateContainerType()) valid = false;
        if (!validateDate()) valid = false;
        if (!validateOriginBranch()) valid = false;
        if (!validateDestinationBranch()) valid = false;
        return valid;
    };

    const validateContainerType = () => {
        if (!containerType || containerType === '') {
            setErrorContainerType('Container type must have a value');
            return false;
        } else {
            if (errorContainerType) setErrorContainerType(null);
            return true;
        }
    };

    const validateDate = () => {
        if (!departureDate || departureDate === '') {
            setErrorDepartureDate('Departure date must have a value')
            return false;
        } else {
            if (errorDepartureDate) setErrorDepartureDate(null);
            return true;
        }
    };

    const validateOriginBranch = () => {
        if (!originBranch || originBranch === '') {
            setErrorOriginBranch('Origin Branch must have a value');
            return false;
        } else {
            if (errorOriginBranch) setErrorOriginBranch(null);
            return true;
        }
    };

    const validateDestinationBranch = () => {
        if (!destinationBranch || destinationBranch === '') {
            setErrorDestinationBranch('Destination Branch must have a value');
            return false;
        } else {
            if (errorDestinationBranch) setErrorDestinationBranch(null);
            return true;
        }
    };

    if ((containerTypelistLoading && showToggleButton) || branchList.length === 0) return <Skeleton />;

    return (
        <div>
            {showToggleButton && <Button
                color="primary"
                onClick={handleClickOpen}
                endIcon={<AddIcon />}
                style={{ width: '160px' }}
            >
                Add Load Plan
            </Button>}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>Add Load Plan</DialogTitle>
                <DialogContent className={classes.add_load_plan_dialog_content}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl className={classes.input} variant="filled" error={errorContainerType && errorContainerType.length > 0} required>
                                <InputLabel id="container-select-label">Container Type</InputLabel>
                                <Select
                                    labelId="container-select-label"
                                    id="container-select"
                                    value={containerType}
                                    onBlur={validateContainerType}
                                    onChange={(event) => handleContainerTypeChange(event.target.value)}>
                                    {containerTypeList.map(c => {
                                        return <MenuItem key={c.id} value={c} style={{ width: 240 }}>
                                            <Grid container item spacing={2} xs={12}>
                                                <Grid item xs={10}>
                                                    {c.title}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Chip style={{ float: 'right' }} size="small" color="primary" label={c.volume} />
                                                </Grid>
                                            </Grid>
                                        </MenuItem>
                                    })
                                    }
                                </Select>
                                {errorContainerType && (<FormHelperText>{errorContainerType}</FormHelperText>)}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                className={classes.input}
                                error={errorDepartureDate && errorDepartureDate.length > 0}
                                id="requestedUpliftDate"
                                value={departureDate}
                                label="Departure Date"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                onBlur={validateDate}
                                onChange={(event) => handleDepartureDateChange(event.target.value)}
                                helperText={errorDepartureDate}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl className={classes.input} variant="filled" error={errorOriginBranch} required>
                                <InputLabel id="originBranchLabel">Origin Branch</InputLabel>
                                <Select
                                    labelId="originBranchLabel"
                                    id="originBranch"
                                    value={originBranch.id}
                                    onBlur={validateOriginBranch}
                                    onChange={(event) => handleOriginBranchChange(event.target.value)}
                                >
                                     {branchList.map(b => {
                                    return <MenuItem
                                            selected={originBranch.id === b.id}
                                            value={b.id}
                                            key={b.id}
                                        >{b.title}</MenuItem>
                                    })}
                                </Select>
                                {errorOriginBranch && (<FormHelperText>{errorOriginBranch}</FormHelperText>)}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl className={classes.input} variant="filled" error={errorDestinationBranch} required>
                                <InputLabel id="destinationBranchLabel">Destination Branch</InputLabel>
                                <Select
                                    labelId="destinationBranchLabel"
                                    id="destinationBranch"
                                    value={destinationBranch.id}
                                    onBlur={validateDestinationBranch}
                                    onChange={(event) => handleDestinationBranchChange(event.target.value)}
                                >
                                  {branchList.map(b => {
                                    return <MenuItem
                                            selected={destinationBranch.id === b.id}
                                            value={b.id}
                                            key={b.id}
                                        >{b.title}</MenuItem>
                                    })}
                                </Select>
                                {errorDestinationBranch && (<FormHelperText>{errorDestinationBranch}</FormHelperText>)}
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions className={classes.dialog_button_group_frame}>
                    {!working && <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>}

                    <Button
                        variant="contained"
                        onClick={createLoadPlanOption}
                        color="primary"
                        autoFocus
                        disabled={working}
                    >
                        {working ? <Spinner color="#fff" size="18px" /> : 'Add'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddContainerControl;

