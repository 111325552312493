import React, { Component } from 'react';
import { Link, AppBar, Container, DialogActions, Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import './bottomBar.css';


const ColorCircularProgress = withStyles({
    root: {
      color: '#fff',
    },
})(CircularProgress);

class BottomBar extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <AppBar position="fixed" color="white" style={{ top: 'auto', bottom: '0' }}>
                <Container maxWidth="lg" className="container bodybottombar">
                    <DialogActions>
                        <Button onClick={this.props.onClose} color="primary">
                            Cancel
                        </Button>
                        {this.props.hideSaveButton ? null
                        :
                        <Button onClick={this.props.onSubmit} color="primary" variant="contained">
                        {this.props.working ? <ColorCircularProgress size={24}/> : null}<span>{this.props.onSubmitText}</span>
                        </Button>
                        }
                    </DialogActions>
                </Container>
            </AppBar>
        )
    }
}

export default BottomBar;