import React from 'react';
import {
    List, Datagrid, TextField, NumberField, Show, SimpleShowLayout, ShowButton, NumberInput, Create, Edit,
    SimpleForm, TextInput, required, SelectInput, ReferenceInput, NullableBooleanInput
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { ViewButton, TextNullableField, ShowNullableField } from '../components';
import classes from './reactAdmin.module.css';

const validateVehicle = (values) => {
    const errors = {};

    if (values.capacity && values.capacity < 0) {
        errors.capacity = ['Invalid Capacity'];
    }

    if (values.weight && values.weight < 0) {
        errors.weight = ['Invalid Weight'];
    }

    return errors;
};

export const VehicleList = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>{props.options.label}</Typography>
        <List {...props} sort={{ field: 'title', order: 'ASC' }}>
            <Datagrid rowClick="show">
                {/* <TextField source="id" /> */}
                <TextField source="title" />
                <TextField source="registrationNumber" label="Registration Number" />
                <TextNullableField source="fleetNumber" label="Fleet Number" />
                <TextField source="vehicleType.title" label="Vehicle Type" />
                <TextNullableField source="agency.name" label="Agency" />
                <TextNullableField source="domicileBranch.title" label="Domicile Branch" />
                <TextField source="containerised" label="Containerised" />
                <NumberField source="capacity" label="Capacity" />
                <NumberField source="weight" label="Weight" />
                <ViewButton />
            </Datagrid>
        </List>
    </div>
);

export const VehicleShow = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>{props.options.name}</Typography>
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="title" />
                <TextField source="registrationNumber" label="Registration Number" />
                <ShowNullableField source="fleetNumber" label="Fleet Number" />
                <TextField source="vehicleType.title" label="Vehicle Type" />
                <ShowNullableField source="agency.name" label="Agency" />
                <ShowNullableField source="domicileBranch.title" label="Domicile Branch" />
                <TextField source="containerised" label="Containerised" />
                <NumberField source="capacity" label="Capacity" />
                <NumberField source="weight" label="Weight" />
            </SimpleShowLayout>
        </Show>
    </div>
);

export const VehicleCreate = (props) => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_add_header}>Create {props.options.name}</Typography>
        <Create {...props}>
            <SimpleForm validate={validateVehicle}>
                <TextInput source="title" validate={required()} />
                <TextInput source="registrationNumber" label="Registration Number" validate={required()} />
                <TextInput source="fleetNumber" label="Fleet Number" />
                <ReferenceInput label="Vehicle Type" source="vehicleType.id" reference="vehicle_types" >
                    <SelectInput optionText="title" validate={required()} />
                </ReferenceInput>
                <ReferenceInput label="Agency" source="agency.id" reference="agencies" >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput label="Domicile Branch" source="domicileBranch.id" reference="branches" >
                    <SelectInput optionText="title" />
                </ReferenceInput>
                <NullableBooleanInput source="containerised" label="Containerised" style={{ minWidth: '256px' }} validate={required()} />
                <NumberInput source="capacity" label="Capacity" validate={required()} />
                <NumberInput source="weight" label="Weight" validate={required()} />
            </SimpleForm>
        </Create>
    </div>
);

export const VehicleEdit = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>Edit {props.options.name}</Typography>
        <Edit {...props}>
            <SimpleForm validate={validateVehicle}>
                <TextInput source="title" validate={required()} />
                <TextInput source="registrationNumber" label="Registration Number" validate={required()} />
                <TextInput source="fleetNumber" label="Fleet Number" />
                <ReferenceInput label="Vehicle Type" source="vehicleType.id" reference="vehicle_types" validate={required()}>
                    <SelectInput optionText="title" />
                </ReferenceInput>
                <ReferenceInput label="Agency" source="agency.id" reference="agencies">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput label="Domicile Branch" source="domicileBranch.id" reference="branches">
                    <SelectInput optionText="title" />
                </ReferenceInput>
                <NullableBooleanInput source="containerised" label="Containerised" style={{ minWidth: '256px' }} validate={required()} />
                <NumberInput source="capacity" label="Capacity" validate={required()} />
                <NumberInput source="weight" label="Weight" validate={required()} />
            </SimpleForm>
        </Edit>
    </div>
);