import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import MomentUtils from '@date-io/moment';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#cb0016', contrastText: '#ffffff' },
        secondary: { main: '#31383D', contrastText: '#ffffff' },
        inherit: { main: '#717A83', contrastText: '#ffffff' },
        default: { main: '#13b5ea', contrastText: '#ffffff' }
    },
    typography: {
        fontFamily: [
            'FiraSans Medium'
        ].join(','),
        subtitle1: {
            color: '#717A83',
        },
        h5: {
            color: '#717A83',
        },
        h6: {
            color: '#717A83',
        }
      }
});

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="en-NZ">
            <App theme={theme} />
        </MuiPickersUtilsProvider>
    </MuiThemeProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
