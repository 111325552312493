import React, { useEffect, useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, Loading, Error } from 'react-admin';
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { RequestStore, LoadPlanStore, UserStore } from '../../../stores';
import { LoadPlanSummary, Spinner, AgencyBooking } from '../../../components';
import { FormatDate } from '../../../utilities';

import {
    FormControl,
    Button,
    CircularProgress,
    Container, Grid, Divider,
    IconButton, FormHelperText,
    Input, InputAdornment, InputLabel,
    LinearProgress, MenuItem,
    Select, TextField,
    Typography, Snackbar,
    ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanelActions
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SaveIcon from '@material-ui/icons/Save';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { constructKeyValuePair } from '../../../utilities';
import Skeleton from '@material-ui/lab/Skeleton';

import classes from './selectVehicles.module.css';

const JOURNYLEG_MODIFIED_CODE = 'M';
const DEFAULT_ERROR_MESSAGE = 'Request failed. Please try again';
// Notes field is 1024 maximum character in database setting
const NOTES_MAX_LENGTH = 1024;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const SelectVehicles = (props) => {
    const { request, onBack } = props;
    const requestStore = new RequestStore();
    const loadPlanStore = new LoadPlanStore();
    const userStore = new UserStore();
    const dataProvider = useDataProvider();
    const history = useHistory();

    const [loadPlans, setLoadPlans] = useState([]);
    const [loadPlansLoading, setLoadPlansLoading] = useState(true);
    const [branches, setBranches] = useState([]);
    const [branchesLoading, setBranchesLoading] = useState(true);
    const [agencies, setAgencies] = useState([]);
    const [agenciesLoading, setAgenciesLoading] = useState(true);
    const [vehicles, setVehicles] = useState([]);
    const [allVehicles, setAllVehicles] = useState([]);
    const [vehiclesLoading, setVehiclesLoading] = useState(true);
    const [drivers, setDrivers] = useState([]);
    const [driversLoading, setDriversLoading] = useState(true);
    const [allAgencyBookings, setAllAgencyBookings] = useState([]);
    const [agencyBookings, setAgencyBookings] = useState([]);
    const [agencyBookingsLoading, setAgencyBookingsLoading] = useState(true);
    const [expandedLoadPlans, setExpandedLoadPlans] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [updating, setUpdating] = useState(false);
    const [requestStatuses, setRequestStatuses] = useState([]);

    const formatVehiclesList = (data) => {
        const result = [];
        data.map(item => {
            result.push({
                key: item.id,
                value: `${item.title} #${item.fleetNumber} ${item.vehicleType.title}`
            });
        });
        return result;
    };

    const formatDriversList = (data) => {
        const result = [];
        data.map(item => {
            result.push({
                key: item.id,
                value: `${item.firstName} ${item.lastName}`
            });
        });
        return result;
    };

    const formatAgencyList = (data) => {
        const result = [];
        data.map(item => {
            result.push({
                key: item.id,
                value: item.name
            });
        });
        return result;
    };

    useEffect(() => {
        getLoadPlansByRequest();
        getBranches();
        getVehicles();
        getDrivers();
        getAgencies();
        getAgencyBookings();
        getRequestStatuses();
    }, []);

    const getAgencyBookings = () => {
        dataProvider.getList('agency_bookings', {
            pagination: { page: 1, perPage: false },
            sort: { field: 'booking_date', order: 'ASC' }
        }).then(response => {
            setAllAgencyBookings(response.data);
            setAgencyBookings(constructKeyValuePair(response.data, 'id', 'bookingReference'));
            setAgencyBookingsLoading(false);
        }).catch(error => {
            console.log(error);
            setErrorMessage('Error: Unable to get agency bookings');
            setAgencyBookingsLoading(false);
        });
    };

    const getRequestStatuses = () => {
        dataProvider.getList('request_statuses', {
            pagination: { page: 1, perPage: false },
            sort: { field: 'title', order: 'ASC' }
        }).then(response => {
            setRequestStatuses(response.data);
        }).catch(error => {
            console.log(error);
            setErrorMessage(error.message || 'Error getting request statuses');
        });
    };

    const formatJourneyLegs = (data) => {
        let result = [];
        data.map((item, index) => {
            result.push(
                {
                    ...item,
                    value: item.origin.id,  // need the origin of all journey legs
                    updateStatus: JOURNYLEG_MODIFIED_CODE
                }
            );

            if (index === data.length - 1) {
                result.push(
                    {
                        ...item,
                        value: item.destination.id, // and the destination of last journey leg
                        updateStatus: null
                    }
                );
            }
        });

        return result;
    };

    const getLoadPlansByRequest = () => {
        requestStore.getByRequestId(request.id)
            .then(response => {
                const loadPlans = response.map(lp => {
                    return {
                        id: lp.id,
                        departureDate: lp.departureDate,
                        containerType: lp.containerType,
                        containerNumber: lp.containerNumber,
                        origin: lp.origin,
                        destination: lp.destination,
                        bookingReference: lp.bookingReference,
                        journeyLegs: formatJourneyLegs(lp.journeyLegs),
                        loadRequests: lp.loadRequests,
                        displayId: lp.displayId,
                        hasError: false,
                        notes: lp.notes
                    }
                });
                setExpandedLoadPlans([true, ...Array(loadPlans.length - 1).fill(false)]);
                setLoadPlans(loadPlans);
                setLoadPlansLoading(false);
            })
            .catch(error => {
                console.log(error);
                setErrorMessage('Error getting load plans');
            });
    };

    const getBranchLabel = (id) => {
        if (id) {
            const branch = branches.find(branch => branch.key === id);
            return branch.value;
        }
    };

    const getBranches = () => {
        //Branches drop down
        dataProvider.getList('branches', {
            pagination: { page: 1, perPage: false },
            filter: { isPickup: 'true' },
            sort: { field: 'title', order: 'ASC' }
        }).then(response => {
            const branches = constructKeyValuePair(response.data, 'id', 'title');
            setBranches(branches);
            setBranchesLoading(false);
        }).catch(error => {
            console.log(error);
            setErrorMessage('Error getting branches');
            setBranchesLoading(false);
        });
    };

    const getVehicles = () => {
        // Vehicles drop down
        dataProvider.getList('vehicles', {
            pagination: { page: 1, perPage: false },
            sort: { field: 'title', order: 'ASC' }
        }).then(response => {
            setAllVehicles(response.data);
            setVehicles(formatVehiclesList(response.data));
            setVehiclesLoading(false);
        }).catch(error => {
            console.log(error);
            setErrorMessage('Error getting vehicles');
            setVehiclesLoading(false);
        });
    };

    const getDrivers = () => {
        // Drivers drop down
        return userStore.getDrivers()
            .then(response => {
                setDrivers(formatDriversList(response));
                setDriversLoading(false);
            })
            .catch(error => {
                console.log(error);
                setErrorMessage('Error getting drivers');
                setDriversLoading(false);
            });
    };

    const getAgencies = () => {
        // Agencies drop down
        dataProvider.getList('agencies', {
            pagination: { page: 1, perPage: false },
            sort: { field: 'name', order: 'ASC' }
        }).then(response => {
            setAgencies(constructKeyValuePair(response.data, 'id', 'name'));
            setAgenciesLoading(false);
        }).catch(error => {
            console.log(error);
            setErrorMessage('Error getting agencies');
            setAgenciesLoading(false);
        });
    };

    const handleExpansionChange = (loadPlanIndex) => {
        const cloneExpandedloadPlans = [...expandedLoadPlans];
        cloneExpandedloadPlans[loadPlanIndex] = !expandedLoadPlans[loadPlanIndex];
        setExpandedLoadPlans(cloneExpandedloadPlans);
    };

    const loadPlanHasError = (loadPlanIndex) => {
        const loadPlan = loadPlans[loadPlanIndex];
        for (let i = 0; i < loadPlan.journeyLegs.length - 1; i++) {
            // loadPlan.journeyLegs.length - 1:  the last journey leg does not have vehicle, just for dislaying the destination
            if (selectHasError(loadPlanIndex, i)) {
                return true;
            }
        }
        return false;
    };

    const selectHasError = (loadPlanIndex, journeyLegsIndex) => {
        // If error Message
        const loadPlan = loadPlans[loadPlanIndex].journeyLegs[journeyLegsIndex].vehicle;
        return loadPlan.error?.length > 0;
    };

    const handleSelectChange = (loadPlanIndex, journeyLegsIndex, value, key) => {
        const templLoadPlans = [...loadPlans];
        templLoadPlans[loadPlanIndex].journeyLegs[journeyLegsIndex][key] = { id: value };
        setLoadPlans(templLoadPlans);
    };

    const handleSelectBlur = (loadPlanIndex, journeyLegsIndex, value, key) => {
        const templLoadPlans = [...loadPlans];
        templLoadPlans[loadPlanIndex].journeyLegs[journeyLegsIndex][key] = { id: value };
        setLoadPlans(templLoadPlans);
    };

    const validateNotes = (value) => {
        setErrorMessage('');
        if (value && value.length > NOTES_MAX_LENGTH) {
            return false;
        }
        return true;
    };

    const handleNotesChange = (loadPlanIndex, value) => {
        if (validateNotes(value)) {
            const templLoadPlans = [...loadPlans];
            templLoadPlans[loadPlanIndex].notes = value;
            setLoadPlans(templLoadPlans);
        } else {
            setErrorMessage(`Notes cannot exceed ${NOTES_MAX_LENGTH} characters`);
        }
    };

    const handleNotesBlur = (loadPlanIndex, value) => {
        if (validateNotes(value)) {
            const templLoadPlans = [...loadPlans];
            templLoadPlans[loadPlanIndex].notes = value;
            setLoadPlans(templLoadPlans);
        } else {
            setErrorMessage(`Notes cannot exceed ${NOTES_MAX_LENGTH} characters`);
        }
    };

    const handleSnackbarClose = () => {
        setErrorMessage('');
    };

    const checkLoadPlansStatus = async (id) => {
        let result = false;
        await loadPlanStore.getByRequestId(id)
            .then(async loadPlans => {
                // check each load plan status
                const promises = loadPlans.map(async lp => {
                    const weekStart = FormatDate.getMonday(lp.departureDate);
                    return loadPlanStore.getPublishStatus(weekStart)
                        .then(response => {
                            if (response.result?.type === 'PUBLISH_WEEK') {
                                return {
                                    success: true,
                                    result: ''
                                };
                            } else {
                                return {
                                    success: false,
                                    result: ""
                                };
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            return {
                                success: false,
                                result: error.message || "Get load plan publish status failed, please try again"
                            };
                        });
                });

                const receivedData = await Promise.all(promises);
                if (receivedData.length === loadPlans.length) {
                    const error = receivedData.find(item => item.success === false);
                    if (!error) {
                        // all load plan are published
                        result = true;
                    }
                }
            }).catch(error => {
                // set error msg
                setErrorMessage(`${error.message || "Check load plan status failed, please try again"}`);
                setUpdating(false);
            });

        return result;
    };

    const updateRequestStatus = async () => {
        if (request.requestStatus.code !== 'Published') {
            let targetStatusCode = 'Queued';

            if (await checkLoadPlansStatus(request.id)) {
                // Move request to published if all related load plans been published
                targetStatusCode = 'Published';
            }
            const status = requestStatuses.find(rs => rs.code === targetStatusCode);
            const data = {
                id: request.id,
                newStatusId: status.id,
                // Set override is true, update request status will skip the allowed status check
                override: targetStatusCode === 'Published' ? true : false
            };

            requestStore.editRequestStatus(request.id, data)
                .then(response => {
                    // nav to view request page
                    history.push(`/requests/view/${request.id}`);
                    setUpdating(false);
                }).catch(error => {
                    // set error msg
                    setErrorMessage(`${error.message || DEFAULT_ERROR_MESSAGE}`);
                    setUpdating(false);
                });
        } else {
            history.push(`/requests/view/${request.id}`);
            setUpdating(false);
        }
    };

    const onSave = async () => {
        const templLoadPlans = [...loadPlans];

        setUpdating(true);
        const promises = templLoadPlans.map(async lp => {
            // Remove the last journey leg
            lp.journeyLegs.pop();
            // Format journey leg data
            lp.journeyLegs.map(jl => {
                delete jl.value;
            });
            delete lp.hasError;

            // update loadplan
            const step = 3;
            return loadPlanStore.editLoadPlan(lp, step)
                .then(response => {
                    return {
                        success: true,
                        result: ''
                    };
                })
                .catch(error => {
                    console.log(error);
                    return {
                        success: false,
                        result: error.message || DEFAULT_ERROR_MESSAGE
                    };
                });
        });

        const receivedData = await Promise.all(promises);
        if (receivedData.length === templLoadPlans.length) {
            const error = receivedData.find(item => item.success === false);
            if (error) {
                setErrorMessage(error.result);
                setUpdating(false);
            } else {
                loadPlanStore.validateByRequestId(request.id)
                .then(response => {
                    if (response.success) {
                        // Update request status if request is not queued or published
                        updateRequestStatus();
                    } else {
                        setErrorMessage(response.message || "Validate load plans failed, please try again");
                    }
                    setUpdating(false);
                }).catch(error => {
                    setUpdating(false);
                    setErrorMessage("Validate load plans failed, please try again");
                });
            }
        }
    };

    const setChildErrorMessage = (message) => {
        setErrorMessage(message || DEFAULT_ERROR_MESSAGE);
    };

    const getAvailableAgencyBookings = (loadRequests) => {
        const requests = loadRequests.map(item => item = item.request.id);
        const bookings = allAgencyBookings.filter(item => requests.includes(item.request?.id));
        if (bookings.length > 0) {
            return constructKeyValuePair(bookings, 'id', 'bookingReference');
        } else {
            return [];
        }
    };

    if (loadPlansLoading || branchesLoading) {
        return (<Skeleton animation="wave" />);
    }

    return (
        <Grid item xs={12} sm={12}>
            <Snackbar open={errorMessage} autoHideDuration={8000} onClose={handleSnackbarClose}>
                <Alert severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
            {loadPlans.map((loadPlan, loadPlanIndex) => {
                return (
                    <ExpansionPanel key={loadPlan.id} expanded={expandedLoadPlans[loadPlanIndex]} onChange={() => handleExpansionChange(loadPlanIndex)}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <LoadPlanSummary loadPlan={loadPlan} error={loadPlanHasError(loadPlanIndex)} />
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {updating ? <Grid item xs={12} sm={12}>
                                <Spinner />
                            </Grid>
                                : <Grid item xs={12} sm={12}>
                                    {loadPlan.journeyLegs.map((item, journeyLegsIndex) => {
                                        const lastIndex = loadPlan.journeyLegs.length - 1;
                                        return (
                                            <Grid key={journeyLegsIndex} item container xs={12} sm={12}>
                                                <Grid item xs={4} sm={4}>
                                                    <Typography variant="h6" gutterBottom style={{ paddingTop: '15px' }}>
                                                        {getBranchLabel(item.value)}
                                                    </Typography>
                                                </Grid>

                                                {journeyLegsIndex !== lastIndex ? <Grid item container xs={12} sm={12} style={{ paddingTop: '20px' }}>
                                                    <Grid item xs={2} sm={1} className={classes.form_arrow_down_icon_frame}>
                                                        <ArrowDownwardIcon />
                                                    </Grid>

                                                    <Grid item container xs={10} sm={11} spacing={2}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormControl variant="filled" style={{ width: '100%' }} error={selectHasError(loadPlanIndex, journeyLegsIndex)}>
                                                                <InputLabel id="branch-select-label">
                                                                    Vehicle
                                                                </InputLabel>
                                                                <Select
                                                                    IconComponent={vehiclesLoading ?
                                                                        props => (<CircularProgress size={20} />) :
                                                                        void 0}
                                                                    labelId="branch-select-label"
                                                                    id="branch-select"
                                                                    value={loadPlan.journeyLegs[journeyLegsIndex].vehicle.id}
                                                                    onChange={(event) => handleSelectChange(loadPlanIndex, journeyLegsIndex, event.target.value, 'vehicle')}
                                                                    onBlur={(event) => handleSelectBlur(loadPlanIndex, journeyLegsIndex, event.target.value, 'vehicle')}
                                                                >
                                                                    {vehicles.map(vehicle => {
                                                                        return <MenuItem key={vehicle.key} value={vehicle.key}>{vehicle.value}</MenuItem>
                                                                    })}
                                                                </Select>
                                                                <FormHelperText>{loadPlan.journeyLegs[journeyLegsIndex].vehicle.error}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <FormControl variant="filled" style={{ width: '100%' }}>
                                                                <InputLabel id="branch-select-label">
                                                                    Driver
                                                                </InputLabel>
                                                                <Select
                                                                    IconComponent={driversLoading ?
                                                                        props => (<CircularProgress size={20} />) :
                                                                        void 0}
                                                                    labelId="branch-select-label"
                                                                    id="branch-select"
                                                                    value={loadPlan.journeyLegs[journeyLegsIndex].driver.id}
                                                                    onChange={(event) => handleSelectChange(loadPlanIndex, journeyLegsIndex, event.target.value, 'driver')}
                                                                    onBlur={(event) => handleSelectBlur(loadPlanIndex, journeyLegsIndex, event.target.value, 'driver')}
                                                                >
                                                                    {drivers.map(driver => {
                                                                        return <MenuItem key={driver.key} value={driver.key}>{driver.value}</MenuItem>
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            {agencies.length > 0 && <AgencyBooking
                                                                agencies={agencies}
                                                                vehicles={vehicles}
                                                                allVehicles={allVehicles}
                                                                journeyLeg={item}
                                                                loadPlan={loadPlan}
                                                                setChildErrorMessage={setChildErrorMessage}
                                                                currentValue={loadPlan.journeyLegs[journeyLegsIndex].agencyBooking?.id}
                                                                availableAgencyBookings={getAvailableAgencyBookings(loadPlan.loadRequests)}
                                                                allAgencyBookings={allAgencyBookings}
                                                                agencyBookingsLoading={agencyBookingsLoading}
                                                            />}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                    :
                                                    <Grid item xs={12} sm={12} style={{ paddingBottom: '20px' }} />}
                                            </Grid>
                                        )
                                    })}

                                    <Grid item xs={12} sm={12}>
                                        <FormControl variant="filled" style={{ width: '100%' }}>
                                            <TextField id="notes"
                                                value={loadPlan.notes}
                                                label="Notes"
                                                variant="filled"
                                                multiline
                                                onChange={(event) => handleNotesChange(loadPlanIndex, event.target.value)}
                                                onBlur={(event) => handleNotesBlur(loadPlanIndex, event.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>}
                        </ExpansionPanelDetails>
                        
                        <Divider />

                        <ExpansionPanelActions>
                            <Button size="small" onClick={() => handleExpansionChange(loadPlanIndex)}>Close</Button>
                        </ExpansionPanelActions>
                    </ExpansionPanel>
                )
            })}
            <Grid className={classes.save_form_btn}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onBack}
                    startIcon={<ArrowLeft />}
                >
                    Back
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSave}
                    startIcon={updating ? <Spinner color="#fff" size="18px" /> : <SaveIcon />}
                    disabled={updating}
                >
                    Done
                </Button>
            </Grid>
        </Grid>
    )
};

export default SelectVehicles;