import React, { Fragment, useState } from 'react';
import { createElement } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery, Collapse, Divider, List, Typography, ListItemIcon, MenuItem } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { MenuItemLink, getResources, usePermissions } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import HomeIcon from '@material-ui/icons/Home';
import Assessment from '@material-ui/icons/Assessment';
import TableChart from '@material-ui/icons/TableChart';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MoveToInbox from '@material-ui/icons/MoveToInbox';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import Person from '@material-ui/icons/Person'

import classes from './crownMenu.module.css';

const CrownMenu = ({ onMenuClick, logout }) => {
    const { loaded, permissions } = usePermissions();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const [showLookupTables, setShowLookupTables] = useState(false);
    const resources = useSelector(getResources);

    const publicItems = ['users', 'load_plans', 'agency_bookings'];

    return loaded ? (
        <div>
            <MenuItemLink
                key="home"
                to="/home"
                primaryText="Home"
                leftIcon={<HomeIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />

            <MenuItemLink
                key="requests"
                to="/requests"
                primaryText="Requests"
                leftIcon={<LocalShippingIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />

            <MenuItemLink
                key="loadplans"
                to="/loadplans"
                primaryText="Load Plans"
                leftIcon={<MoveToInbox />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />

            <MenuItemLink
                key="agency_bookings"
                to="/agency_bookings"
                primaryText="Agency Bookings"
                leftIcon={<LibraryBooksIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />

            {permissions.roles.includes('Admin') ?
                <Fragment>
                    <MenuItemLink
                        key="users"
                        to="/users"
                        primaryText="Users"
                        leftIcon={<Person />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />

                    <MenuItemLink
                        key="reports"
                        to="/reports"
                        primaryText="Reports"
                        leftIcon={<Assessment />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />

                    <MenuItem onClick={() => setShowLookupTables(!showLookupTables)} className={classes.MenuItemWrap}>
                        <Typography variant="body2" color="textSecondary" component="div">
                            <TableChart /> <div className={classes.MenuItemTitle}>Tables</div>
                        </Typography>
                        <ListItemIcon className={classes.ExpandIcon}>
                            {showLookupTables ? <ExpandLess /> : <ExpandMore />}
                        </ListItemIcon>
                    </MenuItem>

                    <Collapse in={showLookupTables} timeout="auto" unmountOnExit>
                        <div className={classes.dropdownMenu}>
                            {
                                resources.map((resource) => {
                                    if (!publicItems.includes(resource.name)) {
                                        return (<MenuItemLink
                                            key={resource.name}
                                            to={`/${resource.name}`}
                                            primaryText={(resource.options && resource.options.label) ||
                                                resource.name}
                                            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                                            onClick={onMenuClick}
                                            sidebarIsOpen={open} />);
                                    }
                                })
                            }
                        </div>
                    </Collapse>
                </Fragment> : null}
            {isXSmall && logout}
        </div >
    ) : null;
}

export default CrownMenu;