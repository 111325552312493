import React, { useEffect, useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, Loading, Error } from 'react-admin';
import { Formik, Field, FieldArray, getIn } from 'formik';
import { useHistory } from "react-router-dom";
import MaskedInput from 'react-text-mask';

import {
    Button,
    Container, Grid,
    LinearProgress, MenuItem, Paper,
    Snackbar, TextField,
    Typography
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import SaveIcon from '@material-ui/icons/Save';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Api from '../../../stores/api';
import authProvider from '../../../providers/authProvider';
import { AddressesDetailsForm, CustomerDetailsForm, RequestDetailsForm, Spinner } from '../../../components';
import requestValidationSchema from '../requestValidationSchema';
import { useIsMountedRef } from '../../../hooks';
import classes from './requestEdit.module.css';

// Server expects these codes to deal with address updates
const ADDRESS_DELETE_CODE = 'X';
const ADDRESS_MODIFIED_CODE = 'M';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop); 

const TextMaskCustom = (props) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            showMask={true}
            placeholderChar={'\u2000'}
            keepCharPositions={false}
            guide={false}
        />
    );
};

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const RequestEdit = (props) => {
    const history = useHistory();
    const dataProvider = useDataProvider();
    const api = new Api();

    const [loading, setLoading] = useState(false);
    const [working, setWorking] = useState(false);
    const [error, setError] = useState();
    const [responseMessage, setResponseMessage] = useState({
        error: false,
        message: ''
    });
    const [addressTypes, setAddressTypes] = useState([]);
    const [branches, setBranches] = useState([]);
    const [branchesLoading, setBranchesLoading] = useState(true);
    const [requestStatuses, setRequestStatuses] = useState([]);
    const [requestRemovedAddresses, setRequestRemovedAddresses] = useState([]);

    const [request, setRequest] = useState({
        id: '',
        interactId: '',
        customerOrganisation: '',
        customerPersonName: '',
        customerTypeId: '',
        estimatedLoadVolume: '',
        freightAllowance: '',
        requestedUpliftDate: '',
        requestedDeliveryDate: '',
        requestTimeframeId: '',
        originBranchId: '',
        destinationBranchId: '',
        upliftDateConstraints: '',
        assignedUserId: '',
        linehaulTypeId: '',
        addresses: [{
            id: '',
            addressLine_1: '',
            addressLine_2: '',
            city: '',
            postcode: '',
            addressUseId: ''
        }]
    });

    const customerDetailsFormRef = useRef(null);
    const requestDetailsFormRef = useRef(null);
    const addressesDetailsFormRef = useRef(null);
    const customerDetailsErrors = ['interactId', 'customerPersonName'];
    const requestDetailsErrors = ['destinationBranchId', 'estimatedLoadVolume', 'linehaulTypeId', 'requestTimeframeId', 'originBranchId', 'requestedUpliftDate', 'assignedUserId'];
    const addressesDetailsErrors = ['addresses'];
    const isMountedRef = useIsMountedRef();
    const executeScroll = (value) => scrollToRef(value);

    const formatAddress = (addresses) => {
        const result = [];
        addresses.map(item => {
            result.push({
                id: item.id,
                addressId: item.address.id,
                addressLine_1: item.address.addressLine_1,
                addressLine_2: item.address.addressLine_2,
                city: item.address.city,
                postcode: item.address.postcode,
                addressUseId: item.addressUse.id
            })
        });
        return result;
    };

    useEffect(() => {
        setLoading(true);
        //Branches drop down
        dataProvider.getList('branches', {
            pagination: { page: 1, perPage: false },
            filter: { isPickup: 'true' },
            sort: { field: 'title', order: 'ASC' }
        }).then(response => {
            if (isMountedRef.current) {
                setBranches(response.data);
                setBranchesLoading(false);
            }
        }).catch(error => {
            console.log(error);
            setResponseMessage({
                error: true,
                message: error.message || 'Error getting branches'
            });
        });

        // Request_Statuses
        dataProvider.getList('request_statuses', {
            pagination: { page: 1, perPage: false },
            sort: { field: 'title', order: 'ASC' }
        }).then(response => {
            setRequestStatuses(response.data);

        }).catch(error => {
            console.log(`Error getting request_statuses`);
            console.log(error);
        });

        // Address_Types
        dataProvider.getList('address_types', {
            pagination: { page: 1, perPage: false },
            sort: { field: 'title', order: 'ASC' }
        }).then(response => {
            setAddressTypes(response.data);
        }).catch(error => {
            console.log(`Error getting address_types`);
            console.log(error);
        });
        // Request_Details
        const id = props.match.params.id;
        api.dataProvider.getOne('requests', { id: id })
            .then(response => {
                const data = response.data;
                setRequest({
                    id: data.id,
                    interactId: data.interactId,
                    customerOrganisation: data.customerOrganisation,
                    customerPersonName: data.customerPersonName,
                    customerTypeId: data.customerType.id,
                    estimatedLoadVolume: data.estimatedLoadVolume,
                    freightAllowance: data.freightAllowance,
                    requestedUpliftDate: data.requestedUpliftDate.substring(0, 10),
                    requestedDeliveryDate: data.requestedDeliveryDate ? data.requestedDeliveryDate.substring(0, 10) : '',
                    requestTimeframeId: data.requestTimeframe.id || '',
                    requestStatus: data.requestStatus,
                    originBranchId: data.originBranch.id,
                    destinationBranchId: data.destinationBranch.id,
                    upliftDateConstraints: data.upliftDateConstraints,
                    assignedUserId: data.assignedUser.id,
                    linehaulTypeId: data.linehaulType.id,
                    addresses: formatAddress(data.requestAddresses),
                });
                setLoading(false);
            }).catch(error => {
                //setErrorMessage({ errorMessage: error.message || DEFAULT_ERROR_MESSAGE });
                setError(error.message || 'Something went wrong');
                setLoading(false);
            });
    }, []);

    const removeAddress = (address) => {
        setRequestRemovedAddresses([
            ...requestRemovedAddresses,
            {
                id: address.id,
                address: {
                    id: address.addressId,
                    addressLine_1: address.addressLine_1,
                    addressLine_2: address.addressLine_2,
                    city: address.city,
                    postcode: address.postcode
                },
                addressUse: { id: address.addressUseId },
                updateStatus: ADDRESS_DELETE_CODE
            }
        ]);
    };

    const updateForm = async (submitForm, validateForm) => {
        validateForm().then((errors) => {
            // Get errors, order errors, scroll to the first error
            for (const [key, value] of Object.entries(errors)) {
                if (key) {
                    // Show error message
                    setResponseMessage({
                        error: true,
                        message:'This form has errors. Please correct all highlighted fields and try again.'
                    });
                }

                if (customerDetailsErrors.includes(key)) {
                    executeScroll(customerDetailsFormRef);
                    break;
                }

                if (requestDetailsErrors.includes(key)) {
                    executeScroll(requestDetailsFormRef);
                    break;
                }

                if (addressesDetailsErrors.includes(key)) {
                    executeScroll(addressesDetailsFormRef);
                    break;
                }
            }
        });

        submitForm();
    };

    const handleSubmit = async (values) => {
        setWorking(true);
        const addressType = addressTypes.find(at => at.code.toUpperCase() === 'CUSTOMER');
        const requestId = request.id;
        const updatedAddresses = [];

        values.addresses.map(item => {
            if (item.id) {
                // edit address
                updatedAddresses.push({
                    id: item.id,
                    address: {
                        id: item.addressId,
                        addressLine_1: item.addressLine_1,
                        addressLine_2: item.addressLine_2,
                        city: item.city,
                        postcode: item.postcode,
                        addressType: addressType
                    },
                    addressUse: { id: item.addressUseId },
                    updateStatus: ADDRESS_MODIFIED_CODE
                });
            } else {
                // new address
                updatedAddresses.push({
                    address: {
                        addressLine_1: item.addressLine_1,
                        addressLine_2: item.addressLine_2,
                        city: item.city,
                        postcode: item.postcode,
                        addressType: addressType
                    },
                    addressUse: { id: item.addressUseId }
                });
            }
        });
        // Concat removed addresses
        const formattedAddresses = updatedAddresses.concat(requestRemovedAddresses);

        const requestData = {
            id: request.id,
            interactId: values.interactId,
            customerOrganisation: values.customerOrganisation,
            customerPersonName: values.customerPersonName,
            customerType: { id: values.customerTypeId },
            estimatedLoadVolume: parseFloat(values.estimatedLoadVolume),
            freightAllowance: parseFloat(values.freightAllowance),
            requestedUpliftDate: values.requestedUpliftDate,
            requestedDeliveryDate: values.requestedDeliveryDate || null,
            requestTimeframe: values.requestTimeframeId ? { id: values.requestTimeframeId } : {},
            originBranch: { id: values.originBranchId },
            destinationBranch: { id: values.destinationBranchId },
            upliftDateConstraints: values.upliftDateConstraints,
            linehaulType: { id: values.linehaulTypeId },
            requestStatus: values.requestStatus,
            assignedUser: { id: values.assignedUserId },
            requestAddresses: formattedAddresses
        }

        dataProvider.update('requests', { id: requestId, data: requestData })
            .then(({ data }) => {
                history.push(`/requests/view/${requestId}`);
                setWorking(false);
            })
            .catch(error => {
                console.log('error', error)
                setResponseMessage({
                    error: true,
                    message: error.message || 'Update request failed, please try again'
                });
                setWorking(false);
            });
    };

    const handleSnackbarClose = () => {
        setResponseMessage({
            error: false,
            message: ''
        });
    };

    const canEdit = () => {
        if (
            authProvider.highLevelUser() 
            || (
                !['cancelled', 'archived', 'completed'].includes(request.status?.code.toLowerCase())
                && (!authProvider.readOnly() || authProvider.isBranchOps(request.originBranch?.id))
            )) {
            return true;
        } else {
            return false;
        }
    };

    const goBack = () => {
        history.push(`/requests/view/${request.id}`);
    };

    if (loading || branchesLoading ) return <Loading />;
    if (error) return <Error />;

    return (
        <div className={classes.pageRoot}>
            {responseMessage.message && <Snackbar open={responseMessage.message} autoHideDuration={5000} onClose={handleSnackbarClose}>
                <Alert severity={responseMessage.error ? 'error' : 'success'}>
                    {responseMessage.message}
                </Alert>
            </Snackbar>}

            <Grid container className={classes.page_title_frame} ref={customerDetailsFormRef}>
                <Typography variant="h5" gutterBottom style={{ marginTop: '15px' }}>
                    Edit Request
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => history.push(`/requests/view/${props.match.params.id}`)}
                    startIcon={<VisibilityIcon />}
                >
                    View
                </Button>
            </Grid>
            <Paper className={classes.contentWrap}>
                <Formik
                    initialValues={request}
                    onSubmit={handleSubmit}
                    validationSchema={requestValidationSchema}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset,
                            submitForm,
                            validateForm
                        } = props;
                        return (
                            <form noValidate
                                autoComplete="off"
                                style={{ width: '100%' }}
                                onSubmit={handleSubmit}
                            >
                                <div className={classes.content}>
                                    <Grid container spacing={2} direction="column">
                                        <Grid item xs={12} sm={9}>
                                            <Grid item container xs={12} spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <TextField id="interactId"
                                                        error={errors.interactId && touched.interactId}
                                                        helperText={errors.interactId}
                                                        className={classes.inputItem}
                                                        label="Interact ID"
                                                        type="text"
                                                        variant="filled"
                                                        InputProps={{
                                                            inputComponent: TextMaskCustom,
                                                            value: values.interactId,
                                                            onChange: handleChange,
                                                            onBlur: handleBlur
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        required
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} />

                                        <CustomerDetailsForm {...props} canEdit={canEdit()} />

                                        <Grid item xs={12} ref={requestDetailsFormRef} />

                                        <RequestDetailsForm {...props} canEdit={canEdit()} branches={branches} branchesLoading={branchesLoading} />

                                        <Grid item xs={12} ref={addressesDetailsFormRef} />

                                        <AddressesDetailsForm {...props} removeAddress={removeAddress} canEdit={canEdit()} branches={branches} />
                                    </Grid>
                                </div>
                                <div className={classes.contentActions}>
                                    <Button onClick={goBack} color="primary">
                                        Cancel
                                    </Button>
                                    {canEdit() && <Button
                                        id="submitBtn"
                                        variant="contained"
                                        color="primary"
                                        startIcon={working ? <Spinner color="inherit" size={24} /> : <SaveIcon />}
                                        onClick={() => updateForm(submitForm, validateForm)}
                                        disabled={working}
                                        style={{marginLeft: '20px'}}
                                    >
                                        Save
                                    </Button>}
                                </div>
                            </form>);
                    }}
                </Formik>
            </Paper>
        </div >
    )
};

export default RequestEdit;