import Api from './api';
import Axios from 'axios';

const NZ_POST_TOKEN_KEY = 'zKBi40KHfvaUhHM6y45E';

const NZ_POST_SUGGEST_URL = 'https://api.nzpost.co.nz/addresschecker/1.0/suggest';

const getToken = async () => {
    let nzPostToken = localStorage.getItem(NZ_POST_TOKEN_KEY);
    if (nzPostToken === null) {
        console.log('Token not found');
        nzPostToken = await getTokenFromServer();
    } else {
        nzPostToken = JSON.parse(nzPostToken);
        const currentTime = new Date().getTime();
        if (nzPostToken.expires_in < currentTime) {
            nzPostToken = await getTokenFromServer();
        }
    }
    return nzPostToken.access_token;
}

const getTokenFromServer = async () => {
    return new Promise(async (resolve, reject) => {
        await Api.get(`/nzPost`).then(data => {
            localStorage.setItem(NZ_POST_TOKEN_KEY, JSON.stringify(data));
            resolve(data);
        })
            .catch(error => {
                reject(error)
            })
    })
}

export const clearNZPostToken = () => {
    localStorage.removeItem(NZ_POST_TOKEN_KEY);
}

export const getAddressSuggestions = async (address, callback) => {
    const minLengthToCheck = 4;
    const maxAddressOptions = 5;
    if (address && address.length > minLengthToCheck) {
        const token = await getToken();
        const config = {
            headers: { 
                Authorization: `Bearer ${token}`,
                Accept: 'application/json'
            },
            params: {
                q: address,
                max: maxAddressOptions,
                type: 'Physical'
            }
        };


        return Axios.get(NZ_POST_SUGGEST_URL, config)
            .then(response => {
                callback(response.data);
            }).catch(error => {
                console.log(error);
            });
    }
}

