import React from 'react';
import {
    Edit,
    SimpleForm,
    SelectInput,
    ReferenceInput,
    ArrayInput,
    SimpleFormIterator,
    List,
    Show,
    SimpleShowLayout,
    Datagrid,
    ReferenceField,
    TextField,
    TextInput,
    ArrayField,
    SingleFieldList, ChipField
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { TextNullableField, ShowNullableField } from '../../components';
import classes from '../reactAdmin.module.css';

export const AddressList = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>{props.options.label}</Typography>
        <List {...props}>
            <Datagrid rowClick="show">
                <TextNullableField source="addressLine_1" />
                <TextNullableField source="addressLine_2" />
                <TextNullableField source="city" />
                <TextNullableField source="postcode" />
                <TextNullableField source="geocode" />
                <TextNullableField source="notes" />
                <ReferenceField label="Address Type" source="addressType.id" reference="address_types">
                    <TextField source="title" />
                </ReferenceField>
                <ArrayField source="branches"><SingleFieldList><ChipField source="id" /></SingleFieldList></ArrayField>
            </Datagrid>
        </List>
    </div>
);

export const AddresShow = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>{props.options.name}</Typography>
        <Show {...props}>
            <SimpleShowLayout>
                <ShowNullableField source="addressLine_1" label="Address Line 1" />
                <ShowNullableField source="addressLine_2" label="Address Line 2" />
                <ShowNullableField source="city" label="City" />
                <ShowNullableField source="postcode" label="Postcode" />
                <ShowNullableField source="geocode" label="Geocode" />
                <ShowNullableField source="notes" label="Notes" />
                <ReferenceField label="Address Type" source="addressType.id" reference="address_types">
                    <TextField optionText="title" />
                </ReferenceField>
                <ArrayField source="branches">
                    <SingleFieldList>
                        <TextField source="title" />
                    </SingleFieldList>
                </ArrayField>
            </SimpleShowLayout>
        </Show>
    </div>
);

export const AddressEdit = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>Edit {props.options.name}</Typography>
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="addressLine_1" />
                <TextInput source="addressLine_2" />
                <TextInput source="city" />
                <TextInput source="postcode" />
                <TextInput source="geocode" />
                <TextInput source="notes" />
                <ReferenceInput label="Address Type" source="addressType.id" reference="address_types">
                    <SelectInput optionText="title" />
                </ReferenceInput>
                <ArrayInput source="branches">
                    <SimpleFormIterator>
                        <TextInput source="id" />
                        <TextInput source="title" />
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    </div>
);