export default {
    formatValue: (record, source) => {
        let result = record[source];

        if (source.includes('.')) {
          const values = source.split('.');
      
          if (values.length === 2) {
            if (record[values[0]]) {
              result = record[values[0]][values[1]];
            }
          }
        }

        return result;
    } 
}