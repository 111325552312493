import Api from './api';
import { FormatDate } from '../utilities';

export default class RequestStore {
    async getById(id, active) {
        return new Promise(async (resolve, reject) => {
            await Api.get(`/requests/${id}`).then(data => {
                resolve(data);
            })
        })
    }

    async add(data) {
        return new Promise(async (resolve, reject) => {
            Api.post(`/requests`, data).then(response => {
                if (response.success) {
                    resolve(response);
                } else {
                    reject(response.message);
                }
                // reject if error
            }).catch(error => {
                reject(error);
            });
        });
    }

    async edit(data, skip, limit) {
        return new Promise(async (resolve, reject) => {
            Api.put(`/requests/${data.id}`, data).then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
        });
    }

    async remove(id, skip, limit) {
        return new Promise(async (resolve, reject) => {
            Api.delete(`/requests/${id}`).then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
        });
    }

    async getAvailableStatusesByRequestIds(ids) {
        return new Promise(async (resolve, reject) => {
            await Api.post(`/request_statuses/available`, { requestIds: ids }).then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    }

    async editRequestStatus(id, data) {
        return new Promise(async (resolve, reject) => {
            Api.put(`/requests/status/${id}`, data).then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
        });
    }

    async getAvailableLoadPlan(id, fromDate = null, exact) {
        return new Promise((resolve, reject) => {
            let url = `load_plans/available/request/${id}?exact=${exact}`;
            if(fromDate) {
                url += `&date=${fromDate}`;
            }
            Api.get(url)
                .then(result => resolve(result))
                .catch(error => reject(error));
        });
    }

    async getLoadPlan(id) {
        return new Promise((resolve, reject) => {
            const url = `load_plans/${id}`;
            Api.get(url)
                .then(result => resolve(result))
                .catch(error => reject(error));
        });
    }

    async getLoadPlansByWeek(fromDate, filter) {
        return new Promise((resolve, reject) => {
            let url = `load_plans/week?date=${fromDate}`;
            if (filter.branchId !== 'none') {
                url += `&branch_id=${filter.branchId}`;
            }
            if (filter.driverId !== 'none') {
                url += `&driver_id=${filter.driverId}`;
            }
            if (filter.vehicleId !== 'none') {
                url += `&vehicle_id=${filter.vehicleId}`;
            }
            Api.get(url)
                .then(result => resolve(result))
                .catch(error => reject(error));
        });
    }

    async getSchedule(type, id, fromDate) {
        return new Promise((resolve, reject) => {
            let url = `reports/schedule/${type}/${id}?date=${fromDate}&html=true`;
            Api.get(url)
                .then(result => resolve(result))
                .catch(error => reject(error));
        });
    }

    async downloadLoadPlansByWeek(fromDate, filter) {
        return new Promise(async (resolve, reject) => {
            const type = filter.branchId !== 'none' ? 'branch' : filter.driverId !== 'none' ? 'driver' : 'vehicle';
            const id = filter.branchId !== 'none' ? filter.branchId : filter.driverId !== 'none' ? filter.driverId : filter.vehicleId;
            const formattedDate = FormatDate.displayDate(new Date(fromDate));
            const filename = 'Schedule_' + (
                    filter.branchId !== 'none' ? 'Branch_' + filter.branchName : 
                    filter.driverId !== 'none' ? 'Driver_' + filter.driverName : 
                    'Vehicle_' + filter.vehicleName
                ) + '_' + formattedDate.split(' ').join('_') + '.pdf';
            const url = `/reports/schedule/${type}/${id}?date=${fromDate}`;
            Api.download(url, filename).catch(error => {
                reject(error);
            });
        });
    }

    async downloadRequestSummaryReport(data, fileName) {
        return new Promise(async (resolve, reject) => {
            const url = `/reports/requestSummary?start_date=${data.startDate}&end_date=${data.endDate}&type=${data.type}`;
            Api.download(url, fileName);
        });
    }

    async downloadRequestLoadPlanReport(data, fileName) {
        return new Promise(async (resolve, reject) => {
            const url = `/reports/requestLoadPlan?start_date=${data.startDate}&end_date=${data.endDate}&type=${data.type}`;
            Api.download(url, fileName);
        });
    }

    async getByRequestId(id) {
        return new Promise((resolve, reject) => {
            Api.get(`load_plans/request/${id}`)
                .then(result => resolve(result))
                .catch(error => reject(error));
        });
    }

    async getRequestStats(interact_id) {
        return new Promise((resolve, reject) => {
            Api.get(`requests/stats?interact_id=${interact_id}`)
                .then(result => resolve(result))
                .catch(error => reject(error));
        });
    }

    async publishLoadPlansForWeek(weekStart) {
        return new Promise(async (resolve, reject) => {
            Api.put(`/load_plans/publish/${weekStart}`).then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
        });
    }

    async acknowledgeAlert(alertId) {
        return new Promise(async (resolve, reject) => {
            Api.put(`/requests/acknowledgealert/${alertId}`).then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
        });
    }
}
