import React from 'react';
import {
    List, Datagrid, TextField, Show, SimpleShowLayout, ShowButton, ReferenceField, Create, Edit,
    SimpleForm, TextInput, required, SelectInput, ReferenceInput
} from 'react-admin';
import { Typography } from '@material-ui/core';
import classes from './reactAdmin.module.css';
import { ViewButton, TextNullableField, ShowNullableField } from '../components';

export const AgencyList = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>{props.options.label}</Typography>
        <List {...props} sort={{ field: 'title', order: 'ASC' }}>
            <Datagrid rowClick="show">
                <TextField source="name" />
                <TextNullableField source="contactName" />
                <TextNullableField source="email" />
                <TextNullableField source="workPhone" />
                <TextNullableField source="mobilePhone" />
                <TextField
                    label="Address Line 1"
                    source="address.addressLine_1"
                />
                <TextField
                    label="Address Line 2"
                    source="address.addressLine_2"
                />
                <TextField
                    label="City"
                    source="address.city"
                />
                <TextField
                    label="Postcode"
                    source="address.postcode"
                />
                <ReferenceField label="Address Type" source="address.addressType.id" reference="address_types">
                    <TextField optionText="title" />
                </ReferenceField>
                <ViewButton />
            </Datagrid>
        </List>
    </div>
);

export const AgencyShow = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>{props.options.name}</Typography>
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="name" />
                <ShowNullableField source="contactName" label="Contact Name" />
                <ShowNullableField source="email" label="Email" />
                <ShowNullableField source="workPhone" label="Work Phone" />
                <ShowNullableField source="mobilePhone" label="Mobile Phone" />
                <TextField
                    label="Address Line 1"
                    source="address.addressLine_1"
                />
                <TextField
                    label="Address Line 2"
                    source="address.addressLine_2"
                />
                <TextField
                    label="City"
                    source="address.city"
                />
                <TextField
                    label="Postcode"
                    source="address.postcode"
                />
                <ReferenceField label="Address Type" source="address.addressType.id" reference="address_types">
                    <TextField optionText="title" />
                </ReferenceField>
            </SimpleShowLayout>
        </Show>
    </div>
);

const validateAgency = (values) => {
    const errors = {};
    if (values.address) {
        if (!values.address.addressType) {
            errors.address = { addressType: { id: ["Address Type is required for a new address"] } };
        } else if (!values.address.city) {
            errors.address = { city: ["City is required for a new address"] };
        }
    }
    return errors;
};

export const AgencyCreate = (props) => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_add_header}>Create {props.options.name}</Typography>
        <Create {...props}>
            <SimpleForm validate={validateAgency}>
                <TextInput source="name" validate={required()} />
                <TextInput source="contactName" validate={required()} />
                <TextInput source="email" validate={required()} />
                <TextInput source="workPhone" />
                <TextInput source="mobilePhone" validate={required()} />
                <TextInput
                    label="Address Line 1"
                    source="address.addressLine_1"
                />
                <TextInput
                    label="Address Line 2"
                    source="address.addressLine_2"
                />
                <TextInput
                    label="City"
                    source="address.city"
                />
                <TextInput
                    label="Postcode"
                    source="address.postcode"
                />
                <ReferenceInput label="Address Type" source="address.addressType.id" reference="address_types">
                    <SelectInput optionText="title" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    </div>
);

export const AgencyEdit = props => (
    <div>
        <Typography variant="h5" className={classes.react_admin_page_header}>Edit {props.options.name}</Typography>
        <Edit {...props}>
            <SimpleForm validate={validateAgency}>
                <TextInput source="name" validate={required()} />
                <TextInput source="contactName" validate={required()} />
                <TextInput source="email" validate={required()} />
                <TextInput source="workPhone" />
                <TextInput source="mobilePhone" validate={required()} />
                <TextInput
                    label="Address Line 1"
                    source="address.addressLine_1"
                />
                <TextInput
                    label="Address Line 2"
                    source="address.addressLine_2"
                />
                <TextInput
                    label="City"
                    source="address.city"
                />
                <TextInput
                    label="Postcode"
                    source="address.postcode"
                />
                <ReferenceInput label="Address Type" source="address.addressType.id" reference="address_types">
                    <SelectInput optionText="title" />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    </div>
);