import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ErrorIcon from '@material-ui/icons/Error';

import classes from './loadPlanSummary.module.css';


const LoadPlanSummary = (props) => {

    const { loadPlan, error } = props;

    const dt = new Date(loadPlan.departureDate);
    const parts = dt.toString().split(' ');
    const formattedDepartureDate = `${parts[2]}-${parts[1]}-${dt.getFullYear()}`;

    return (<Grid container>
        <Grid item xs={12}>
            <Paper variant="outlined" square className={classes.summaryBox}>
                { error && <ErrorIcon className={classes.error}/> }
                <span style={{ margin: '8px' }}>{loadPlan.displayId}</span>
                <span style={{ margin: '8px' }}>Type: {loadPlan.containerType.title}</span>
                <span style={{ margin: '8px' }}>Departure Date: {formattedDepartureDate}</span>
                <span style={{ margin: '8px' }}>Origin: {loadPlan.origin.title}</span>
                <span style={{ margin: '8px' }}>Destination: {loadPlan.destination.title}</span>
            </Paper>
        </Grid>
        <Grid item xs={12}>
            <Paper variant="outlined" square>
                <Table className={classes.table} aria-label="simple table" size="small" >
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeader}>Load Order</TableCell>
                            <TableCell className={classes.tableHeader}>Interact ID</TableCell>
                            <TableCell className={classes.tableHeader}>Origin</TableCell>
                            <TableCell className={classes.tableHeader}>Destination</TableCell>
                            <TableCell className={classes.tableHeader}>Volume</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loadPlan.loadRequests.map((row) => (
                            <TableRow key={row.request.interactId}>
                                <TableCell>{row.loadOrder}</TableCell>
                                <TableCell>{row.request.interactId}</TableCell>
                                <TableCell>{row.request.originBranch.title}</TableCell>
                                <TableCell>{row.request.destinationBranch.title}</TableCell>
                                <TableCell>{row.volume}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Grid>
    </Grid>);
}

export default LoadPlanSummary;