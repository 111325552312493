import React, { useEffect, useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, Loading, Error } from 'react-admin';
import { Formik, Field, FieldArray, getIn } from 'formik';
import { useHistory } from "react-router-dom";
import MaskedInput from 'react-text-mask';
import {
    Button,
    Container, Grid,
    LinearProgress, MenuItem, Paper,
    Snackbar, TextField,
    Typography
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import SaveIcon from '@material-ui/icons/Save';
import { Skeleton } from '@material-ui/lab';

import { AddressesDetailsForm, CustomerDetailsForm, RequestDetailsForm, Spinner } from '../../../components';
import requestValidationSchema from '../requestValidationSchema';
import classes from './requestCreate.module.css';
import authProvider from '../../../providers/authProvider';
import { useIsMountedRef } from '../../../hooks';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop); 

const TextMaskCustom = (props) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            showMask={true}
            placeholderChar={'\u2000'}
            keepCharPositions={false}
            guide={false}
        />
    );
};

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const initialFormValue = {
    interactId: '',
    customerOrganisation: '',
    customerPersonName: '',
    customerTypeId: '',
    estimatedLoadVolume: '',
    requestedUpliftDate: '',
    requestedDeliveryDate: '',
    requestTimeframeId: '',
    originBranchId: '',
    destinationBranchId: '',
    upliftDateConstraints: '',
    assignedUserId: '',
    linehaulTypeId: '',
    freightAllowance: '',
    addresses: [{
        addressLine_1: '',
        addressLine_2: '',
        city: '',
        postcode: '',
        addressUseId: ''
    }]
};

const RequestCreate = (props) => {
    const history = useHistory();
    const dataProvider = useDataProvider();

    const [working, setWorking] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [responseMessage, setResponseMessage] = useState({
        error: false,
        message: ''
    });
    const [addressTypes, setAddressTypes] = useState([]);
    const [requestStatuses, setRequestStatuses] = useState([]);
    const [requestStatus, setRequestStatus] = useState(null);
    const [request, setRequestValues] = useState(initialFormValue);
    const [branches, setBranches] = useState([]);
    const [branchesLoading, setBranchesLoading] = useState(true);

    const customerDetailsFormRef = useRef(null);
    const requestDetailsFormRef = useRef(null);
    const addressesDetailsFormRef = useRef(null);
    const customerDetailsErrors = ['interactId', 'customerPersonName'];
    const requestDetailsErrors = ['destinationBranchId', 'estimatedLoadVolume', 'linehaulTypeId', 'requestTimeframeId', 'originBranchId', 'requestedUpliftDate', 'assignedUserId'];
    const addressesDetailsErrors = ['addresses'];
    const isMountedRef = useIsMountedRef();
    const executeScroll = (value) => scrollToRef(value);

    useEffect(() => {
        //Branches drop down
        dataProvider.getList('branches', {
            pagination: { page: 1, perPage: false },
            filter: { isPickup: 'true' },
            sort: { field: 'title', order: 'ASC' }
        }).then(response => {
            if (isMountedRef.current) {
                setBranches(response.data);
                setBranchesLoading(false);
            }
        }).catch(error => {
            console.log(error);
            setResponseMessage({
                error: true,
                message: error.message || 'Error getting branches'
            });
        });

        // Request_Statuses
        dataProvider.getList('request_statuses', {
            pagination: { page: 1, perPage: false },
            sort: { field: 'title', order: 'ASC' }
        }).then(response => {
            setRequestStatuses(response.data);

        }).catch(error => {
            console.log(error);
            setResponseMessage({
                error: true,
                message: error.message || 'Error getting request_statuses'
            });
        });

        // Address_Types
        dataProvider.getList('address_types', {
            pagination: { page: 1, perPage: false },
            sort: { field: 'title', order: 'ASC' }
        }).then(response => {
            setAddressTypes(response.data);
        }).catch(error => {
            console.log(error);
            setResponseMessage({
                error: true,
                message: error.message || 'Error getting address_types'
            });
        });
    }, []);

    const updateForm = async (submitForm, status, validateForm, canCreate, values) => {
        validateForm().then((errors) => {
            // Get errors, order errors, scroll to the first error
            for (const [key, value] of Object.entries(errors)) {
                if (key) {
                    // Show error message
                    setResponseMessage({
                        error: true,
                        message:'This form has errors. Please correct all highlighted fields and try again.'
                    });
                }

                if (customerDetailsErrors.includes(key)) {
                    executeScroll(customerDetailsFormRef);
                    break;
                }

                if (requestDetailsErrors.includes(key)) {
                    executeScroll(requestDetailsFormRef);
                    break;
                }

                if (addressesDetailsErrors.includes(key)) {
                    executeScroll(addressesDetailsFormRef);
                    break;
                }
            }
        });

        const statusCode = requestStatuses.find(rs => rs.code.toUpperCase() === status);

        const canCreateRequest = canCreate(values);

        if (canCreateRequest) {
            if (statusCode) {
                setRequestStatus(statusCode);
                submitForm();
            } else {
                setResponseMessage({
                    error: true,
                    message:'Invalid request status.'
                });
            }
        } else {
            setResponseMessage({
                error: true,
                message:'You do not have permission to create a request for above branches.'
            });
        }
    };

    const handleSubmit = async (values) => {
        setWorking(true);
        const addressType = addressTypes.find(at => at.code.toUpperCase() === 'CUSTOMER');

        const requestData = {
            interactId: values.interactId,
            customerOrganisation: values.customerOrganisation,
            customerPersonName: values.customerPersonName,
            customerType: { id: values.customerTypeId },
            estimatedLoadVolume: values.estimatedLoadVolume,
            requestedUpliftDate: values.requestedUpliftDate,
            requestedDeliveryDate: values.requestedDeliveryDate || null,
            requestTimeframe: { id: values.requestTimeframeId },
            originBranch: { id: values.originBranchId },
            destinationBranch: { id: values.destinationBranchId },
            upliftDateConstraints: values.upliftDateConstraints,
            freightAllowance: values.freightAllowance,
            linehaulType: { id: values.linehaulTypeId },
            requestStatus: requestStatus,
            assignedUser: { id: values.assignedUserId },
            requestAddresses: values.addresses.map(a => {
                return {
                    address: {
                        addressLine_1: a.addressLine_1,
                        addressLine_2: a.addressLine_2,
                        city: a.city,
                        postcode: a.postcode,
                        addressType: addressType
                    },
                    addressUse: { id: a.addressUseId }
                };
            })
        };

        if(!values.requestTimeframeId) delete requestData.requestTimeframe;

        dataProvider.create('requests', { data: requestData })
            .then(({ data }) => {
                setLoading(true);
                setRequestValues(initialFormValue);
                setResponseMessage({
                    error: false,
                    message: 'The request has been created'
                });
                setLoading(false);
                setWorking(false);
            })
            .catch(error => {
                console.log(error);
                setResponseMessage({
                    error: true,
                    message: error.message || 'Create request failed, please try again'
                });
                setWorking(false);
            });
    };

    const handleSnackbarClose = () => {
        setResponseMessage({
            error: false,
            message: ''
        });
    };

    const canEdit = () => {
        if (
            authProvider.highLevelUser() 
            || (
                !['cancelled', 'archived', 'completed'].includes(request.status?.code.toLowerCase())
                && (!authProvider.readOnly() || authProvider.isBranchOps(request.originBranch?.id))
            )) {
            return true;
        } else {
            return false;
        }
    };

    const canCreate = (values) => {
        if (authProvider.highLevelUser() || authProvider.isBranchOps(values.originBranchId) || authProvider.isBranchOps(values.destinationBranchId)) {
            return true;
        } else {
            return false;
        }
    };

    if (error) return <Error />;
    if (branchesLoading) return <Loading />;

    return (
        <div className={classes.pageRoot}>
            {responseMessage.message && <Snackbar open={responseMessage.message} autoHideDuration={5000} onClose={handleSnackbarClose}>
                <Alert severity={responseMessage.error ? 'error' : 'success'}>
                    {responseMessage.message}
                </Alert>
            </Snackbar>}

            <Grid container item className={classes.page_title_frame} ref={customerDetailsFormRef}>
                <Typography variant="h5" gutterBottom style={{ marginTop: '15px' }}>
                    Create Request
                </Typography>
            </Grid>

            { loading ? <Skeleton animation="wave" variant="rect" height={200} width={'100%'} />
            :
            <Paper className={classes.contentWrap}>
                <Formik
                    initialValues={request}
                    onSubmit={handleSubmit}
                    enablereinitialize
                    validationSchema={requestValidationSchema}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            submitForm,
                            validateForm
                        } = props;
                        return (
                            <form noValidate
                                autoComplete="off"
                                style={{ width: '100%' }}
                            >
                                <div className={classes.content}>
                                    <Grid container spacing={2} direction="column">
                                        <Grid item xs={12} sm={9}>
                                            <Grid item container xs={12} spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <TextField id="interactId"
                                                        error={errors.interactId && touched.interactId}
                                                        helperText={`${errors.interactId || ''} Eg. 4444-55555-22`}
                                                        className={classes.inputItem}
                                                        label="Interact ID"
                                                        type="text"
                                                        variant="filled"
                                                        InputProps={{
                                                            inputComponent: TextMaskCustom,
                                                            value: values.interactId,
                                                            onChange: handleChange,
                                                            onBlur: handleBlur
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        required
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <CustomerDetailsForm {...props} canEdit={canEdit}/>

                                        <Grid item xs={12} ref={requestDetailsFormRef}/>

                                        <RequestDetailsForm {...props} canEdit={canEdit} branches={branches} branchesLoading={branchesLoading} />

                                        <Grid item xs={12} ref={addressesDetailsFormRef} canEdit={canEdit} />

                                        <AddressesDetailsForm {...props} canEdit={canEdit()} branches={branches} />
                                    </Grid>
                                </div>
                                <div className={classes.contentActions}>
                                    <Button
                                        id="saveBtn"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => updateForm(submitForm, 'DRAFT', validateForm, canCreate, values)}
                                        startIcon={working ? <Spinner color="inherit" size={24} /> : <SaveIcon />}
                                        disabled={working}
                                    >
                                        Save as draft
                                    </Button>

                                    <Button
                                        id="submitBtn"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => updateForm(submitForm, 'SUBMITTED', validateForm, canCreate, values)}
                                        startIcon={working ? <Spinner color="inherit" size={24} /> : <SaveIcon />}
                                        disabled={working}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </form>);
                    }}
                </Formik>
            </Paper>}
        </div >
    )
};

export default RequestCreate;