import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Button, Paper, Checkbox, Collapse, Box, IconButton
 } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckIcon from '@material-ui/icons/Check';

import classes from './selectTable.module.css';

const SelectTable = (props) => {

    const {
        name,
        headerColumns,
        data,
        onCheckEvent,
        isSelected,
        onExpandEvent,
        isExpanded,
        collapseContent,
        onEdit,
        allowSelect,
        allowIndicator,
        exact,
        getTableData
    } = props;

    return (
        <TableContainer component={Paper}>
            <Table aria-label={name} size="small">
                {exact != undefined && <caption>                
                    <Button onClick={getTableData} style={{width: '100px'}} color="primary" >
                        {exact ? 'More' : 'Less'}
                    </Button>
                </caption>}
                <TableHead>
                    <TableRow style={{ height: '62px' }}>
                        {allowIndicator && <TableCell className={classes.tableHeader}></TableCell>}
                        {allowSelect && <TableCell className={classes.tableHeader}/>}
                        {headerColumns.map((hc, index) =>
                            <TableCell key={index} className={classes.tableHeader}>{hc.label}</TableCell>
                        )}
                        <TableCell className={classes.tableHeader}>Agency Booking</TableCell>
                        <TableCell className={classes.tableHeader}>Expand</TableCell>
                        {onEdit && <TableCell className={classes.tableHeader}>Action</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => (
                        <Row key={index}
                            headerColumns={headerColumns}
                            row={row}
                            onEdit={onEdit}
                            onCheckEvent={onCheckEvent}
                            isSelected={isSelected(row['id'])}
                            onExpandEvent={onExpandEvent}
                            isExpanded={isExpanded(row['id'])}
                            collapseContent={collapseContent}
                            allowSelect={allowSelect}
                            allowIndicator={allowIndicator}
                        />)
                    )}
                </TableBody>
            </Table>
        </TableContainer >
    );
}

SelectTable.propTypes = {
    name: PropTypes.string,
    headerColumns: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    })),
    data: PropTypes.arrayOf(PropTypes.object),
    onCheckChange: PropTypes.func,
    collapseContent: PropTypes.func
}

const Row = (props) => {

    const { row, 
            headerColumns, 
            collapseContent, 
            onCheckEvent,
            onExpandEvent,
            isExpanded, 
            isSelected,
            allowSelect,
            allowIndicator,
            onEdit } = props;

    const colSpan = props.headerColumns.length + (onEdit ? 3 : 2);
    const collapse = () => collapseContent(row['id']);

    return (
        <Fragment>
            <TableRow style={{ height: '62px', backgroundColor: '#DFE1E6' }}>
                {allowIndicator && <TableCell padding="checkbox">
                    <FiberManualRecordIcon className={row['readyToPublish'] ? classes['pass_indicator'] : classes['warning_indicator']} />
                </TableCell>}
                {allowSelect && <TableCell padding="checkbox">
                    <Checkbox
                        checked={isSelected}
                        onChange={() => onCheckEvent(row['id'])}
                        inputProps={{ 'aria-label': `Check this` }}
                    />
                </TableCell>}
                {headerColumns.map((col, index) =>
                    (<TableCell key={index} scope="row">
                        {row[col.value]}
                    </TableCell>)
                )}
                <TableCell>{row['hasAgencyBooking'] ? <CheckIcon/> : null}</TableCell>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => onExpandEvent(row['id'])}>
                        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {onEdit && <TableCell>
                    <Button onClick={ev => onEdit(ev, row['id']) } color="secondary" variant="contained">Edit</Button>
                    </TableCell> }
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            {collapse}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}

export default SelectTable; 