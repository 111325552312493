import React, { Component } from 'react';
import { TextField, CircularProgress } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './headerSearch.css';
import { withRouter } from 'react-router-dom';

class HeaderSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            loading: false,
            keywords: '',
            open: false
        };
    }

    onInputChange = (event) => {
        // console.log("search", event.target.value);
        if (event.target.value.trim().length < 2) {
            this.setState({
                loading: false,
                keywords: '',
                open: false
            });
            return;
        }
        this.setState({
            loading: true,
            keywords: event.target.value.trim(),
            open: true
        });
        this.searchStore.doSearch(event.target.value).then(result => {
            if (result.success) {
                // console.log("result", result);
                this.setState({
                    options: result.result,
                    loading: false
                });
            } else {
                //this.refs.snackBar.handleClick('error', "Category Error");
                console.log("error searching", result.result);
            }
        })
        .catch(error => {
            // this.refs.snackBar.handleClick('error', error.message ? error.message : "Category Error");
            console.log("unexpected error searching", error.message);
        });
    }

    setOpen = (value) => {
        this.setState({
            open: value
        });
    }

    onSelected = (value) => {
        this.setState({
            keywords: ''
        });
        if (!value) {
            return;
        }
        this.props.history.push(`/newpage/${value.id}`);
    }

    render() {
        const options = this.state.options;

        return (
            <Autocomplete
                id="app-search"
                options={options}
                groupBy={option => option.type + "s"}
                onChange={(event, value) => this.onSelected(value)}
                getOptionLabel={option => option.title}
                open={this.state.open}
                onClose={() => this.setOpen(false)}
                loading={this.state.loading}
                style={{ width: '100%' }}
                renderInput={params => (
                    <div className='search'>
                        <div className='search-icon'>
                            <SearchIcon />
                        </div>
                        <TextField 
                            {...params} 
                            label="" 
                            fullWidth 
                            placeholder="Search" 
                            className="header-search-bar"
                            onChange={this.onInputChange}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                        />
                    </div>
                )}
                renderOption={option => {
                    return (
                        <div>
                            <span><strong>{option.number}</strong>: {option.title}</span>
                        </div>
                    );
                }}
            />
        );
    }
}

export default withRouter(HeaderSearch);